var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseView',{attrs:{"searchEnabled":"","searchConfig":{
    'System Criteria': [
      {
        key: 'ship_type',
        label: 'Ship Type',
        type: 'dropdown',
        options: Object.keys(_vm.ship_types),
      },
      {
        key: 'apparatus_group',
        label: 'Apparatus Group',
        type: 'dropdown',
        options: ['IIA', 'IIB', 'IIC'],
        conditionalRendering: function (searchCriteria) {
          return _vm.ship_types[searchCriteria['ship_type']];
        },
      },
      {
        key: 'temperature_group',
        label: 'Temperature Group',
        type: 'dropdown',
        options: ['T1', 'T2', 'T3', 'T4', 'T5', 'T6'],
        initValue: 'T3',
        default: 'T3',
        conditionalRendering: function (searchCriteria) {
          return _vm.ship_types[searchCriteria['ship_type']];
        },
      },
      {
        key: 'with_uscg_approval_set',
        label: 'US Trade Eligible (USCG)',
        type: 'checkbox',
      },
      {
        key: 'only_uscg_approved_systems',
        label: 'Only USCG-Approved systems',
        type: 'checkbox',
        conditionalRendering: function (searchCriteria) {
          return searchCriteria['with_uscg_approval_set'] === true;
        },
      },
      {
        key: 'include_old_g8_systems',
        label: 'Include Old G8 Systems',
        type: 'checkbox',
      },
      {
        key: 'only_approved_imo_status',
        label: 'Use only IMO-Approved systems',
        type: 'checkbox',
      },
      {
        key: 'fitting_requirements',
        label: 'Fitting Requirements',
        type: 'dropdown',
        options: ['New Build', 'Retrofit'],
      } ],
    'Water Quality': [
      {
        key: 'use_worst_possible_ports',
        label: 'Use worst possible ports',
        type: 'checkbox',
      },
      {
        key: 'specific_port_data',
        label: 'Use data from specific ports',
        type: 'multiselect',
        apiEndpoint: 'ports',
        mapFunc: function (rec) { return rec['port_name']; },
        conditionalRendering: function (searchCriteria) {
          return searchCriteria['use_worst_possible_ports'] == false;
        },
      } ],
    'Flow Rate Requirements': [
      {
        key: 'pump_one_capacity',
        label: 'Pump One (m³/h)',
        type: 'number',
        step: 100,
        min: 0,
      },
      {
        key: 'pump_two_capacity',
        label: 'Pump Two (m³/h)',
        type: 'number',
        step: 100,
        min: 0,
      } ],
    'Weighting Criteria': [
      {
        key: 'financial_cat_weight',
        label: 'Operational Limitations',
        type: 'slider',
        initValue: 2,
        min: 0,
        max: 4,
      },
      {
        key: 'installation_cat_weight',
        label: 'Financial',
        type: 'slider',
        initValue: 2,
        min: 0,
        max: 4,
      },
      {
        key: 'operational_cat_weight',
        label: 'Installation Complexity',
        type: 'slider',
        initValue: 2,
        min: 0,
        max: 4,
      },
      {
        key: 'manufacturer_cat_weight',
        label: 'Manufacturer Reputation',
        type: 'slider',
        initValue: 2,
        min: 0,
        max: 4,
      } ],
  }}},[_c('Table',{key:_vm.rerenderTableKey,attrs:{"apiEndpoint":"bwms_finder","stickyHeaderHeight":"1125px","fields":_vm.systemsSchema,"rowDetailsButtonEnabled":"","detailsRowModel":_vm.modelsSchema,"addButtonEnabled":function () { return false; },"editButtonEnabled":function () { return false; },"deleteButtonEnabled":function () { return false; },"detailsButtonEnabled":function () { return false; },"viewButtonEnabled":function () { return true; },"booleanColumns":['active_substrances', 'ams_designation', 'explosion_proofing']},on:{"detailsClicked":function($event){return _vm.navigateToSystemsPage.apply(void 0, arguments)},"rerenderAfterGenericModal":function($event){_vm.rerenderTableKey = !_vm.rerenderTableKey}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }