<template>
  <BaseView>
    <Table
      :key="rerenderTableKey"
      apiEndpoint="leads"
      :apiParams="{ status: leadsStatusOptions }"
      stickyHeaderHeight="1125px"
      :fields="leadsSchema"
      :emailColumns="['email']"
      searchingEnabled
      :sortTableByFn="(a, b) => (a['status'] == 'Qualified' || b['status'] == 'Qualified' ? -1 : 1)"
      :convertButtonEnabled="{
        convertToSchema: opportunitiesSchema,
        copyFields: [
          ['name', 'name'],
          ['region', 'region'],
          ['manager_full_name', 'manager_full_name'],
          ['lead_name', 'opportunity_title'],
          ['lead_id', 'origin_lead_id'],
        ],
        convertToApiEndpoint: 'opportunities',
        updateCurrRecord: (rec) => (rec['status'] = 'Qualified'),
      }"
      @rerenderAfterGenericModal="rerenderAfterGenericModal"
    >
    </Table>
  </BaseView>
</template>

<script>
import BaseView from "@/views/BaseView.vue";
import Table from "@/components/charts/Table.vue";
import { phoneNumberFormatter } from "@/components/style_formatters/phoneNumberFormatter.js";
import { TABLE_SCHEMAS, TABLE_ENUMS } from "@/config.js";

export default {
  name: "Leads",
  components: {
    BaseView,
    Table,
  },
  data() {
    return {
      leadsSchema: TABLE_SCHEMAS[this.$options.name],
      leadsStatusOptions: TABLE_ENUMS["LeadsStatusOptions"],
      opportunitiesSchema: TABLE_SCHEMAS["Opportunities"],
      rerenderTableKey: false,
    };
  },
  methods: {
    phoneNumberFormatter,
    rerenderAfterGenericModal() {
      this.rerenderTableKey = !this.rerenderTableKey;
    },
  },
};
</script>
