<template>
  <BaseView>
    <Table
      :key="rerenderTableKey"
      apiEndpoint="ships"
      stickyHeaderHeight="1125px"
      :fields="shipsSchema"
      searchingEnabled
      v-bind:addButtonEnabled="() => true"
      v-bind:editButtonEnabled="
        (row) => {
          return isIRorBallastAdmin() || (row !== undefined && isUsersRecord(row));
        }
      "
      v-bind:deleteButtonEnabled="
        (row) => {
          return isIRorBallastAdmin() || (row !== undefined && isUsersRecord(row));
        }
      "
      v-bind:detailsButtonEnabled="
        () => {
          return isSubcriptionProOrUltimate();
        }
      "
      v-bind:viewButtonEnabled="() => false"
      @detailsClicked="navigateToShipsPage(...arguments)"
      @rerenderAfterGenericModal="rerenderAfterGenericModal"
    >
    </Table>
  </BaseView>
</template>

<script>
/* @ is a shortcut for ./src */
import BaseView from "./BaseView.vue";
import Table from "@/components/charts/Table.vue";
import { TABLE_SCHEMAS } from "./../config.js";
import { isSubcriptionProOrUltimate, isIRorBallastAdmin, isUsersRecord } from "./../utils/helpers.js";

export default {
  name: "Ships",
  components: {
    BaseView,
    Table,
  },
  data() {
    return {
      rerenderTableKey: true,
      shipsSchema: TABLE_SCHEMAS[this.$options.name],
    };
  },
  methods: {
    isSubcriptionProOrUltimate,
    isIRorBallastAdmin,
    isUsersRecord,
    rerenderAfterGenericModal() {
      this.rerenderTableKey = !this.rerenderTableKey;
    },
    navigateToShipsPage(row) {
      this.$router.push("/ships/" + row.item["ship_id"]);
    },
  },
};
</script>
