<template>
  <BaseView @rerenderAfterGenericModal="rerenderAfterGenericModal">
    <div
      class="text-center text-danger my-2"
      v-if="opportunityInfo == undefined"
      style="height: 350px; padding-top: 160px;"
    >
      <b-spinner class="align-middle"></b-spinner>
      <strong>Loading... {{ this.opportunityInfo }} </strong>
    </div>
    <div v-else-if="opportunityInfo.length == 0">
      <h2 class="banner-class-text">
        You are not authorized to view the information of this project
      </h2>
    </div>
    <b-container fluid v-else>
      <!-- Download quote button -->
      <b-button variant="outline-info" style="float: right;" v-on:click="downloadProjectQuote()" v-if="isAdminUser()">
        Generate Quote
      </b-button>

      <!-- First set of information: Account info and Project description -->
      <Collapsable label="GENERAL INFO" visible id="opportunity-info">
        <br />
        <b-form>
          <b-row class="info-row-border" style="background-color: white;">
            <b-col
              :sm="6"
              v-for="(field, idx) in opportunitiesSchema.filter((f) => f.onAdd && f.key != 'opportunity_description')"
              :key="idx"
              style="height: 70px;"
            >
              <b-row>
                <b-col sm="3" style="font-weight: bold;">
                  <span v-html="field.label"></span>
                </b-col>
                <b-col sm="8">
                  <b-checkbox v-if="field.checkbox" v-model="opportunityInfo[field.key]" disabled> </b-checkbox>
                  <b-form-group v-else>
                    <b-form-input :placeholder="opportunityInfo[field.key].toString()" disabled />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </Collapsable>
      <Collapsable label="Description" visible>
        <p style="white-space: pre-wrap;">
          {{ opportunityInfo.opportunity_description }}
        </p>
      </Collapsable>

      <!-- Second set of information: Service and project estimates -->
      <b-row>
        <div class="col-12">
          <Collapsable label="SERVICES" id="services" visible>
            <Table
              apiEndpoint="services"
              name="services"
              :key="rerenderTableKey"
              :fields="servicesRequestedSchema"
              :apiParams="{ opportunityId: this.$route.params.id }"
              @rerenderAfterGenericModal="rerenderAfterGenericModal"
            >
            </Table>
          </Collapsable>
        </div>

        <div class="col-12">
          <Collapsable label="PRODUCTS" id="products" visible>
            <Table
              apiEndpoint="products-requested"
              name="Product"
              :key="rerenderTableKey"
              :fields="productsRequestedSchema"
              :apiParams="{ opportunityId: this.$route.params.id }"
              @rerenderAfterGenericModal="rerenderAfterGenericModal"
            />
          </Collapsable>
        </div>

        <div class="col-12">
          <Collapsable label="DOCUMENTS" id="documents" visible>
            <FileManager
              :key="rerenderTableKey"
              :rootDir="'/opportunities/' + this.opportunityInfo['opportunity_id'] + '/'"
            />
          </Collapsable>
        </div>

        <div class="col-12">
          <Collapsable label="EXTERNAL LINKS" id="external-links" visible>
            <Table
              apiEndpoint="links"
              :key="rerenderTableKey"
              :fields="linksSchema"
              :apiParams="{ opportunityId: this.$route.params.id }"
              name="external link"
              :urlColumns="{'link_url':'link_name'}"
              @rerenderAfterGenericModal="rerenderAfterGenericModal"
            />
          </Collapsable>
        </div>
      </b-row>
    </b-container>
  </BaseView>
</template>

<script>
import BaseView from "@/views/BaseView.vue";
import Collapsable from "@/components/containers/Collapsable.vue";
import Table from "@/components/charts/Table.vue";
import { callEndpoint } from "@/utils/apicall.js";
import { currencyFormatter } from "@/components/style_formatters/currencyFormatter.js";
import store from "@/views/auth/store.js";
import { TABLE_SCHEMAS } from "@/config.js";
import { isAdminUser } from "@/utils/helpers.js";

export default {
  name: "OpportunitiesInfo",
  components: {
    BaseView,
    Collapsable,
    Table,
  },

  data() {
    return {
      store,
      items: [],
      opportunityInfo: undefined,
      opportunitiesSchema: TABLE_SCHEMAS["Opportunities"],
      linksSchema: TABLE_SCHEMAS["Links"].concat([
        { key: "opportunity_id", copyValue: parseInt(this.$route.params.id), hidden: true },
      ]),
      servicesRequestedSchema: TABLE_SCHEMAS["ServicesRequested"].concat([
        { key: "opportunity_id", copyValue: parseInt(this.$route.params.id), hidden: true },
      ]),
      productsRequestedSchema: TABLE_SCHEMAS["ProductsRequested"].concat([
        { key: "opportunity_id", copyValue: parseInt(this.$route.params.id), hidden: true },
      ]),
      rerenderTableKey: false,
    };
  },
  methods: {
    isAdminUser,
    currencyFormatter,

    rerenderAfterGenericModal() {
      this.rerenderTableKey = !this.rerenderTableKey;
    },

    downloadProjectQuote() {
      const currDate = new Date().toJSON().slice(0, 10).replace(/-/g, "");
      callEndpoint(
        "download-quote",
        (data) => {
          var fileURL = window.URL.createObjectURL(data);
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", this.opportunityInfo["opportunity_title"] + "_quote_" + currDate + ".docx");
          document.body.appendChild(fileLink);
          fileLink.click();
        },
        { id: this.$route.params.id, obj_type: "opportunity" },
        "GET",
        {},
        null,
        "blob"
      );
    },
  },

  mounted() {
    callEndpoint(
      "opportunities",
      (data) => {
        if (data.length > 0) this.opportunityInfo = data[0];
        else this.opportunityInfo = [];
      },
      { opportunityId: this.$route.params.id },
      "GET"
    );
  },
};
</script>

<style>
.banner-class-text {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #593b8a;
}

/* Controls how much space there should be after specific tables only when the table is in a collapsable and it is in shown state */
#opportunity-info .mt-2.collapse.show,
#services .mt-2.collapse.show,
#products .mt-2.collapse.show,
#documents .mt-2.collapse.show {
  padding-bottom: 32px;
}
</style>
