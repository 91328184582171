<template>
  <div class="dashboard-panel" :style="getBackgroundColor()">
    <div class="statistics">
      <p>
        {{ count }}
        <br />
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
import { callEndpoint } from "./../../utils/apicall.js";

export default {
  name: "StatisticsContainer",
  props: {
    text: String,
    backgroundColor: {
      type: String,
      default: "white"
    },
    apiEndpoint: String,
    apiParams: {}
  },
  data() {
    return {
      count: undefined
    };
  },
  methods: {
    getBackgroundColor() {
      return "background-color: " + this.backgroundColor;
    }
  },
  created() {
    callEndpoint(
      this.apiEndpoint,
      data => {
        this.count = data;
      },
      this.apiParams
    );
  }
};
</script>

<style>
.dashboard-panel {
  line-height: 120px;
  border-style: solid;
  border-width: 1.5px;
  border-color: lightgray;
}
.statistics {
  font-weight: bold;
  margin-left: 10px;
}
.statistics p {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.5;
}
</style>