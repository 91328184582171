<template>
  <div>
    <!-- Bootstrap modal popup window: https://bootstrap-vue.js.org/docs/components/modal/ -->
    <b-modal
      :id="this.$options.name"
      ok-only
      hide-header-close
      centered
      no-stacking
      no-close-on-backdrop
      ok-title="Extend Session"
      class="text-center"
    >
      You have been inactive for more than {{ totalInactivityPeriodMinutes }} minutes. For security reasons you will be
      automatically be logged off in {{ finalInactivityPeriodSeconds }} seconds.
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../views/auth/store.js";
import IdleVue from "idle-vue";

Vue.use(IdleVue, {
  eventEmitter: new Vue(),
  store,
  idleTime: 10000, // 10 seconds,
  startAtIdle: false,
});

const timeoutMilliseconds = 3600000 * 4;
const intervalMilliseconds = 60000;

export default {
  name: "IdleAlertLogoutModal",
  data() {
    return {
      time: timeoutMilliseconds,
      totalInactivityPeriodMinutes: 60,
      finalInactivityPeriodSeconds: 60,
    };
  },

  created() {
    let timerId = setInterval(() => {
      this.time -= intervalMilliseconds;
      // eslint-disable-next-line
      // console.log(this.time + " milliseconds remaining ");
      if (!store.state.idleVue.isIdle) {
        this.time = timeoutMilliseconds;
      }

      if (this.time < this.finalInactivityPeriodSeconds * 1000) {
        this.$bvModal.show("IdleAlertLogoutModal");
      }

      if (this.time < 1) {
        this.$bvModal.hide("IdleAlertLogoutModal");
        sessionStorage.removeItem("user-token");
        clearInterval(timerId);
        this.$router.push("/");
      }
    }, intervalMilliseconds);
  },
};
</script>
