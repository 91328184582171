<template>
  <div>
    <!-- Buttons -->
    <div class="file-manager-buttons-container">
      <!-- Folder up button -->
      <b-button
        variant="outline-info"
        @click="moveOneFolderHigher()"
        style="float: left"
        class="file-manager-button-margin"
      >
        <i class="fa fa-arrow-up fa-xs" />
      </b-button>

      <!-- Text on left showing current folder -->
      <div style="float: left; color: #17a2b8; font-size: 24px">
        {{ getActiveFolder() }}
      </div>

      <b-button v-if="displayErrorMsg" variant="danger" style="display: inline-block; margin-right: 15px">
        {{ this.errorMsg }}
      </b-button>

      <!-- Download -->
      <b-button
        variant="outline-info"
        class="file-manager-button-margin"
        @click="downloadZip()"
        :disabled="this.numberUploadedFiles == 0"
      >
        Download All
      </b-button>

      <!-- Upload Files -->
      <b-button
        variant="outline-info"
        @click="$refs['upload-files'].click()"
        class="file-manager-button-margin"
        v-if="isIRBallastAdminManufacturer()"
      >
        Upload Files
      </b-button>
      <!-- display: none in the next line hides the default file selector button, to replace it with our nice bootstrap button above -->
      <input
        type="file"
        ref="upload-files"
        multiple
        style="display: none"
        v-on:change="upload('upload-files')"
        v-if="isIRBallastAdminManufacturer()"
      />

      <!-- Upload Directory -->
      <b-button
        variant="outline-info"
        @click="$refs['upload-directory'].click()"
        v-if="isIRBallastAdminManufacturer() && this.directoryUploadEnabled"
      >
        Upload Directory
      </b-button>
      <!-- display: none in the next line hides the default file selector button, to replace it with our nice bootstrap button above -->
      <input
        type="file"
        ref="upload-directory"
        multiple
        webkitdirectory
        mozdirectory
        style="display: none"
        v-on:change="upload('upload-directory')"
        v-if="isIRBallastAdminManufacturer() && this.directoryUploadEnabled"
      />
    </div>

    <!-- The actual table displaying files and folders -->
    <Table
      ref="uploadedFiles"
      :key="this.currentPath + '/' + this.onDeleteRefresh"
      apiEndpoint="files"
      :apiParams="{ path: this.currentPath }"
      :fields="getTableFields()"
      :templates="['name', 'Share']"
      :onRefresh="setNumberOfUploadedFiles"
      v-bind:addButtonEnabled="() => false"
      v-bind:editButtonEnabled="() => false"
      v-bind:viewButtonEnabled="() => false"
      v-bind:deleteButtonEnabled="() => !this.showShareColumn"
      @rerenderAfterGenericModal="rerenderAfterGenericModal"
      :disableDisplayMsg="true"
    >
      <!-- Customize particular columns -->
      <!-- Make Title a hyperlink to download the filename -->
      <template v-slot:name="{ data }">
        <i :class="getFAIconFromFilenameExtension(data.item.name)" style="padding-right: 15px; color: #6c757d"></i>
        <!-- Files -->
        <a href="#" v-on:click="downloadFile(data.item.name, data.item.id)" v-if="data.item.type == 'file'">
          {{ data.item.name }}
        </a>
        <!-- Folders -->
        <a href="#" v-on:click="appendToPathAndRefresh(data.item.name)" v-else>
          {{ data.item.name }}
        </a>
      </template>

      <!-- "SHARE" button (no support for folders so far). Disabled for now on 02/08/2020 -->
      <!-- <template v-slot:Share="{ data }">
        <b-button
          v-on:click="showShareModal(data.item.id)"
          v-b-tooltip.hover.topright="'Share'"
          size="sm"
          v-if="data.item.type != 'folder' && isAdminUser()"
        >
          <i class="fa fa-share-alt" aria-hidden="true"></i>
        </b-button>
      </template> -->
    </Table>
    <!-- <ShareModal :projectId="this.$route.params.id" /> -->
  </div>
</template>

<script>
import { callEndpoint } from "@/utils/apicall.js";
import Table from "@/components/charts/Table.vue";
import getClassNameForExtension from "font-awesome-filetypes";
// import ShareModal from "@/modals/ShareModal.vue";
import { getUsername } from "@/utils/helpers.js";
import { isAdminUser, isIRBallastAdminManufacturer } from "@/utils/helpers.js";

export default {
  name: "FileManager",

  components: {
    Table,
    // ShareModal,
  },

  props: {
    showShareColumn: {
      type: Boolean,
      default: false,
    },
    rootDir: {
      type: String,
      default: "/", // *** MUST *** be unique across all instances of filemanager
    },
    directoryUploadEnabled: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      currentPath: this.rootDir,
      numberUploadedFiles: 0,
      onDeleteRefresh: false,
      displayErrorMsg: false,
      errorMsg: "",
    };
  },

  methods: {
    isAdminUser,
    isIRBallastAdminManufacturer,
    getUsername,
    getFAIconFromFilenameExtension(filename) {
      const extension = filename.split(".").pop();
      const i = getClassNameForExtension(extension);
      return "fa " + i;
    },

    appendToPathAndRefresh(folder) {
      if (this.currentPath == this.rootDir) this.currentPath = this.currentPath + folder;
      else this.currentPath = this.currentPath + "/" + folder;
    },

    formFile(data, outputFileName) {
      var fileURL = window.URL.createObjectURL(data);
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", outputFileName);
      document.body.appendChild(fileLink);
      fileLink.click();
    },

    downloadFile(outputFileName, fileId) {
      callEndpoint("files/" + fileId, (data) => this.formFile(data, outputFileName), {}, "GET", {}, null, "blob");
    },

    downloadZip() {
      callEndpoint(
        "download-zip",
        (data) => this.formFile(data, "download.zip"),
        { path: this.currentPath },
        "GET",
        {},
        null,
        "blob"
      );
    },

    upload(type) {
      // Attach all the files as part of the body
      let formData = new FormData();
      const files = this.$refs[type].files;
      const MAX_FILE_UPLOAD_SIZE_LIMIT_MB = 20;

      // Check if the cumulative file size exceeds 5MB
      var total_file_size = new Number(0);
      for (var i = 0; i < files.length; i++) {
        total_file_size += parseFloat((files[i].size / 1024 / 1024).toFixed(4)); // MB
      }
      if (total_file_size >= MAX_FILE_UPLOAD_SIZE_LIMIT_MB) {
        this.displayErrorMsg = true;
        this.errorMsg = "Max upload size: " + MAX_FILE_UPLOAD_SIZE_LIMIT_MB + " MB";
        setTimeout(() => (this.displayErrorMsg = false), 6000);
        return;
      }

      // If we are ok file-size wise, then proceed with the upload
      for (i = 0; i < files.length; i++) {
        let file = files[i];
        formData.append("files[" + i + "]", file);
      }

      // For being able to upload the same file twice, we need the following
      // https://stackoverflow.com/questions/4109276/how-to-detect-input-type-file-change-for-the-same-file
      this.$refs[type].value = "";

      // Submit them to the backend
      callEndpoint(
        "files",
        () => {
          // Just refresh table. Remember, we use children[0] because Table is an abstraction over b-table
          this.$refs["uploadedFiles"].$children[0].$emit("refresh");
        },
        { path: this.currentPath },
        "POST",
        formData,
        (error) => {
          this.displayErrorMsg = true;
          this.errorMsg = error.response.data;
          setTimeout(() => (this.displayErrorMsg = false), 6000);
        },
        null,
        "multipart/form-data"
      );
    },

    setNumberOfUploadedFiles(tableData) {
      this.numberUploadedFiles = tableData.length;
    },

    getTableFields() {
      const arr = [
        { key: "id", primaryKey: true, hidden: true },
        { key: "name", label: "Title" },
        { key: "date_created", label: "Date Created" },
        { key: "full_name", label: "Created By" },
      ];
      // Add a share button ONLY if this is an owned (== not shared) projhect
      if (!this.showShareColumn)
        arr.push({
          key: "Share",
          label: "",
          disableSorting: true,
        });
      return arr;
    },

    getActiveFolder() {
      return this.currentPath == this.rootDir ? "" : this.currentPath.split("/").pop();
    },

    moveOneFolderHigher() {
      const base = new String(this.currentPath).substring(0, this.currentPath.lastIndexOf("/"));
      this.currentPath = base + "/" == this.rootDir ? this.rootDir : base;
    },

    // showShareModal(fileId) {
    //   this.$root.$emit("bv::show::modal", "ShareModal", fileId);
    // },

    rerenderAfterGenericModal() {
      this.onDeleteRefresh = !this.onDeleteRefresh;
    },
  },
};
</script>

<style scoped>
.file-manager-buttons-container {
  padding-bottom: 20px;
  text-align: right;
}
.file-manager-button-margin {
  margin-right: 15px;
}
</style>
