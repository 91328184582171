<template>
  <b-modal
    :id="this.$options.name"
    size="lg"
    centered
    hide-footer
    header-class="register-user-header-class"
    @show="resetModal"
    @hidden="resetModal"
  >
    <transition name="fade" mode="out-in">
      <div class="newUserModalHeight" v-if="registerUserPaneShown">
        <div class="register-user-title-style">
          <h1>Join Mouawad Consulting now</h1>
        </div>
        <b-form @submit="onSubmit">
          <!-- First row -->
          <div class="row registerUserRowSpacing">
            <div class="col">
              <b-form-group label="First Name" label-for="input-first-name">
                <b-form-input
                  id="input-first-name"
                  v-model="form.firstname"
                  type="text"
                  required
                  pattern="^([a-zA-Z]+\s*)*[a-zA-Z]+$"
                  :oninvalid="getInvalidText(form.firstname)"
                  oninput="this.setCustomValidity('')"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group label="Last Name" label-for="input-last-name">
                <b-form-input
                  id="input-last-name"
                  v-model="form.lastname"
                  type="text"
                  required
                  pattern="^([a-zA-Z]+\s*)*[a-zA-Z]+$"
                  :oninvalid="getInvalidText(form.lastname)"
                  oninput="this.setCustomValidity('')"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <!-- Second row -->
          <div class="row registerUserRowSpacing">
            <div class="col">
              <b-form-group label="Email address" label-for="input-email">
                <b-form-input id="input-email" v-model="form.email" type="email" required></b-form-input>
                <b-form-invalid-feedback :state="emailValid">
                  You have already registered this e-mail. Have you forgotten your password?
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group label="Phone Number" label-for="input-phone">
                <b-form-input
                  id="input-phone"
                  v-model="form.phone"
                  type="tel"
                  required
                  pattern="(?:00|\+)[0-9]{4,15}"
                  onInvalid="setCustomValidity('Phone number can only have from 4 to 15 phone digits, starting by a single \'+\' or 00 without any spaces')"
                  oninput="this.setCustomValidity('')"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <!-- Third row -->
          <div class="row registerUserRowSpacing">
            <div class="col">
              <b-form-group label="Password" label-for="input-password">
                <b-input-group>
                  <b-form-input
                    id="input-password"
                    v-model="form.password"
                    :type="passwordFieldType"
                    :state="passwordState"
                    required
                  ></b-form-input>
                  <b-form-invalid-feedback :state="passwordState">
                    Password must be at least 8 characters long
                  </b-form-invalid-feedback>
                  <b-input-group-append>
                    <span
                      class="fa fa-eye register-user-hide-password-button"
                      type="password"
                      @click="switchVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group label="Repeat Password" label-for="input-repeat-password">
                <b-input-group>
                  <b-form-input
                    id="input-repeat-password"
                    v-model="form.repeatPassword"
                    :type="passwordFieldType"
                    :state="repeatPasswordState"
                    required
                  ></b-form-input>
                  <b-form-invalid-feedback :state="repeatPasswordState">
                    Password must be at least 8 characters long
                  </b-form-invalid-feedback>
                  <b-input-group-append>
                    <span
                      class="fa fa-eye register-user-hide-password-button"
                      type="password"
                      @click="switchVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-invalid-feedback :state="passwordsMatch()"> Your passwords do not match </b-form-invalid-feedback>
            </div>
          </div>
          <!-- Fourth row -->
          <div class="row registerUserRowSpacing">
            <div class="col">
              <b-form-group label="Company" label-for="input-company">
                <b-form-input
                  id="input-company"
                  v-model="form.company_user"
                  type="text"
                  required
                  pattern="^([a-zA-Z]+\s*)*[a-zA-Z]+$"
                  :oninvalid="getInvalidText(form.company_user)"
                  oninput="this.setCustomValidity('')"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group label="Country">
                <b-form-select :options="country_names" v-model="form.country" required />
              </b-form-group>
            </div>
          </div>
          <!-- Fourth row -->
          <div class="row registerUserRowSpacing">
            <div class="col">
              <b-form-group label="Webpage (Optional)" label-for="input-webpage">
                <b-form-input
                  id="input-webpage"
                  v-model="form.url"
                  type="url"
                  onInvalid="setCustomValidity('A valid url starts with http:// or https:// (e.g. https://google.com)')"
                  oninput="this.setCustomValidity('')"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col">
              <VueRecaptcha
                @verify="markRecaptchaAsVerified"
                @expired="markRecaptchaAsInvalid"
                @error="markRecaptchaAsInvalid"
                sitekey="6Lcov-gUAAAAABDeprjgVyVSfvKQUv70KJFTgdSO"
                :loadRecaptchaScript="true"
              ></VueRecaptcha>
              <b-form-invalid-feedback :state="captchaFilled()">Please complete the captcha</b-form-invalid-feedback>
            </div>
          </div>
          <!-- Fifth and sixth rows -->
          <div class="row registerUserRowSpacing">
            <div class="col">
              <div class="radio-list-label" style="padding-left: 0px">Are you managing ships?</div>
              <b-form-radio-group
                v-model="form.manages_ships"
                :options="options"
                class="mb-3"
                value-field="item"
                text-field="name"
              ></b-form-radio-group>
            </div>
            <div class="col">
              <div class="radio-list-label" style="padding-left: 0px">Are you a BWMS manufacturer?</div>
              <b-form-radio-group
                v-model="form.isManufacturer"
                :options="options"
                class="mb-3"
                value-field="item"
                text-field="name"
              ></b-form-radio-group>
            </div>
          </div>

          <div style="float: left">
            <b-form-checkbox
              id="new-user-accept-conditions-checkbox"
              v-model="acceptedConditions"
              name="new-user-accept-conditions-checkbox"
              value="accepted"
              unchecked-value="not_accepted"
              required
              class="register-user-term-checkbox"
            />
            I agree to the Mouawad Consulting User Agreement, Privacy Policy, and Cookie Policy
            <!-- Final text -->
            <p style="text-align: center; color: grey">
              We will not, in any circumstances, share your personal information with<br />other individuals or
              organizations without your permission.
            </p>
            <!-- Submit button -->
            <div class="register-user-register-button">
              <b-button type="submit" variant="primary">Register Now</b-button>
            </div>
          </div>
        </b-form>
      </div>
      <div v-else class="forgot-password-label forget-password-root-div-conf" style="padding-top: 55px">
        <h2>
          An email to activate your account
          <br />was sent to your inbox
        </h2>
      </div>
    </transition>
  </b-modal>
</template>

<script>
  import { callEndpoint } from "./../utils/apicall.js";

  export default {
    name: "RegisterUserModal",

    data() {
      return {
        country_names: [],
        form: {
          email: "",
          firstname: "",
          lastname: "",
          phone: "",
          password: "",
          repeatPassword: "",
          country: undefined,
          url: "",
          company_user: "",
          manages_ships: false,
          isManufacturer: false,
        },
        registerUserPaneShown: true,
        options: [
          { item: true, name: "Yes" },
          { item: false, name: "No" },
        ],
        emailValid: true,
        acceptedConditions: false,
        attemptedSubmit: false,
        passwordFieldType: "password",
        captchaCompleted: false,
      };
    },

    methods: {
      markRecaptchaAsVerified() {
        this.captchaCompleted = true;
      },

      markRecaptchaAsInvalid() {
        this.captchaCompleted = false;
      },

      onSubmit(evt) {
        evt.preventDefault();
        this.attemptedSubmit = true;
        this.emailValid = true;
        if (this.allFieldsValid()) {
          callEndpoint(
            "users",
            /* success callback */
            () => {
              /* Flip it over to the "an email has been sent" page */
              this.registerUserPaneShown = false;
            },
            {},
            "POST",
            this.form,
            /* error handler */
            (error) => {
              const status = error.response.status;
              if (status == 409) {
                // Indicates there was something wrong (conflict) with the submitted data
                const error_type = error.response.data["error_type"];
                if (error_type == "UNIQUE_KEY") {
                  const conflicting_attr = error.response.data["error_attr"];
                  if (conflicting_attr[0] == "email") {
                    this.emailValid = false;
                  }
                }
              }
            }
          );
        }
      },

      resetModal() {
        this.form.email = "";
        this.form.firstname = "";
        this.form.lastname = "";
        this.form.phone = "";
        this.form.password = "";
        this.form.repeatPassword = "";
        this.form.country = undefined;
        this.form.url = "";
        this.form.company_user = "";
        this.form.manages_ships = false;
        this.form.isManufacturer = false;
        this.emailValid = true;
        this.acceptedConditions = false;
        this.attemptedSubmit = false;
        this.passwordFieldType = "password";
        this.captchaCompleted = false;
        this.registerUserPaneShown = true;
      },

      allFieldsValid() {
        return (
          this.acceptedConditions &&
          this.countryValid() &&
          this.captchaCompleted &&
          this.passwordsMatch() &&
          this.repeatPasswordState != false &&
          this.passwordState != false
        );
      },

      countryValid() {
        if (!this.attemptedSubmit) return null;
        return !(this.form.country == undefined || this.form.country === "");
      },

      captchaFilled() {
        return !(this.attemptedSubmit == true && this.captchaCompleted == false);
      },

      getInvalidText(field) {
        if (field == "") {
          return ""; // Default return
        } else {
          return "setCustomValidity('This field can only include letters (small or capital) and single spaces between words')";
        }
      },

      passwordsMatch() {
        return !this.attemptedSubmit || this.form.password === this.form.repeatPassword;
      },

      switchVisibility() {
        this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
      },
    },

    computed: {
      repeatPasswordState() {
        return !this.attemptedSubmit || this.form.repeatPassword.length >= 8 ? null : false;
      },
      passwordState() {
        return !this.attemptedSubmit || this.form.password.length >= 8 ? null : false;
      },
    },

    created() {
      // Countries is an open endpoint which needs to be loaded for the register user pane
      callEndpoint("countries", (cnames) => (this.country_names = cnames));
    },
  };
</script>

<style>
  .newUserModalHeight {
    height: 900px;
    max-height: 900px;
    margin-left: 40px;
    margin-right: 40px;
    border-spacing: 50em;
  }

  .custom-control-inline {
    margin-right: 0.5rem;
  }

  .register-user-title-style {
    color: #593b8a;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 50px;
  }

  .registerUserRowSpacing {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .register-user-header-class {
    border-bottom: 0px solid white !important;
  }

  .register-user-register-button {
    text-align: center;
    padding-top: 20px;
  }

  .radio-list-label {
    padding-left: 16px;
    padding-right: 12px;
  }

  .register-user-term-checkbox {
    display: inline-block !important;
    padding-top: 10px;
    padding-bottom: 30px;
  }

  .form-control,
  .d-block {
    display: block !important;
    width: 100% !important;
  }

  .register-user-hide-password-button {
    position: absolute;
    top: 1px;
    right: 9px;
    border: 1px solid transparent;
    background: transparent;
    line-height: 35px;
    font-size: 0.8rem;
    text-transform: uppercase;
    cursor: pointer;
    z-index: 100000;
  }
</style>
