<template>
  <BaseView>
    <Table
      id="mcqscTable"
      :key="rerenderTableKey"
      apiEndpoint="mcqsc"
      stickyHeaderHeight="1125px"
      :fields="mcqscSchema"
      searchingEnabled
      @rerenderAfterGenericModal="rerenderAfterGenericModal"
    ></Table>
  </BaseView>
</template>



<script>
import BaseView from "./../BaseView.vue";
import Table from "@/components/charts/Table.vue";
import { TABLE_SCHEMAS } from "./../../config.js";

export default {
  name: "MCQSC",

  data() {
    return {
      rerenderTableKey: true,
      mcqscSchema: TABLE_SCHEMAS[this.$options.name]
    };
  },

  components: {
    BaseView,
    Table
  },

  methods: {
    rerenderAfterGenericModal() {
      this.rerenderTableKey = !this.rerenderTableKey;
    }
  }
};
</script>
