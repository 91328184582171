<template>
  <div :key="resetTransition">
    <b-modal
      :id="this.$options.name"
      hide-footer
      centered
      no-stacking
      no-close-on-backdrop
      size="lg"
      header-class="reset-password-header-class"
      footer-class="reset-password-footer-class"
      content-class="reset-password-content-class"
      body-class="reset-password-body-class"
      @ok="handleEvent"
      @close="resetModal"
    >
      <transition name="fade" mode="out-in">
        <div v-if="setNewPwPaneShown" key="pane#1" class="reset-password-root-div-conf">
          <div class="forgot-password-label">
            <h1>Let's reset your password</h1>
          </div>
          <div>
            <div style="width: 450px; display: inline-block;">
              <b-form @submit="handleEvent">
                <div style="text-align: left">
                  <b-form-group label="Enter your new desired password in the field below:">
                    <b-input-group>
                      <b-form-input
                        id="reset-password-field"
                        v-model="newPassword"
                        :type="passwordFieldType"
                        required
                      ></b-form-input>
                      <b-input-group-append>
                        <span class="fa fa-eye hide-password-button" type="password" @click="switchVisibility" />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group label="Please confirm your new password:">
                    <b-input-group>
                      <b-form-input
                        id="reset-password-field-confirmed"
                        v-model="newPasswordConfirmed"
                        :type="passwordFieldType"
                        required
                      ></b-form-input>
                      <b-input-group-append>
                        <span class="fa fa-eye hide-password-button" type="password" @click="switchVisibility" />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div style="height: 25px">
                  <!-- Must match the padding on h4 above (sign in text) -->
                  <h6 v-if="!passwordsMatch()" style="color: red">Your passwords do not match</h6>
                </div>
                <div style="height:110px; min-height:110px">
                  <div style="padding-left: 73px">
                    <VueRecaptcha
                      @verify="markRecaptchaAsVerified"
                      @expired="markRecaptchaAsInvalid"
                      @error="markRecaptchaAsInvalid"
                      sitekey="6Lcov-gUAAAAABDeprjgVyVSfvKQUv70KJFTgdSO"
                      :loadRecaptchaScript="true"
                    ></VueRecaptcha>
                    <br />
                  </div>
                  <div style="margin-top: -25px">
                    <b-form-invalid-feedback :state="captchaFilled()"
                      >Please complete the captcha</b-form-invalid-feedback
                    >
                  </div>
                </div>
                <br />
                <b-button :disabled="this.resetPwButtonDisabled" type="submit" variant="primary">
                  SUBMIT
                </b-button>
              </b-form>
            </div>
          </div>
        </div>
        <div v-else key="pane#2" class="forgot-password-label reset-password-root-div-conf" style="padding-top: 160px;">
          <h2>{{ msg }}</h2>
        </div>
      </transition>
    </b-modal>
  </div>
</template>

<script>
import { callEndpoint } from "./../utils/apicall.js";

export default {
  name: "ResetPasswordModal",

  props: {
    token: String
  },

  data() {
    return {
      newPassword: null,
      newPasswordConfirmed: null,
      setNewPwPaneShown: true,
      resetTransition: true,
      resetPwButtonDisabled: false,
      attemptedReset: false,
      passwordFieldType: "password", // For switching between hidden and non hidden password
      captchaCompleted: false,
      attemptedSubmit: false,
      msg: ""
    };
  },

  methods: {
    markRecaptchaAsVerified() {
      this.captchaCompleted = true;
    },

    markRecaptchaAsInvalid() {
      this.captchaCompleted = false;
    },

    captchaFilled() {
      return !(this.attemptedSubmit == true && this.captchaCompleted == false);
    },

    handleEvent(evt) {
      evt.preventDefault();
      if (this.setNewPwPaneShown) {
        this.attemptedReset = true;
        this.handleProvideNewPwPaneClose();
      } else this.handlePasswordIsResetPaneClose(evt);
    },

    handleProvideNewPwPaneClose() {
      this.attemptedSubmit = true;
      if (this.newPassword == null) return;
      if (this.captchaCompleted == false) return;

      if (this.passwordsMatch()) {
        // Disable the button so that users cannot click it again
        this.resetPwButtonDisabled = true;

        callEndpoint(
          "reset-password",
          /* success callback */
          () => {
            this.msg =
              "Your password has been successfully reset. Please try to login with your new credentials.";
            /* Switch to the "your password has been send pane" or error msg page */
            this.setNewPwPaneShown = false;
          },
          {} /* No special query param, we pass data in body as last arg */,
          "POST",
          {
            newPassword: this.newPassword,
            token: this.token
          },
          /* error handler */
          err => {
            this.msg =
              err.response.data["error"] +
              " Please request a new password reset token and try resetting your password again";
            /* Switch to the "your password has been send pane" or error msg page */
            this.setNewPwPaneShown = false;
          }
        );
      }
    },

    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
    },

    handlePasswordIsResetPaneClose() {
      /* Just close the modal */
      this.resetModal();
      this.$bvModal.hide(this.$options.name);
    },

    resetModal() {
      this.newPassword = null;
      this.setNewPwPaneShown = true;
      this.resetTransition = !this.resetTransition;
      this.attemptedSubmit = false;
      this.captchaCompleted = false;
    },

    passwordsMatch() {
      return !this.attemptedReset || this.newPassword === this.newPasswordConfirmed;
    }
  }
};
</script>

<style>
.forgot-password-label {
  color: #593b8a;
  padding-bottom: 30px;
}
.reset-password-footer-class {
  border-top: 0px solid white !important;
  display: inline-block;
}
.reset-password-body-class {
  padding-bottom: 0px !important;
}
.reset-password-root-div-conf {
  text-align: center;
}
.reset-password-content-class {
  height: 540px !important;
  min-height: 540px !important;
}
.reset-password-root-div-conf {
  border-bottom: 0px solid white !important;
}
.reset-password-header-class {
  border-bottom: 0px solid white !important;
}
/* Transition CSS */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.hide-password-button {
  position: absolute;
  top: 1px;
  right: 9px;
  border: 1px solid transparent;
  background: transparent;
  line-height: 35px;
  font-size: 0.8rem;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 100000;
}
</style>
