var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseView',[_c('Table',{key:_vm.rerenderTableKey,attrs:{"apiEndpoint":"systems","stickyHeaderHeight":"1125px","fields":_vm.systemsSchema,"searchingEnabled":"","addButtonEnabled":function () {
        return _vm.isUserAdminBallastAdminManufacturer();
      },"editButtonEnabled":function (row) {
        return _vm.isUserAdminBallastAdmin() || (row !== undefined && _vm.isUsersRecord(row));
      },"deleteButtonEnabled":function (row) {
        return _vm.isUserAdminBallastAdmin() || (row !== undefined && _vm.isUsersRecord(row));
      },"detailsButtonEnabled":function () { return true; },"booleanColumns":['active_substrances', 'ams_designation', 'explosion_proofing'],"templates":['is_active']},on:{"detailsClicked":function($event){return _vm.navigateToSystemsPage.apply(void 0, arguments)},"rerenderAfterGenericModal":function($event){_vm.rerenderTableKey = !_vm.rerenderTableKey}},scopedSlots:_vm._u([{key:"is_active",fn:function(ref){
      var data = ref.data;
return [(data.item['is_active'])?_c('div',{staticStyle:{"text-align":"center"}},[_vm._v("🟢")]):_c('div',{staticStyle:{"text-align":"center"}},[_vm._v("⚫")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }