<template>
  <b-modal
    :size="getModalSize"
    :id="this.$options.name"
    centered
    hide-footer
    header-class="generic-modal-header-class"
    title-class="generic-modal-title-class"
    @hidden="resetModal"
    content-class="generic-modal-content-class"
    no-close-on-backdrop
    ref="modal"
  >
    <template v-slot:modal-title>
      <span>{{ title }}</span>
      <a v-if="generalError" style="color: red" v-html="'- ' + generalErrorMsg" />
    </template>
    <div class="generic-modal-props">
      <b-form @submit="onSubmit">
        <div class="row">
          <div :class="getColClass(field)" v-for="(field, idx) in this.viewableFields" :key="idx">
            <b-form-group>
              <template slot="label">
                <div class="d-flex justify-content-between">
                  <span v-html="field.label"></span>
                  <a
                    style="color: #007bff; font-size: 13px; padding-top: 3px; cursor: pointer"
                    @click="field.addItemFn($router)"
                    v-if="field.addItemFn && operation != 'VIEW'"
                  >
                    Add New {{ field.label }}
                  </a>
                </div>
              </template>

              <!-- Search and select dropdown -->
              <b-input-group v-if="(field.isDropdown || field.options) && field.searchable == true">
                <model-select
                  :options="dict[field.key]"
                  v-model="form[getFormKey(field)]"
                  v-bind:isDisabled="isReadOnly"
                  v-bind:isError="getState(field) == false"
                  @input="itemUpdated"
                >
                </model-select>
                <b-form-invalid-feedback :state="getState(field)">
                  {{ getErrorMsg(field) }}
                </b-form-invalid-feedback>
              </b-input-group>

              <!-- Simple Dropdown (Without search) -->
              <b-input-group v-else-if="field.isDropdown || field.options">
                <b-form-select
                  :options="dict[field.key]"
                  v-model="form[getFormKey(field)]"
                  v-on:change="itemUpdated"
                  :state="getState(field)"
                  v-bind:disabled="isReadOnly"
                  :required="getRequired(field)"
                />
                <b-form-invalid-feedback :state="getState(field)">
                  {{ getErrorMsg(field) }}
                </b-form-invalid-feedback>
              </b-input-group>

              <!-- TextArea -->
              <b-form-textarea
                rows="4"
                v-model="form[field.key]"
                required
                v-else-if="field.textarea"
                v-on:change="itemUpdated"
                v-bind:disabled="isReadOnly"
              />

              <!-- Date Picker -->
              <a v-else-if="field.format && field.format == 'date'">
                <b-form-datepicker
                  v-model="form[field.key]"
                  today-button
                  reset-button
                  close-button
                  :state="dateFieldValid(field)"
                  v-on:change="itemUpdated"
                  v-bind:disabled="isReadOnly"
                />
                <b-form-invalid-feedback :state="dateFieldValid(field)">
                  {{ getErrorMsg(field) }}
                </b-form-invalid-feedback>
              </a>

              <!-- Checkbox -->
              <b-form-checkbox
                v-else-if="field.checkbox"
                v-model="form[field.key]"
                :state="form[field.key]"
                size="lg"
                v-on:change="itemUpdated"
                v-bind:disabled="isReadOnly"
              />

              <!-- Component -->
              <component
                :is="field.componentName"
                :row="record"
                :operation="operation"
                v-else-if="field.componentName"
                v-on:change="itemUpdated"
                v-bind="field.componentParams(form)"
              />

              <!-- Multiselect dropdown -->
              <div v-else-if="field.isMultiSelectDropdown" style="height: 50px">
                <multiselect
                  v-if="dict[field.key] && dict[field.key].length > 0"
                  v-model="form[field.key]"
                  :options="dict[field.key]"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :allowEmpty="true"
                  :preselectFirst="false"
                  :preserve-search="true"
                  placeholder="Please select values"
                  label="model_name"
                  track-by="model_name"
                  :max-height="160"
                  :preselect-first="true"
                  v-bind:disabled="isReadOnly"
                ></multiselect>
                <div v-else><h6>No valid entries to select from</h6></div>
              </div>

              <!-- Table -->
              <div v-else-if="field.isTable">
                <Table
                  :apiEndpoint="field.tableApiEndpoint"
                  :fields="field.tableFields"
                  :apiParams="field.tableApiParams(form)"
                  v-bind:addButtonEnabled="() => true"
                  v-bind:editButtonEnabled="() => true"
                  v-bind:deleteButtonEnabled="() => true"
                  v-bind:viewButtonEnabled="() => false"
                  :booleanColumns="field.tableBooleanColumns"
                  :copyApiParamsToBody="true"
                  :name="field.tableName"
                  v-bind:disableTableBorder="field.disableTableBorder"
                ></Table>
              </div>

              <!-- Rich editor -->
              <div v-else-if="field.richeditor">
                <wysiwyg v-model="form[field.key]" />
              </div>

              <!-- Simple fill field -->
              <a v-else>
                <b-form-input
                  v-model="form[field.key]"
                  :placeholder="getPlaceholder(field.key)"
                  :required="field.required !== undefined ? field.required : true"
                  v-bind:type="field.type !== undefined ? field.type : 'text'"
                  :state="getState(field)"
                  v-on:change="itemUpdated"
                  v-bind:number="field.type == 'number'"
                  v-bind:pattern="field.pattern"
                  :min="field.min"
                  :max="field.max"
                  :step="field.step !== undefined ? field.step : 1"
                  v-bind:oninvalid="getInvalidText(form[field.key], field)"
                  oninput="this.setCustomValidity('')"
                  v-bind:disabled="isReadOnly"
                  trim
                />
                <b-form-invalid-feedback :state="getState(field)">
                  {{ getErrorMsg(field) }}
                </b-form-invalid-feedback>
              </a>
            </b-form-group>
          </div>
        </div>
        <div class="row generic-modal-row-spacing" v-if="operation != 'VIEW'">
          <div class="col-12" style="text-align: center">
            <b-button variant="outline-info" type="submit">
              {{ getButtonName() }}
            </b-button>
          </div>
        </div>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import { callEndpoint } from "./../utils/apicall.js";
import store from "./../views/auth/store.js";
import componentBus from "./../main.js";
import { VIRTUAL_ATTRIBUTES } from "./../config.js";
import Multiselect from "vue-multiselect";
import { formatString } from "./../utils/helpers.js";
import "vue-search-select/dist/VueSearchSelect.css";
import { ModelSelect } from "vue-search-select";

export default {
  name: "GenericModal",

  components: {
    Multiselect,
    ModelSelect,
  },

  props: {
    fields: Array,
    withWideModal: {
      type: Boolean,
      default: false,
    },
  },

  // As Table.vue already references GenericModel, we need this little neat trick to avoid circular imports
  // https://stackoverflow.com/questions/55768033/circular-references-between-components-vue
  beforeCreate() {
    this.$options.components.Table = require("./../components/charts/Table.vue").default;
  },

  data() {
    return {
      store,
      record: undefined,
      editableFields: [],
      form: {},
      operation: "",
      primaryKeyValue: "",
      dict: {},
      validity: {},
      errorMsgs: {},
      title: "",
      hasAttemptedSubmit: false,
      generalError: false,
      generalErrorMsg: "",
      isReadOnly: false,
    };
  },

  methods: {
    //eslint-disable-next-line
    itemUpdated(newValue) {
      // Explanation of the magic below: vue reactivity is a bit weird when it comes to having dictionaries in record.
      // There, to notify all dependent conditions and components that a change has happened, we need to use the following
      // line. v-model does not do it by itself apparently when a change happens (even though it sets the value correctly, it
      // does not signal the dependent components), so we have to trigger the reactivity ourselves.
      this.form = Object.assign({}, this.form, this.form);
      this.dict = Object.assign({}, this.dict, this.dict);
    },

    getColClass(field) {
      if (typeof field.fullLine === "boolean" && field.fullLine == true) return "col-12 col-12-height";
      else if (
        field.textarea ||
        field.componentName ||
        field.isMultiSelectDropdown ||
        field.isTable ||
        field.richeditor
      )
        return "col-12";
      else if (field.explicitWidth) return field.explicitWidth;
      else if (typeof field.fullLine === "function" && field.fullLine() == true) return "col-12 col-12-height";
      else return "col-6";
    },

    getComponentName(field) {
      return "__component__" + field;
    },

    getButtonName() {
      return this.operation == "EDIT" ? "SAVE" : this.operation;
    },

    getFormKey(field) {
      if (field.virtualAttr) {
        const key = VIRTUAL_ATTRIBUTES[field.key]["targetAttrKey"];
        return key ? key : VIRTUAL_ATTRIBUTES[field.key]["sourceAttrValue"];
      } else return field.key;
    },

    resetModal() {
      this.form = {};
      this.record = undefined;
      this.primaryKeyValue = undefined;
      this.hasAttemptedSubmit = false;
      this.errorMsgs = {};
      // We need to call the close callback in Table.vue only for convert operations
      if (this.operation == "CONVERT" && this.hasAttemptedSubmit)
        this.closeCallback(formatString(this.name, true, false, true, true) + " converted successfully");
    },

    getPlaceholder(elem) {
      return this.record == undefined ||
        this.form == undefined ||
        this.record[elem] == undefined ||
        this.form[elem] == undefined ||
        this.form[elem] == ""
        ? ""
        : this.record[elem].toString();
    },

    getEndpoint() {
      return this.operation == "EDIT" ? this.apiEndpoint + "/" + this.form[this.primaryKeyValue] : this.apiEndpoint;
    },

    onSubmit(evt) {
      evt.preventDefault();
      this.hasAttemptedSubmit = true;
      if (!this.allFieldsValid()) return;

      // Reset error msgs before submitting
      this.errorMsgs = {};
      this.validity = Object.assign({}, this.validity, {});

      // Call for adding, editting, deletting records as well as for creating the new record of convert
      callEndpoint(
        this.getEndpoint(),
        /* success callback */
        () => {
          // If the operation was a convert, issue the put that will mark the old record as "qualified"
          if (this.operation == "CONVERT") {
            callEndpoint(
              this.origRecordModifyApiEndpoint + "/" + this.origRecord[this.primaryKeyValue],
              /* success callback */
              () => {
                /* Just close the modal */
                this.$refs["modal"].hide();
                this.$emit("rerenderAfterGenericModal");
              },
              {},
              "PUT",
              this.origRecord,
              /* error handler */
              (error) => {
                // eslint-disable-next-line
                console.log(error);
                this.handleError(error);
              }
            );
          } else {
            /* Was an add, so just close the modal */
            this.$refs["modal"].hide();
            this.$emit("rerenderAfterGenericModal");
          }
        },
        {},
        this.getHttpOperation(),
        this.form,
        (error) => this.handleError(error)
      );

      // We are not done. Issue update to all the components of the generic model as well, so that they can update themselves on our "close/done" button
      componentBus.$emit("component-update");
    },

    handleError(error) {
      //eslint-disable-next-line
      console.log(error.response);
      // if we get an 403 from the api, we tried to do an unauthorized request. Print this on the title
      if (error.response.status == 403) {
        this.generalError = true;
        this.generalErrorMsg = "UNAUTHORIZED";
        setTimeout(() => (this.generalError = false), 6000);
        return;
      }
      // else check if it is an internal error, and mark it as such on the title
      else if (error.response.status == 500) {
        this.generalError = true;
        this.generalErrorMsg = 'INTERNAL SERVER ERROR - PLEASE CONTACT <a href="mailto:support@innovum.co">SUPPORT</a>';
        setTimeout(() => (this.generalError = false), 6000);
        return;
      }

      const error_type = error.response.data["error_type"];
      const conflicting_attr = error.response.data["error_attr"];

      // Set the conflicting attr
      const dict = {};
      for (const ca of conflicting_attr) dict[ca] = false;
      this.validity = Object.assign({}, this.validity, dict);

      // Set the conflicting attr error msg
      if (error_type == "UNIQUE_KEY") {
        for (const ca of conflicting_attr) this.errorMsgs[ca] = "Value already exists. Please choose a unique value.";
      } else if (error_type == "MAXIMUM_STRING_LENGTH_EXCEEDED") {
        this.errorMsgs[conflicting_attr] =
          "Your value exceeds the maximum allowed string length of " +
          error.response.data["error_info"] +
          " characters for this attribute";
      } else if (error_type == "INT_OVERFLOW") {
        this.errorMsgs[conflicting_attr] =
          "Your value exceeds the maximum allowed numeric value of 2,147,483,647 for this attribute";
      } else if (error_type == "INVALID_EMAIL") {
        this.errorMsgs[conflicting_attr] = "Invalid email";
      }
    },

    getErrorMsg(field) {
      return this.errorMsgs[this.getFormKey(field)];
    },

    allFieldsValid() {
      for (const f of this.editableFields) {
        // Check that all date fields have been filled and have a valid date
        if (f.format && f.format == "date") {
          if (this.getRequired(f) === false) continue;
          else if (this.form[f.key] === undefined) return false;
          else if (f.validationFn !== undefined && f.validationFn(this.form) != "") return false;
        }
      }
      return true;
    },

    dateFieldValid(field) {
      // User has not attempted submit yet -> nothing to check
      if (!this.hasAttemptedSubmit) return null; // No error msg, no date field marked red

      if (this.getRequired(field) == false) return null;

      // For all cases below, user has attempted submit and we check if there is an error
      const key = this.getFormKey(field);
      // User didn't fill the field with a date
      if (this.form[key] === undefined || this.form[key].toString() == "") {
        this.errorMsgs[key] = "Please select a date";
        return false;
      }
      // Else something wrong happened during submission
      else if (this.getState(field) == false) return false;
      // If field has an additional validation function, call it and check that it didn't signal us that
      // there is an error. The semantics are that if the function returns an empty string, then there is no
      // error, otherwise the returned string is the error messgage
      else if (field.validationFn !== undefined) {
        const msg = field.validationFn(this.form);
        if (msg != "") {
          this.errorMsgs[key] = msg;
          return false;
        }
      }
      // All good. We return null and not true, as true would mark the field as green/ok, something we want to avoid
      else return null;
    },

    getState(field) {
      return this.validity[this.getFormKey(field)] == false ? false : null;
    },

    getRequired(field) {
      if (field.required === undefined) return true; // Require fields to be filled by default, unless otherwise specified
      return field.required === true;
    },

    getHttpOperation() {
      switch (this.operation) {
        case "ADD":
          return "POST";
        case "EDIT":
          return "PUT";
        case "CONVERT":
          return "POST";
      }
    },

    getModalTitle(operation, entity, otherEntity) {
      const title = formatString(entity);
      if (operation == "ADD") return "ADD NEW " + title;
      else if (operation == "EDIT") return "EDIT " + title;
      else if (operation == "SELECT") return "SELECT " + title;
      else if (operation == "VIEW") return "DETAILS OF " + title;
      else return "CONVERT " + title + " TO " + formatString(otherEntity);
    },

    getInvalidText(txt, field) {
      if (txt === undefined || txt == "") return "";
      // default error msg
      else return field.onInvalidText ? "setCustomValidity('" + field.onInvalidText + "')" : "";
    },

    copyFieldsToNewRec(targetRow, sourceRow, convertToInfo) {
      // Then copy over those specified
      if (convertToInfo.copyFields) {
        for (let [sourceKey, targetKey] of convertToInfo.copyFields) {
          if (VIRTUAL_ATTRIBUTES[sourceKey]) {
            const key = VIRTUAL_ATTRIBUTES[sourceKey]["targetAttrKey"];
            sourceKey = key ? key : VIRTUAL_ATTRIBUTES[sourceKey]["sourceAttrValue"];
          }
          if (VIRTUAL_ATTRIBUTES[targetKey]) {
            const key = VIRTUAL_ATTRIBUTES[targetKey]["targetAttrKey"];
            targetKey = key ? key : VIRTUAL_ATTRIBUTES[targetKey]["sourceAttrValue"];
          }
          targetRow[targetKey] = sourceRow[sourceKey];
        }
      }
    },

    initializeModal(data, apiEndpoint, operation, name, primaryKeyValue, convertToInfo, closeCallback) {
      this.operation = operation;
      this.apiEndpoint = operation != "CONVERT" ? apiEndpoint : convertToInfo.convertToApiEndpoint;
      this.editableFields = this.fields.filter((f) => f.onAdd || f.onSelect || f.onEdit);
      this.name = name;
      this.title = this.getModalTitle(this.operation, name, this.apiEndpoint);
      this.primaryKeyValue = primaryKeyValue;
      this.record = data !== undefined && operation != "CONVERT" ? { ...data } : {};
      this.closeCallback = closeCallback;
      // The following are used only by view modal
      this.isReadOnly = operation == "VIEW";

      // If operation is add/edit/view/select, then we can take the original record as is. Convert needs a bit of special
      // handling though, as we have two records in our hand: the "original" one (which we will be modifying), and the
      // "new" one we need to create by copying over the records.
      if (this.operation == "CONVERT") {
        this.origRecordModifyApiEndpoint = apiEndpoint;
        this.origRecord = { ...data };
        this.copyFieldsToNewRec(this.record, this.origRecord, convertToInfo);
        convertToInfo.updateCurrRecord(this.origRecord);
      }

      // Setup default values for fields
      if (this.record != undefined) {
        for (const [key, value] of Object.entries(this.record)) {
          this.form[key] = value;
        }
      }
      for (const field of this.editableFields) {
        this.validity[this.getFormKey(field)] = null;
      }

      // Initialize all fields for which we were given an endpoint
      this.fields
        .filter((f) => f.apiEndpoint)
        .forEach((f) => {
          const params = f.apiParams !== undefined ? { ...f.apiParams } : {};
          if (this.operation != "ADD") params[primaryKeyValue] = this.record[primaryKeyValue];
          callEndpoint(
            f.apiEndpoint,
            (record) => {
              this.dict[f.key] = record;
              this.form[f.key] = record.filter((e) => e.count > 0);
              this.itemUpdated(undefined);
            },
            params
          );
        });

      // We may have fields with constant values, hidden and non editable. Copy those over to the record dict
      this.fields
        .filter((f) => f.copyValue != undefined)
        .forEach((f) => {
          this.form[f.key] = f.copyValue;
        });

      // For those edittable fields that had explicit options passed, set them up
      this.editableFields
        .filter(
          (f) =>
            f.options !== undefined ||
            f.optionsAdd !== undefined ||
            f.optionsEdit !== undefined ||
            f.optionsView !== undefined
        )
        .forEach((f) => {
          if (this.operation == "ADD" && f.optionsAdd) this.handleOptions(f, f.optionsAdd);
          else if (this.operation == "EDIT" && f.optionsEdit) this.handleOptions(f, f.optionsEdit);
          else if (this.operation == "VIEW" && f.optionsView) this.handleOptions(f, f.optionsView);
          else this.handleOptions(f, f.options);
        });

      for (const field of this.editableFields) {
        if (field.isDropdown || field.options) {
          const key = this.getFormKey(field);
          // If a default value is given, set this value as preselected, otherwise set it from the first value in the data
          if (field.default && (this.form[key] === undefined || this.form[key] === "")) {
            this.form[key] = field.default;
          } else if (field.virtualAttr) {
            if (this.record[key] == undefined || this.record[key].toString() == "") {
              if (this.dict[field.key] && this.dict[field.key].length > 0)
                this.form[key] = this.dict[field.key][0].value;
            } else this.form[key] = this.record[key];
          } else {
            if (this.record[key] == undefined || this.record[key].toString() == "") {
              if (this.dict[field.key] && this.dict[field.key].length > 0) this.form[key] = this.dict[field.key][0];
            } else this.form[key] = this.record[key];
          }
        }
      }
    },

    handleOptions(f, options) {
      if (options instanceof Function) this.dict[f.key] = options();
      else this.dict[f.key] = options;
      this.itemUpdated();
    },

    getVirtualAttrText(rec, va, e) {
      if (va["sourceAttrText"] instanceof Function) return va["sourceAttrText"](rec);
      return rec[va["sourceAttrText"] ? va["sourceAttrText"] : e.key];
    },
  },

  computed: {
    viewableFields() {
      return this.editableFields.filter((f) => (f.conditionalRendering ? f.conditionalRendering(this.form) : true));
    },
    getModalSize() {
      return this.withWideModal ? "wide" : "lg";
    },
  },

  // Initializes the virtual attributes needed by this modal
  created() {
    this.$props.fields
      // Filter only the fields that are virtual attributes
      .filter((e) => VIRTUAL_ATTRIBUTES[e.key] && e.isDropdown)
      // Foreach one, call the endpoint and set the values
      .forEach((e) => {
        const va = VIRTUAL_ATTRIBUTES[e.key];
        callEndpoint(
          va["endpoint"],
          (records) => {
            // if the return type of the endpoint is a list of strings (e.g project types, service types etc), store the list as is
            if (typeof records[0] === "string") {
              this.dict[e.key] = records;
              // Else, we get back a list of dictionaries, and we map it according to the configuration
            } else {
              this.dict[e.key] = records.map((rec) => {
                return {
                  value: rec[va["sourceAttrValue"]], // key to store internal
                  text: this.getVirtualAttrText(rec, va, e), // value to display instead
                };
              });
            }
            // Update the main modal record dict with the new values
            this.itemUpdated();
          },
          Object.assign({}, va["apiParams"], e["extraApiParams"]) // Endpoint parameters
        );
      });
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.generic-modal-props {
  margin-left: 40px;
  margin-right: 40px;
  border-spacing: 50em;
}

.generic-modal-header-class {
  border-bottom: 0px solid white !important;
}

/* https://vue-loader.vuejs.org/guide/scoped-css.html#child-component-root-elements */
/deep/ .b-form-btn-label-control > .btn {
  float: left !important;
  margin-top: 2px;
  color: #17a2b8;
}

.form-control,
.d-block {
  display: block !important;
  width: 100% !important;
}

.generic-modal-title-class {
  margin-left: 35px;
}

.col-6 {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0px;
  height: 110px;
}

.col-12 {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* We cannot merge this with col-12, as textarea (also always a col-12) is more than 110px */
.col-12-height {
  height: 110px;
}

/deep/ .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/ .modal-wide {
  min-width: 80% !important;
}
</style>
