import Vue from "vue";
import Router from "vue-router";
import DashboardGeneral from "./views/dashboards/DashboardGeneral.vue";
import DashboardFinancials from "./views/dashboards/DashboardFinancials.vue";
import LoginScreen from "./views/auth/LoginScreen.vue";
import Employees from "./views/internal-resources/Employees.vue";
import MCQSC from "./views/internal-resources/MCQSC.vue";
import Scanners from "./views/internal-resources/Scanners.vue";
import Projects from "./views/Projects.vue";
import Services from "./views/sales/Services.vue";
import Leads from "./views/sales/Leads.vue";
import Products from "./views/sales/Products.vue";
import Opportunities from "./views/sales/Opportunities.vue";
import OpportunitiesInfo from "./views/sales/OpportunitiesInfo.vue";
import ProjectTypes from "./views/sales/ProjectTypes.vue";
import store from "./views/auth/store.js";
import Unauthorized from "./views/auth/Unauthorized.vue";
import Profile from "./views/Profile.vue";
import ProjectInfo from "./views/ProjectInfo.vue";
import Users from "./views/Users.vue";
import UsersInfo from "./views/internal-resources/UsersInfo.vue";
import Ships from "./views/Ships.vue";
import ShipsInfo from "./views/ShipsInfo.vue";
import Accounts from "./views/Accounts.vue";
import Ports from "./views/Ports.vue";
import BWMSCube from "./views/BWMSCube.vue";
import BWMSFinder from "./views/BWMSFinder.vue";

// Systems views
import Filters from "./views/systems/Filters.vue";
import Manufacturers from "./views/systems/Manufacturers.vue";
import Systems from "./views/systems/Systems.vue";
import SystemsInfo from "./views/systems/SystemsInfo.vue";
import Technologies from "./views/systems/Technologies.vue";
import TechnologiesGroup from "./views/systems/TechnologiesGroup.vue";
import ClassSocieties from "./views/systems/ClassSocieties.vue";
import Components from "./views/systems/Components.vue";
import ComponentTypes from "./views/systems/ComponentTypes.vue";

// Payments views
import Success from "./views/SuccessPage.vue";

import {
  INTERNAL_RESOURCE_USERS,
  IR_AND_MANUFACTURERS,
  IR_AND_BALLASTADMINS,
  IR_BALLASTADMIN_MANUFACTURERS,
  getUserRole,
  isSubcriptionProOrUltimate,
  isUserInternalResource,
  isIRorBallastAdmin,
} from "./utils/helpers.js";
Vue.use(Router);

const authenticate = (route) => {
  const role = getUserRole();
  // If no scope or permission was defined for this router entry, assume it is "grant-all"
  if (route.meta == undefined || route.meta.permission === undefined) return true;
  // meta field defined, and user has the right role to access it
  else if (typeof route.meta.permission == "string" && route.meta.permission == role) return true;
  else if (route.meta.permission instanceof Array && route.meta.permission.indexOf(role) >= 0) return true;
  else if (route.meta.permission instanceof Function && route.meta.permission()) return true;
  // permission field defined, but user does not have the right role to access it
  else return false;
};

// Navigation guard which checks, on every route change from '_from' to 'to' that the user has the right permissions to access the new page
const navigationGuard = (to, _from, next) => {
  // User has authenticated to the UI successfully
  if (sessionStorage.getItem("user-token") != null) {
    if (authenticate(to)) next();
    else next("/403"); // User does not have the right permissions. Show him the 403 page
  }
  // Authentication to the UI failed (wrong username or)
  else if (store.state.status != "success") next("/");
  // Default routing
  else next();
};

export default new Router({
  mode: "history",

  authenticate: authenticate,

  routes: [
    {
      path: "/",
      name: "login",
      component: LoginScreen,
    },
    {
      path: "/403",
      name: "unauthorized",
      component: Unauthorized,
    },
    {
      path: "/dashboard/general",
      name: "Dashboards - General",
      component: DashboardGeneral,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fas fa-fw fa-home",
        folder: "Dashboards",
        txt: "General",
      },
      meta: {
        permission: IR_AND_MANUFACTURERS,
      },
    },
    {
      path: "/dashboard/financials",
      name: "Dashboards - Financials",
      component: DashboardFinancials,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fas fa-fw fa-home",
        folder: "Dashboards",
        txt: "Financials",
      },
      meta: {
        permission: "Administrator",
      },
    },

    /**************************************************
     * Internal Resources (Accessible only by admin)
     **************************************************/
    {
      path: "/internal-resources/employees",
      name: "Employees",
      component: Employees,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fas fa-fw fa-sitemap",
        folder: "Internal Resources",
        submenuTitle: "Resources",
      },
      meta: {
        permission: "Administrator",
      },
    },
    {
      path: "/users/:id",
      name: "User Information",
      component: UsersInfo,
      beforeEnter: navigationGuard,
      meta: {
        permission: "Administrator",
      },
    },
    {
      path: "/internal-resources/mcqsc",
      name: "MCQSC",
      component: MCQSC,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fas fa-fw fa-sitemap",
        folder: "Internal Resources",
        submenuTitle: "Resources",
      },
      meta: {
        permission: "Administrator",
      },
    },
    {
      path: "/internal-resources/scanners",
      name: "Scanners",
      component: Scanners,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fas fa-fw fa-sitemap",
        folder: "Internal Resources",
        submenuTitle: "Resources",
      },
      meta: {
        permission: "Administrator",
      },
    },

    /*****************************************************************************
     *  Sales: Accssible only by users with Administrator role *
     *****************************************************************************/
    {
      path: "/leads",
      name: "Leads",
      component: Leads,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fa fa-fw fa-briefcase",
        folder: "Sales",
      },
      meta: {
        permission: "Administrator",
      },
    },
    {
      path: "/opportunities",
      name: "Opportunities",
      component: Opportunities,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fas fa-fw fa-briefcase",
        folder: "Sales",
      },
      meta: {
        permission: "Administrator",
      },
    },
    {
      path: "/opportunities/:id",
      name: "Opportunity Information",
      component: OpportunitiesInfo,
      beforeEnter: navigationGuard,
      meta: {
        permission: "Administrator",
      },
    },
    {
      path: "/services",
      name: "Services",
      component: Services,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fa fa-fw fa-briefcase",
        folder: "Sales",
      },
      meta: {
        permission: "Administrator",
      },
    },
    {
      path: "/project-types",
      name: "Project Types",
      component: ProjectTypes,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fa fa-fw fa-list-alt",
        folder: "Sales",
      },
      meta: {
        permission: "Administrator",
      },
    },
    {
      path: "/products",
      name: "Products",
      component: Products,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fa fa-fw fa-briefcase",
        folder: "Sales",
      },
      meta: {
        permission: "Administrator",
      },
    },

    /*****************************************************************************
     *  Projects                                                                 *
     *****************************************************************************/
    {
      path: "/projects",
      name: "Projects",
      component: Projects,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fas fa-fw fa-tasks",
        folder: "Projects",
        txt: "Overview",
      },
      meta: {
        permission: INTERNAL_RESOURCE_USERS,
      },
    },
    {
      path: "/projects/:id",
      name: "Project Information",
      component: ProjectInfo,
      beforeEnter: navigationGuard,
      meta: {
        permission: INTERNAL_RESOURCE_USERS,
      },
    },

    /*************
     *  Systems  *
     *************/
    {
      path: "/systems/technologies-group",
      name: "Technologies Group",
      component: TechnologiesGroup,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fas fa-fw fa-cogs",
        folder: "Ballast Systems",
        txt: "Technologies Group",
      },
      meta: {
        permission: IR_BALLASTADMIN_MANUFACTURERS,
      },
    },
    {
      path: "/systems/technologies",
      name: "Technologies",
      component: Technologies,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fas fa-fw fa-cogs",
        folder: "Ballast Systems",
        txt: "Technologies",
      },
      meta: {
        permission: IR_BALLASTADMIN_MANUFACTURERS,
      },
    },
    {
      path: "/systems/manufacturers",
      name: "Manufacturers",
      component: Manufacturers,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fas fa-fw fa-cogs",
        folder: "Ballast Systems",
        txt: "Manufacturers",
      },
      meta: {
        permission: IR_AND_BALLASTADMINS,
      },
    },
    {
      path: "/systems/component-types",
      name: "Components Type",
      component: ComponentTypes,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fas fa-fw fa-cogs",
        folder: "Ballast Systems",
        txt: "Components Type",
      },
      meta: {
        permission: IR_BALLASTADMIN_MANUFACTURERS,
      },
    },
    {
      path: "/systems/components",
      name: "Components",
      component: Components,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fas fa-fw fa-cogs",
        folder: "Ballast Systems",
        txt: "Components",
      },
      meta: {
        permission: IR_BALLASTADMIN_MANUFACTURERS,
      },
    },
    {
      path: "/systems/class-type-approvals",
      name: "Class Societies",
      component: ClassSocieties,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fas fa-fw fa-cogs",
        folder: "Ballast Systems",
        txt: "Class Societies",
      },
      meta: {
        permission: IR_BALLASTADMIN_MANUFACTURERS,
      },
    },
    {
      path: "/systems/filters",
      name: "Filters",
      component: Filters,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fas fa-fw fa-cogs",
        folder: "Ballast Systems",
        txt: "Filters",
      },
      meta: {
        permission: IR_BALLASTADMIN_MANUFACTURERS,
      },
    },
    {
      path: "/systems/systems",
      name: "Ballast Systems",
      component: Systems,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fas fa-fw fa-cogs",
        folder: "Ballast Systems",
        txt: "Systems",
      },
      meta: {
        permission: IR_BALLASTADMIN_MANUFACTURERS,
      },
    },
    {
      path: "/systems/:id",
      name: "Systems Information",
      component: SystemsInfo,
      beforeEnter: navigationGuard,
      meta: {
        permission: IR_BALLASTADMIN_MANUFACTURERS,
      },
    },
    /********************
     *  End of Systems  *
     ********************/
    {
      path: "/accounts",
      name: "Accounts",
      component: Accounts,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fa fa-fw fa-address-book",
      },
      meta: {
        permission: "Administrator",
      },
    },

    /******************
     *  Ships & ports *
     ******************/
    {
      path: "/ships",
      name: "Ships",
      component: Ships,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fa fa-fw fa-ship",
      },
      // Ships are open to everyone, as even the default FREE subscription includes access to them
    },
    {
      path: "/ships/:id",
      name: "Ships Information",
      component: ShipsInfo,
      beforeEnter: navigationGuard,
      meta: {
        permission: () => isIRorBallastAdmin() || isSubcriptionProOrUltimate(),
      },
    },
    {
      path: "/ports",
      name: "Ports",
      component: Ports,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fa fa-fw fa-anchor",
      },
      meta: {
        permission: () => isUserInternalResource() || isSubcriptionProOrUltimate(),
      },
    },

    /********************
     *  Finder and Cube *
     ********************/
    {
      path: "/bwmscube",
      name: "BWMS Cube",
      component: BWMSCube,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fa fa-fw fa-cube",
      },
      // BWMS Cude is open to everyone, as subscription is by default FREE
    },
    {
      path: "/bwmsfinder",
      name: "BWMS Finder",
      component: BWMSFinder,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fas fa-fw fa-search",
      },
      meta: {
        permission: () => isIRorBallastAdmin() || isSubcriptionProOrUltimate(),
      },
    },

    {
      path: "/users",
      name: "Users",
      component: Users,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fas fa-fw fa-users",
      },
      meta: {
        permission: "Administrator",
      },
    },
    {
      path: "/profile",
      name: "My Profile",
      component: Profile,
      beforeEnter: navigationGuard,
      inMenu: {
        icon: "fas fa-fw fa-user",
      },
    },
    {
      path: "/success",
      name: "Success",
      component: Success,
      beforeEnter: navigationGuard,
    },
  ],
});
