<template>
  <BaseView>
    <div>
      <div style="font-weight:bold">Projects</div>
      <br />
      <Table
        apiEndpoint="projects"
        :apiParams="{ managerUsername: this.$route.params.id }"
        :fields="this.projectFields"
         v-bind:addButtonEnabled="() => false" 
        v-bind:editButtonEnabled="() => false"
        v-bind:deleteButtonEnabled="() => false"
        v-bind:detailsButtonEnabled="() => true"
        @detailsClicked="navigateToProjectPage(...arguments)"
      >
      </Table>

      <br />
      <br />
      <div style="font-weight:bold">MCQSC</div>
      <br />
      <Table
        apiEndpoint="mcqsc-users"
        :apiParams="{ username: this.$route.params.id, filter_matching: true }"
        :fields="[
          { key: 'mcqsc_name', label: 'MCQSC' },
          { key: 'date_created', label: 'Date Assigned' }
        ]"
        v-bind:addButtonEnabled="() => false" 
        v-bind:editButtonEnabled="() => false"
        v-bind:deleteButtonEnabled="() => false"
        :rowClickedHandler="displayMCQSCInfoModal"
      >
      </Table>
      <!--
      <br />
      <br />
      <ChartContainer label="Work Schedule and Statistics">
        <br />
        <Table
          id="user-hours"
          apiEndpoint="user-work-hours"
          :fields="[
            { key: 'day', label: 'Day' },
            { key: 'hours_occupied', label: 'Number of Hours' }
          ]"
          :apiParams="this.getApiParams()"
        />
        <br />
        <div class="row">
          <div class="col" style="font-weight: bold">Sick leave Days</div>
          <div class="col">{{ this.statistics.sickleavedays }}</div>
          <div class="col" style="font-weight: bold">Vacation Days</div>
          <div class="col">{{ this.statistics.vacationdays }}</div>
        </div>
        <div class="row">
          <div class="col" style="font-weight: bold">Remaining days off</div>
          <div class="col">{{ this.statistics.remaininleavedays }}</div>
          <div class="col" style="font-weight: bold">Overtime Hours</div>
          <div class="col">{{ this.statistics.overtimehours }}</div>
        </div>
        <br />
        <br />
        <TimelineChart :items="this.statistics.dayranges" v-if="this.statistics.dayranges.length > 0" />
      </ChartContainer> -->
    </div>
  </BaseView>
</template>

<script>
import Table from "@/components/charts/Table.vue";
//import TimelineChart from "@/components/charts/TimelineChart.vue";
//import ChartContainer from "@/components/containers/ChartContainer.vue";
//import { callEndpoint } from "@/utils/apicall.js";
import { convertDateToAPIFormat } from "@/utils/helpers.js";
import { currencyFormatter } from "@/components/style_formatters/currencyFormatter.js";
import BaseView from "@/views/BaseView.vue";
import { TABLE_SCHEMAS } from "@/config.js";

export default {
  name: "UsersInfo",

  components: {
    Table,
    //TimelineChart,
    //ChartContainer,
    BaseView
  },

  data() {
    return {
      // statistics: {
      //   sickleavesum: "",
      //   vacationsum: "",
      //   remaining: "",
      //   dayranges: []
      // },
      projectFields: TABLE_SCHEMAS["Projects"],
      row: ""
    };
  },

  methods: {
    currencyFormatter,
    convertDateToAPIFormat,
    getApiParams() {
      return { username: this.$route.params.id };
    },
    // convertHoursToDays(hours) {
    //   return Math.round((hours / 8) * 2) / 2;
    // },
    resetModal() {
      // this.statistics = {
      //   sickleavesum: "",
      //   vacationsum: "",
      //   remaining: "",
      //   dayranges: []
      // };
      this.row = "";
    },
    navigateToProjectPage(data) {
      this.$router.push("/projects/" + data.item.project_id);
    },
    displayMCQSCInfoModal(row) {
      this.$root.$emit("bv::show::modal", "MCQSCInfoModal", row);
    }
  },

  created() {
    // callEndpoint(
    //   "user-hours-statistics",
    //   data => {
    //     const isEmptyDict = data.length == 0;
    //     const userData = data[0];
    //     this.statistics.sickleavedays = isEmptyDict ? 0 : this.convertHoursToDays(userData["sum"]["Sickleave"]);
    //     this.statistics.vacationdays = isEmptyDict ? 0 : this.convertHoursToDays(userData["sum"]["Vacation"]);
    //     this.statistics.remaininleavedays = isEmptyDict ? 20 : userData["sum"]["remainingLeaveDays"];
    //     this.statistics.overtimehours = isEmptyDict ? 0 : userData["sum"]["overtimeHours"];
    //     this.statistics.dayranges = isEmptyDict ? undefined : userData["dayRanges"];
    //   },
    //   this.getApiParams()
    // );
  }
};
</script>
