<template>
  <b-modal
    :id="this.$options.name"
    :title="this.title"
    hide-header-close
    body-class="confirm-modal-body-class"
    header-class="confirm-modal-header-class"
    footer-class="confirm-modal-footer-class"
    modal-class="confirm-modal-modal-class"
    title-class="confirm-modal-title-class"
    @ok="onOk()"
  >
  </b-modal>
</template>

<script>
export default {
  name: "ConfirmationModal",

  data() {
    return {
      title: ""
    };
  },

  methods: {
    onOk() {
      this.callback(this.callbackParam);
    }
  },

  mounted() {
    this.$root.$on("bv::show::modal", (modalName, modalTitle, callback, callbackParam) => {
      if (this.$options && modalName == this.$options.name) {
        // You may ask yourself, why don't we just put the '?' on the parameter directly. Well, if we do, vue gives the error
        // "SyntaxError: Failed to execute 'querySelector' on 'Document': 'Are you sure you want to delete this entry?' is not a valid selector."
        // Removing it from the param and adding it here seems to do the trick
        this.title = modalTitle + "?";
        this.callback = callback;
        this.callbackParam = callbackParam;
      }
    });
  }
};
</script>

<style>
.confirm-modal-body-class {
  display: none;
  padding: 0px;
}
.confirm-modal-header-class {
  border: 0px;
  width: 100%;
  text-align: center;
  display: block;
  color: #593b8a;
}
.confirm-modal-modal-class > div {
  width: 700px;
  max-width: 700px;
  margin-top: 100px;
}
.confirm-modal-title-class {
  font-size: 24px;
  text-align: center;
  margin: 0 auto;
  width: 350px;
  max-width: 350px;
}
.confirm-modal-footer-class {
  justify-content: center;
}
</style>
