export function currencyFormatter(column_key, currency_column = 'currency') {
    return (value, key, item) => {
        try {
            return item[column_key].toLocaleString('en-US', {
                style: 'currency',
                currency: item[currency_column],
                maximumFractionDigits: 0,
                minimumFractionDigits: 0
            })
        } catch {
            return item[column_key]
        }
    }
}