<template>
  <BaseView backgroundColor="white">
    <b-container fluid>
      <b-row class="mb-4">
        <b-col>
          <DropdownMenu
            allOptionsText="ALL REGIONS"
            :items="this.store.state.regions"
            @dropdownSelected="region = $event"
          />
        </b-col>
        <b-col>
          <DropdownMenu
            allOptionsText="ALL TIME"
            :items="['THIS YEAR', 'THIS MONTH', 'THIS WEEK']"
            defaultOption="THIS MONTH"
            @dropdownSelected="timePeriod = $event"
          />
        </b-col>
        <b-col v-if="isAdminUser()">
          <DropdownMenu
            allOptionsText="ALL MANAGERS"
            apiEndpoint="users/fullnames"
            :fieldExtractor="(user) => user['full_name']"
            @dropdownSelected="managerName = $event"
            :apiParams="{ role: ['Administrator', 'Project Manager'] }"
          />
        </b-col>
      </b-row>

      <b-row class="mb-4" style="height: 320px">
        <div class="col-3 col-sm-2" />
        <div class="col-6 col-sm-8">
          <div style="height: 200px; min-height: 200px; max-width: 420px; margin: 0 auto">
            <ChartContainer label="INVOICES BY STATUS">
              <br />
              <PieChart
                apiEndpoint="invoices/count/type"
                :apiParams="{ region: this.region, managerName: this.managerName, startDate: this.getStartDate() }"
                :key="this.dropdownChangedKey"
              />
            </ChartContainer>
          </div>
        </div>
        <div class="col-3 col-sm-2" />
      </b-row>

      <b-row class="row-border mb-3">
        <div class="col-12">
          <ChartContainer label="OPEN PROJECTS">
            <Table
              apiEndpoint="projects"
              :fields="[
                { key: 'project_title', label: 'Project Title' },
                { key: 'name', label: 'Account' },
                { key: 'project_type', label: 'Project Type' },
                { key: 'validity_date', label: 'Due Date' },
                { key: 'issued_amount', label: 'Total Amount', formatter: currencyFormatter('issued_amount') },
                { key: 'invoiced_amount', label: 'Invoiced Amount', formatter: currencyFormatter('invoiced_amount') },
                { key: 'unbilled_amount', label: 'Unbilled Amount', formatter: currencyFormatter('unbilled_amount') },
                { key: 'num_hours_registered', label: '#Hours Registered' },
                {
                  key: 'salaries_and_expenses',
                  label: 'Salaries & Expenses',
                  formatter: currencyFormatter('salaries_and_expenses'),
                },
                {
                  key: 'subcontractor_cost',
                  label: 'Subcontractor Cost',
                  formatter: currencyFormatter('subcontractor_cost'),
                },
                { key: 'balance', label: 'Balance', formatter: currencyFormatter('balance') },
              ]"
              :apiParams="{
                region: this.region,
                managerName: this.managerName,
                startDate: this.getStartDate(),
                projectStatus: ['In Progress', 'Pending'],
              }"
              v-bind:addButtonEnabled="() => false"
              v-bind:editButtonEnabled="() => false"
              v-bind:deleteButtonEnabled="() => false"
              disableTableBorder
            />
          </ChartContainer>
        </div>
        <!-- Row: employee information -->
        <div class="col-12" style="padding-top: 50px">
          <br />
          <ChartContainer label="EMPLOYEE OVERVIEW (CURRENT MONTH)">
            <Table
              apiEndpoint="user-hours-statistics"
              :fields="[
                { key: 'username', label: 'Username' },
                { key: hoursLoggedName, label: hoursLoggedName },
                { key: overtimeLoggedName, label: overtimeLoggedName },
                { key: 'Projects', label: 'Projects And Services' },
              ]"
              :templates="[hoursLoggedName, overtimeLoggedName, 'Projects']"
              :apiParams="{
                userrole: ['Administrator', 'Project Manager'],
              }"
              v-bind:addButtonEnabled="() => false"
              v-bind:editButtonEnabled="() => false"
              v-bind:deleteButtonEnabled="() => false"
              disableTableBorder
            >
              <template v-slot:[hoursLoggedName]="{ data }">
                {{ data.item.sum["Work"] }} / {{ numWorkingDaysInPeriod() }}
              </template>
              <template v-slot:[overtimeLoggedName]="{ data }">
                {{ data.item.sum["overtimeHours"] }}
              </template>
              <template v-slot:Projects="{ data }">
                <ol style="padding-inline-start: 13px">
                  <li v-for="(item, index) in data.item['ProjectsAndServices']" :key="index">
                    {{ item }}
                  </li>
                </ol>
              </template>
            </Table>
          </ChartContainer>
        </div>
      </b-row>
    </b-container>
  </BaseView>
</template>

<script>
/* @ is a shortcut for ./src */
import BaseView from "@/views/BaseView.vue";
import PieChart from "@/components/charts/PieChart.vue";
import DropdownMenu from "@/components/containers/DropdownMenu.vue";
import ChartContainer from "@/components/containers/ChartContainer.vue";
import { convertDateToAPIFormat, isAdminUser } from "@/utils/helpers.js";
import Table from "@/components/charts/Table.vue";
import { currencyFormatter } from "@/components/style_formatters/currencyFormatter.js";
import store from "@/views/auth/store.js";

export default {
  name: "DashboardFinancials",

  components: {
    BaseView,
    PieChart,
    DropdownMenu,
    ChartContainer,
    Table,
  },

  data() {
    return {
      store,
      region: "",
      managerName: "",
      timePeriod: "ALL TIME",
      hoursLoggedName: "Hours Logged",
      overtimeLoggedName: "Overtime Logged",
    };
  },

  methods: {
    currencyFormatter,
    isAdminUser,

    getStartDate() {
      const date = new Date();
      switch (this.timePeriod) {
        case "THIS WEEK":
          date.setDate(date.getDate() - 7);
          return convertDateToAPIFormat(date);
        case "THIS MONTH":
          date.setMonth(date.getMonth() - 1);
          return convertDateToAPIFormat(date);
        case "THIS YEAR":
          date.setFullYear(date.getFullYear() - 1);
          return convertDateToAPIFormat(date);
        default:
          return ""; // No filtering
      }
    },
    getFirstDayCurrentMonth() {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      return convertDateToAPIFormat(firstDay);
    },
    getLasttDayCurrentMonth() {
      var date = new Date();
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      return convertDateToAPIFormat(lastDay);
    },
    numWorkingDaysInPeriod() {
      var endate = new Date();
      var stdate = new Date(endate.getFullYear(), endate.getMonth(), 1);
      for (var i = 0; stdate < endate; ) {
        // The getDay() method returns the day of the week (from 0 to 6) for the specified date.
        // Note: Sunday is 0, Monday is 1, and so on.
        if (stdate.getDay() > 0 && stdate.getDay() < 6) i++;
        stdate.setDate(stdate.getDate() + 1);
      }
      return i * 8;
    },
  },

  computed: {
    dropdownChangedKey: function () {
      return this.region + "/" + this.managerName + "/" + this.timePeriod;
    },
  },
};
</script>
