<template>
  <BaseView searchEnabled :searchConfig="searchConfig">
    <Table
      :key="rerenderTableKey"
      apiEndpoint="systems"
      :apiParams="{ is_approved: true }"
      stickyHeaderHeight="1125px"
      :fields="systemsSchema"
      v-bind:addButtonEnabled="() => false"
      v-bind:editButtonEnabled="() => false"
      v-bind:deleteButtonEnabled="() => false"
      v-bind:detailsButtonEnabled="() => false"
      v-bind:viewButtonEnabled="() => true"
      @detailsClicked="navigateToSystemsPage(...arguments)"
      :booleanColumns="['active_substrances', 'ams_designation', 'explosion_proofing']"
      @rerenderAfterGenericModal="rerenderTableKey = !rerenderTableKey"
    ></Table>
  </BaseView>
</template>

<script>
/* @ is a shortcut for ./src */
import BaseView from "./BaseView.vue";
import Table from "@/components/charts/Table.vue";
import { TABLE_SCHEMAS } from "./../config.js";
import store from "@/views/auth/store.js";
import { isSubcriptionProOrUltimate, isIRorBallastAdmin } from "./../utils/helpers.js";

export default {
  name: "BWMSCube",
  components: {
    BaseView,
    Table,
  },

  data() {
    return {
      systemsSchema: TABLE_SCHEMAS["Systems"],
      rerenderTableKey: true,
      searchCriteria: {},
      countries: store.state.countries,
    };
  },

  methods: {
    isSubcriptionProOrUltimate,
    isIRorBallastAdmin,
    navigateToSystemsPage(row) {
      this.$router.push("/systems/" + row.item["system_id"]);
    },
  },

  computed: {
    searchConfig() {
      const standard_filters = {
        "Standard Filters": [
          { key: "system_title", label: "Title" },
          { key: "manufacturer_name", label: "Manufacturer" },
          { key: "technology_name", label: "Technology" },
          { key: "active_substrances", label: "Active Substrances", type: "checkbox" },
          { key: "ams_designation", label: "AMS Designation", type: "checkbox" },
          {
            key: "imo_approval_country",
            label: "IMO Type Approval",
            type: "dropdown",
            options: store.state.countries,
          },
          {
            key: "imo_approval_status",
            label: "",
            type: "dropdown",
            options: ["", "Approved", "Ongoing", "Final", "Basic"],
          },
          {
            key: "stage_uscg_approval",
            label: "Stage of USCG Approval",
            type: "dropdown",
            options: ["", "Ongoing", "Approved"],
          },
        ],
      };
      const premium_filters = {
        "Premium Filters": [
          { key: "explosion_proofing", label: "Explosion Proofing", type: "checkbox" },
          {
            key: "class_type_approval",
            label: "Class Type Approval",
            type: "dropdown",
            options: ["", "Approved", "Ongoing", "Final", "Basic"],
          },
          { key: "min_tss", label: "Min TSS", type: "slider", initValue: 0, min: 0, max: 100 },
          { key: "min_uv_t_imo", label: "Min UV-T IMO", type: "slider", initValue: 0, min: 0, max: 100 },
          { key: "min_uv_t_uscg", label: "Min UV-T UCSG", type: "slider", initValue: 0, min: 0, max: 100 },
          { key: "min_salinity", label: "Min Salinity", type: "slider", initValue: 0, min: 0, max: 100 },
          { key: "max_temp", label: "Max Temperature", type: "slider", initValue: 100, min: 0, max: 100 },
          {
            key: "requires_stored_onboard_chemicals",
            label: "Required Chemicals",
            type: "dropdown",
            options: ["", "Neutralising Agent", "Cleaning Agent", "Stored Biocide"],
          },
        ],
      };
      if (this.isSubcriptionProOrUltimate()) {
        return Object.assign({}, standard_filters, premium_filters);
      } else return standard_filters;
    },
  },
};
</script>
