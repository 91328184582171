<template>
  <BaseView>
    <h1>Thanks for your order!</h1>
    <p>
      We appreciate your business! If you have any questions, please email
      <a href="mailto:info@bwm.no">info@bwm.no</a>.
    </p>
  </BaseView>
</template>

<script>
/* @ is a shortcut for ./src */
import BaseView from "./BaseView.vue";

export default {
  name: "Success",
  components: {
    BaseView,
  },
};
</script>
