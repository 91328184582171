<template>
  <Table
    :apiEndpoint="this.apiEndpoint"
    :apiParams="this.apiParamsFn"
    :fields="this.fields"
    :templates="['count']"
    ref="base_table"
    searchingEnabled
    :paginationRowsPerPage="this.paginationRowsPerPage"
    :stickyHeaderHeight="this.stickyHeaderHeight"
    :dropdownColumns="['approval_status']"
    dropdownsEnabled
    :sortTableByColumn="sortTableByColumnField"
    v-bind:addButtonEnabled="() => false"
    v-bind:editButtonEnabled="() => false"
    v-bind:deleteButtonEnabled="() => false"
    v-bind:viewButtonEnabled="() => false"
    disableTableBorder
  >
    <template v-slot:count="{ data }">
      <b-form-group>
        <b-form-checkbox :checked="data.item.count" :ref="data.item[tablePrimaryKey]"></b-form-checkbox>
      </b-form-group>
    </template>
  </Table>
</template>

<script>
import Table from "@/components/charts/Table.vue";
import componentBus from "../../main";
import { callEndpoint } from "./../../utils/apicall.js";

export default {
  name: "TableWithCheckbox",

  props: {
    apiEndpoint: {
      type: String,
    },
    apiParamsFn: {
      type: Object,
    },
    fields: {
      type: Array,
    },
    tablePrimaryKey: {
      type: String,
    },
    operation: {
      type: String,
    },
    paginationRowsPerPage: {
      type: Number,
      default: 5,
    },
    sortTableByColumnField: {
      type: String,
      default: undefined,
    },
    stickyHeaderHeight: {
      type: String,
      default: "360px",
    },
  },

  components: {
    Table,
  },

  methods: {
    triggerUpdate() {
      if (this.$refs["base_table"] !== undefined) {
        const pk = this.tablePrimaryKey;
        const tableData = this.$refs["base_table"].$data.items;
        const tableDict = Object.assign({}, ...tableData.map((e) => ({ [e[pk]]: e })));

        const selectedItemsToAssign = Object.entries(this.$refs)
          .filter(([, v]) => v !== undefined && v.isChecked)
          .filter((cb) => tableDict[cb[0]]["count"] == 0)
          .map((cb) => tableDict[cb[0]]);

        const selectedItemsToDeassign = Object.entries(this.$refs)
          .filter(([k, v]) => k != "base_table" && v !== undefined && !v.isChecked)
          .filter((cb) => tableDict[cb[0]]["count"] != 0)
          .map((cb) => cb[0]);

        // Prepare API params (mostly the API key)
        const apiParams = this.apiParamsFn;

        // Post of new records
        if (selectedItemsToAssign.length > 0) {
          callEndpoint(this.apiEndpoint, () => {}, apiParams, "POST", selectedItemsToAssign);
        }

        // Issue deletion of unselected records
        if (this.operation == "EDIT" || this.operation == "SELECT") {
          if (selectedItemsToDeassign.length > 0) {
            apiParams["values"] = selectedItemsToDeassign;
            callEndpoint(this.apiEndpoint, () => {}, apiParams, "DELETE");
          }
        }
      }
    },
  },

  mounted() {
    componentBus.$on("component-update", this.triggerUpdate);
  },
};
</script>

<style scoped>
/deep/ .form-group {
  margin-bottom: 0px;
}
</style>
