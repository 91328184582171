<template>
  <BaseView>
    <stripe-checkout ref="checkoutRef" mode="subscription" :pk="publishableKey" :sessionId="sessionId">
    </stripe-checkout>

    <b-form>
      <b-row class="info-row-border" style="background-color: white">
        <b-col :sm="6" v-for="(field, idx) in filteredEntries" :key="idx" style="height: 70px">
          <b-row>
            <b-col sm="3" style="font-weight: bold">
              <span v-html="field.label"></span>
            </b-col>
            <b-col sm="8">
              <b-checkbox v-if="field.checkbox" v-model="form[field.key]"> </b-checkbox>
              <b-form-group v-else>
                <b-form-input v-model="form[field.key]" :disabled="disableUsersFormField(field.key)" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col :sm="6">
          <b-row>
            <b-col sm="3"></b-col>
            <b-col sm="8">
              <b-button
                style="float: right"
                variant="outline-info"
                v-if="profileInfoHasBeenUpdated()"
                v-on:click="updateUser"
                >Update My Profile
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>

    <template>
      <br />
      <span id="mc-payments-tooltip-style-container"></span>
      <h1>Plans</h1>
      <br />

      <!-- For reference, please check varioous examples here: https://www.web-eau.net/blog/10-examples-pricing-table-bootstrap -->
      <div class="row text-center align-items-center">
        <!-- Pricing Table: Free Plan-->
        <div class="col-lg-4 mb-5 mb-lg-0" :class="{ grayout: disablePlan('Free') }">
          <div class="bg-white p-5 rounded-lg shadow extra-for-ribbon">
            <div class="ribbon" v-if="disablePlan('Free')">Current Plan</div>
            <h1 class="h6 text-uppercase font-weight-bold mb-4">Basic</h1>
            <h2 class="h1 font-weight-bold">&euro; 0<span class="text-small font-weight-normal ml-2">/ month</span></h2>

            <div class="custom-separator my-4 mx-auto bg-primary"></div>

            <ul class="list-unstyled my-5 text-small text-left">
              <li class="mb-3">
                <i class="fa fa-check mr-2 mb-3 text-primary"></i>
                <span
                  v-b-tooltip.hover="{
                    container: 'mc-payments-tooltip-style-container',
                    customClass: 'mc-payments-tooltip-style-css',
                  }"
                  v-b-tooltip.hover.html="tooltipTextShips"
                  >Access to My Ships</span
                >
                <ul class="mb-3 text-muted">
                  <i class="fa fa-times mr-2"></i>
                  <del>Extended BWMS components selection</del>
                </ul>
              </li>
              <li class="mb-3">
                <i class="fa fa-check mr-2 mb-3 text-primary"></i>
                <span
                  v-b-tooltip.hover="{
                    container: 'mc-payments-tooltip-style-container',
                    customClass: 'mc-payments-tooltip-style-css',
                  }"
                  v-b-tooltip.hover.html="tooltipTextCube"
                  >Access to BWMS Cube</span
                >
                <ul class="mb-3 text-muted">
                  <i class="fa fa-times mr-2"></i>
                  <del>Updated documentation (e.g. Design Limitations)</del>
                </ul>
              </li>
              <li class="mb-3 text-muted">
                <i class="fa fa-times mr-2"></i>
                <del>
                  <span
                    v-b-tooltip.hover="{
                      container: 'mc-payments-tooltip-style-container',
                      customClass: 'mc-payments-tooltip-style-css',
                    }"
                    v-b-tooltip.hover.html="tooltipTextFinder"
                    >Access to BWMS Finder</span
                  >
                </del>
              </li>
              <li class="mb-3 text-muted">
                <i class="fa fa-times mr-2"></i>
                <del>
                  <span
                    v-b-tooltip.hover="{
                      container: 'mc-payments-tooltip-style-container',
                      customClass: 'mc-payments-tooltip-style-css',
                    }"
                    v-b-tooltip.hover.html="tooltipTextPorts"
                    >Access to Ports Database</span
                  >
                </del>
              </li>
              <li class="mb-3 text-muted">
                <i class="fa fa-times mr-2"></i>
                <del>Extra Discount on Paid Subscriptions </del>
              </li>
              <li class="mb-3 text-muted">
                <i class="fa fa-times mr-2"></i>
                <del>
                  <span
                    v-b-tooltip.hover="{
                      container: 'mc-payments-tooltip-style-container',
                      customClass: 'mc-payments-tooltip-style-css',
                    }"
                    v-b-tooltip.hover.html="tooltipTextSupport"
                    >BWM support</span
                  >
                </del>
              </li>
            </ul>
            <b-button disabled @click="checkout('Free')">Free</b-button>
          </div>
        </div>
        <!-- END Free Plan -->

        <!-- Pricing Table: Monthly Paid Plan - Pro -->
        <div class="col-lg-4 mb-5 mb-lg-0" :class="{ grayout: disablePlan('Pro') }">
          <div class="bg-white p-5 rounded-lg shadow extra-for-ribbon">
            <div class="ribbon" v-if="disablePlan('Pro')">Current Plan</div>
            <h1 class="h6 text-uppercase font-weight-bold mb-4">Pro</h1>
            <h2 class="h1 font-weight-bold">
              &euro; 99<span class="text-small font-weight-normal ml-2">/ month</span>
            </h2>

            <div class="custom-separator my-4 mx-auto bg-primary"></div>

            <ul class="list-unstyled my-5 text-small text-left font-weight-normal">
              <li class="mb-3">
                <i class="fa fa-check mr-2 mb-3 text-primary"></i>
                <span
                  v-b-tooltip.hover="{
                    container: 'mc-payments-tooltip-style-container',
                    customClass: 'mc-payments-tooltip-style-css',
                  }"
                  v-b-tooltip.hover.html="tooltipTextShips"
                  >Access to My Ships</span
                >
                <ul class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>
                  Extended BWMS components selection
                </ul>
              </li>
              <li class="mb-3">
                <i class="fa fa-check mr-2 mb-3 text-primary"></i>
                <span
                  v-b-tooltip.hover="{
                    container: 'mc-payments-tooltip-style-container',
                    customClass: 'mc-payments-tooltip-style-css',
                  }"
                  v-b-tooltip.hover.html="tooltipTextCube"
                  >Access to BWMS Cube</span
                >
                <ul class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>
                  Updated documentation (e.g. Design Limitations)
                </ul>
              </li>
              <li class="mb-3">
                <i class="fa fa-check mr-2 text-primary"></i>
                <span
                  v-b-tooltip.hover="{
                    container: 'mc-payments-tooltip-style-container',
                    customClass: 'mc-payments-tooltip-style-css',
                  }"
                  v-b-tooltip.hover.html="tooltipTextFinder"
                  >Access to BWMS Finder</span
                >
              </li>
              <li class="mb-3">
                <i class="fa fa-check mr-2 text-primary"></i>
                <span
                  v-b-tooltip.hover="{
                    container: 'mc-payments-tooltip-style-container',
                    customClass: 'mc-payments-tooltip-style-css',
                  }"
                  v-b-tooltip.hover.html="tooltipTextPorts"
                  >Access to Ports Database</span
                >
              </li>
              <li class="mb-3 text-muted">
                <i class="fa fa-times mr-2"></i>
                <del>Extra Discount on Paid Subscriptions </del>
              </li>
              <li class="mb-3 text-muted">
                <i class="fa fa-times mr-2"></i>
                <del>
                  <span
                    v-b-tooltip.hover="{
                      container: 'mc-payments-tooltip-style-container',
                      customClass: 'mc-payments-tooltip-style-css',
                    }"
                    v-b-tooltip.hover.html="tooltipTextSupport"
                    >BWM support</span
                  >
                </del>
              </li>
            </ul>
            <!-- Plans and Payments -->
            <b-button :disabled="disablePlan('Pro')" @click="checkout('Pro')">Subscribe</b-button>
          </div>
        </div>
        <!-- END Pro Plan -->

        <!-- Pricing Table: Yearly Paid Plan - Ultimate -->
        <div class="col-lg-4 mb-5 mb-lg-0" :class="{ grayout: disablePlan('Ultimate') }">
          <div class="bg-white p-5 rounded-lg shadow extra-for-ribbon">
            <div class="ribbon" v-if="!disablePlan('Ultimate')">Best Value</div>
            <div class="ribbon" v-if="disablePlan('Ultimate')">Current Plan</div>
            <h1 class="h6 text-uppercase font-weight-bold mb-4">Ultimate</h1>
            <h2 class="h1 font-weight-bold">
              &euro; 999<span class="text-small font-weight-normal ml-2">/ year</span>
            </h2>

            <div class="custom-separator my-4 mx-auto bg-primary"></div>

            <ul class="list-unstyled my-5 text-small text-left font-weight-normal">
              <li class="mb-3">
                <i class="fa fa-check mr-2 mb-3 text-primary"></i>
                <span
                  v-b-tooltip.hover="{
                    container: 'mc-payments-tooltip-style-container',
                    customClass: 'mc-payments-tooltip-style-css',
                  }"
                  v-b-tooltip.hover.html="tooltipTextShips"
                  >Access to My Ships</span
                >
                <ul class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>
                  Extended BWMS components selection
                </ul>
              </li>
              <li class="mb-3">
                <i class="fa fa-check mr-2 mb-3 text-primary"></i>
                <span
                  v-b-tooltip.hover="{
                    container: 'mc-payments-tooltip-style-container',
                    customClass: 'mc-payments-tooltip-style-css',
                  }"
                  v-b-tooltip.hover.html="tooltipTextCube"
                  >Access to BWMS Cube</span
                >
                <ul class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>
                  Updated documentation (e.g. Design Limitations)
                </ul>
              </li>
              <li class="mb-3">
                <i class="fa fa-check mr-2 text-primary"></i>
                <span
                  v-b-tooltip.hover="{
                    container: 'mc-payments-tooltip-style-container',
                    customClass: 'mc-payments-tooltip-style-css',
                  }"
                  v-b-tooltip.hover.html="tooltipTextFinder"
                  >Access to BWMS Finder</span
                >
              </li>
              <li class="mb-3">
                <i class="fa fa-check mr-2 text-primary"></i>
                <span
                  v-b-tooltip.hover="{
                    container: 'mc-payments-tooltip-style-container',
                    customClass: 'mc-payments-tooltip-style-css',
                  }"
                  v-b-tooltip.hover.html="tooltipTextPorts"
                  >Access to Ports Database</span
                >
              </li>
              <li class="mb-3">
                <i class="fa fa-check mr-2 text-primary"></i>
                Extra Discount on Paid Subscriptions
              </li>
              <li class="mb-3">
                <i class="fa fa-check mr-2 text-primary"></i>
                <span
                  v-b-tooltip.hover="{
                    container: 'mc-payments-tooltip-style-container',
                    customClass: 'mc-payments-tooltip-style-css',
                  }"
                  v-b-tooltip.hover.html="tooltipTextSupport"
                  >BWM support</span
                >
              </li>
            </ul>
            <!-- Plans and Payments -->
            <b-button :disabled="disablePlan('Ultimate')" @click="checkout('Ultimate')">Subscribe</b-button>
          </div>
        </div>
        <!-- END Ultimate plan -->
      </div>
    </template>
    <br />
  </BaseView>
</template>

<script>
import BaseView from "./BaseView.vue";
import { callEndpoint } from "./../utils/apicall.js";
import { getUsername } from "@/utils/helpers.js";
import { TABLE_SCHEMAS } from "./../config.js";
import { StripeCheckout } from "vue-stripe-checkout";

export default {
  name: "Profile",

  components: {
    StripeCheckout,
    BaseView,
  },

  data() {
    return {
      usersSchema: TABLE_SCHEMAS["Users"],
      form: {},
      originalData: {},
      staticColumns: ["country", "email", "date_of_registration", "subscription", "subscription_last_validity_date"],
      selectedPlan: "",

      // Stripe related data
      sessionId: "",
      loading: false,

      // Tooltips
      tooltipTextShips:
        "<b>My Ships:</b> This service allows the user to register a specific ship in the database and include details of the BWMS installed. With the PRO and ULTIMATE subscription, the user can choose from a dropdown list of approved components and specify components of the BWMS that are official. This will allow the user to have a full overview of the BWMS onboard their fleet, including their specific components, and order spare parts with specific models easily. ",
      tooltipTextCube:
        "<b>BWMS Cube:</b> The BWMS Cube is the world´s most extensive and comprehensive database for Ballast Water Management Systems; the database includes not only currently offered and up to date BWMS, it also includes previous models and documentation related to it. The BWMS Cube offers the user a free of charge possibility to filter the BWMS according to several criteria, including type approval status. The PRO and ULTIMATE subscriptions allow the user to use more advanced filtration features as well as access to all aspects of the documentation and operational limitations of the BWMS.",
      tooltipTextFinder:
        "<b>BWMS Finder:</b> The BWMS Finder is only available for PRO and ULTIMATE users. This tool allows the users to enter details of the ship they are planning to fit with a BWMS, and put a weight on which factors they consider most important. The BWMS Finder will then run an algorithm that gives points to each BWMS with models, and recommends the best match for the selected ship. The results can be printed in a simple report that can be used for reference. The BWMS Finder uses an evaluation technique of a large number of parameters, similar to the evaluation Mouawad Consulting does when running market analysis, and instantaneously recommends the best suited BWMS with model and capacity, to the specific ship of the user.",
      tooltipTextPorts:
        "<b>Ports Database:</b> The Ports Database is only available for PRO and ULTIMATE users. This database includes information gathered from various databases, tests and samples taken by Mouawad Consulting team and other sources for water quality of the world´s ports. The database includes ports with average salinity and temperature, and more than 80 ports that have UV Transmittance values. We continue updating this database continuously and improve with more data that becomes available to us.",
      tooltipTextSupport:
        "<b>BWM Support:</b> For the ULTIMATE users, we offer technical and regulatory support by email, to issues related to Ballast Water Management. Subscribers can ask any technical question or inquiry about BWMS, whether planned to be installed or already in operation, and Mouawad Consulting will provide support based on the experience we have with Ballast Water Management over the past 15 years.",
    };
  },

  created() {
    callEndpoint(
      "users",
      (data) => {
        this.form = data[0];
        Object.assign(this.originalData, data[0]);
        sessionStorage.setItem("subscription", this.form["subscription"]);
      },
      { username: getUsername() }
    );
  },

  methods: {
    checkout(selectedPlan) {
      this.selectedPlan = selectedPlan;
      if (selectedPlan == "Free") {
        callEndpoint("cancel_stripe_subscription");
        this.$router.go();
      } else {
        callEndpoint(
          "get_stripe_session_id",
          (data) => {
            this.sessionId = data;
            this.$refs.checkoutRef.redirectToCheckout();
          },
          { subscription: selectedPlan }
        );
      }
    },

    disableUsersFormField(column) {
      return this.staticColumns.includes(column);
    },

    disablePlan(plan) {
      return [plan].indexOf(this.form["subscription"]) >= 0;
    },

    profileInfoHasBeenUpdated() {
      for (const k in this.form) {
        if (k in this.originalData && this.form[k] != this.originalData[k]) {
          return true;
        }
      }
      return false;
    },

    updateUser() {
      callEndpoint(
        "users/" + this.form["username"],
        /* success callback */
        () => {},
        {} /* no params */,
        "PUT",
        this.form,
        /* error handler */
        () => {}
      );
    },
  },

  computed: {
    filteredEntries() {
      return this.usersSchema.filter(
        (f) => !f.primaryKey && (f.conditionalRendering === undefined || f.conditionalRendering(this.form))
      );
    },
    publishableKey() {
      if (window.location.origin.includes("https://platform.bwm.no"))
        return "pk_live_51IsC4cFN1Ed8OjNy1EVsYttAxUFJx9Wz3Ff0scm0pAnJZDwpVXdwQfBu04w8rmvkTGFgfMVUYYoHkMz9034ruv8M00rucTEVSq";
      else
        return "pk_test_51IsC4cFN1Ed8OjNyyZbY4sUGoUld7iGNoLW98DNzvvFSwFLsy5zjkSLQAf2zLX5kfY63K72EthCj7bEmYYRNUTgG001c3GaDEI";
    },
  },
};
</script>

<style lang="scss">
.rounded-lg {
  border-radius: 1rem !important;
}

.text-small {
  font-size: 0.9rem !important;
}

.custom-separator {
  width: 5rem;
  height: 6px;
  border-radius: 1rem;
}

.text-uppercase {
  letter-spacing: 0.2em;
}

.ribbon {
  width: 150px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  box-shadow: 0px 2px 3px rgba(136, 136, 136, 0.25);
  background: #4dbe3b;
  transform: rotate(45deg);
  position: absolute;
  right: -35px;
  top: 25px;
  padding-top: 5px;
  z-index: 100;
}

.extra-for-ribbon {
  overflow: hidden;
  position: relative;
}

.grayout {
  opacity: 0.6;
  filter: alpha(opacity = 60);
}

.mc-payments-tooltip-style-css .tooltip-inner {
  max-width: 700px !important;
  width: 600px !important;
  background-color: white;
  color: black;
  border-color: black;
  border: 2px solid;
}

.tooltip-inner {
  text-align: justify !important;
}
</style>
