<template>
  <div class="col search">
    <!-- Iterate through categories -->
    <div
      v-for="([categoryName, categoryItems], idx) in Object.entries(
        activeSearchCriteria
      )"
      :key="idx"
    >
      <!-- Header -->
      <b-row
        style="
          line-height: 80px;
          padding-left: 0px;
          padding-top: 20px;
          padding-bottom: 20px;
        "
      >
        <b-button class="w-100" disabled>{{ categoryName }}</b-button>
      </b-row>

      <!-- Elements -->
      <b-row
        v-for="(item, label) in categoryItems"
        :key="label"
        style="padding-bottom: 20px; padding-left: 10px; padding-right: 10px"
      >
        <div class="col-12">
          <b-checkbox
            v-if="item.type == 'checkbox'"
            v-model="searchCriteria[item.key]"
            style="float: left"
            v-on:change="searchUpdated"
          ></b-checkbox>
          <span style="white-space: normal">{{ item.label }}</span>
          <!-- Add the currently selected value of the slider to the right of the label -->
          <span
            v-if="item.type == 'slider'"
            style="float: right; padding-right: 12px"
            >{{ searchCriteria[item.key] }}</span
          >
        </div>
        <div class="col-12">
          <b-form-slider
            v-model="searchCriteria[item.key]"
            :step="0.1"
            :min="item.min"
            :max="item.max"
            @slide-start="searchUpdated"
            @slide-stop="searchUpdated"
            v-if="item.type == 'slider'"
          />
          <multiselect
            v-else-if="item.type == 'multiselect'"
            v-model="searchCriteria[item.key]"
            :options="searchCriteriaOptions[item.key]"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :allowEmpty="true"
            :preselectFirst="false"
            :preserve-search="true"
            placeholder="Please select values"
            :max-height="160"
            :preselect-first="true"
          ></multiselect>
          <b-form-select
            :options="item.options"
            v-model="searchCriteria[item.key]"
            v-else-if="item.type == 'dropdown'"
            v-on:change="searchUpdated"
          />
          <b-form-input
            v-model="searchCriteria[item.key]"
            :min="item.min"
            :step="item.step"
            v-bind:type="item.type"
            v-bind:number="item.type == 'number'"
            v-else-if="item.type != 'checkbox'"
          ></b-form-input>
        </div>
      </b-row>
    </div>

    <!-- Find button -->
    <b-row style="padding-left: 0px; padding-top: 15px">
      <b-button
        class="w-100"
        @click="$root.$emit('searchButtonClicked', searchCriteria)"
      >
        Find Eligible Systems
      </b-button>
    </b-row>
  </div>
</template>

<script>
  import bFormSlider from "vue-bootstrap-slider/es/form-slider";
  import "bootstrap-slider/dist/css/bootstrap-slider.css";
  import { callEndpoint } from "./../../utils/apicall.js";
  import Multiselect from "vue-multiselect";

  export default {
    name: "Search",

    components: {
      bFormSlider,
      Multiselect,
    },

    data() {
      return {
        searchCriteria: {},
        searchCriteriaOptions: {},
        value: 0,
      };
    },

    props: {
      router: {
        type: Object,
      },
      searchConfig: {
        type: Object,
        default: () => {},
      },
    },

    methods: {
      searchUpdated() {
        // Explanation of the magic below: vue reactivity is a bit weird when it comes to having dictionaries in record.
        // There, to notify all dependent conditions and components that a change has happened, we need to use the following
        // line. v-model does not do it by itself apparently when a change happens (even though it sets the value correctly, it
        // does not signal the dependent components), so we have to trigger the reactivity ourselves.
        this.searchCriteria = Object.assign(
          {},
          this.searchCriteria,
          this.searchCriteria
        );
      },
    },

    computed: {
      activeSearchCriteria() {
        const activeSearchCriteria = {};
        const vm = this;
        // eslint-disable-next-line
        Object.entries(this.searchConfig).map(function ([key, value], idx) {
          activeSearchCriteria[key] = value.filter((item) => {
            return (
              item.conditionalRendering === undefined ||
              item.conditionalRendering(vm.searchCriteria)
            );
          });
        });
        return activeSearchCriteria;
      },
    },

    created() {
      // Assign empty initial value for each search field
      for (var key in this.searchConfig) {
        for (const [, item] of Object.entries(this.searchConfig[key])) {
          if (item.initValue !== undefined)
            this.searchCriteria[item.key] = item.initValue;
          else if (item.type == "dropdown")
            this.searchCriteria[item.key] = item.options[0];
          else if (item.type == "multiselect") {
            // Initialize all fields for which we were given an endpoint
            this.searchCriteriaOptions[item.key] = [];
            callEndpoint(item.apiEndpoint, (record) => {
              this.searchCriteriaOptions[item.key] = record.map((r) =>
                item.mapFunc(r)
              );
              this.searchCriteriaOptions = Object.assign(
                {},
                this.searchCriteriaOptions,
                this.searchCriteriaOptions
              );
            });
          } else if (item.type == "checkbox")
            this.searchCriteria[item.key] = false;
        }
      }
    },
  };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
  .search {
    font-size: 15px;
    border-left: 3px solid #31373d;
    background-color: #eeeeee;
    color: #31373d;
    white-space: nowrap; // so that text does not break into two lines
    min-height: 100%;
    width: auto;
  }

  /deep/ .slider.slider-horizontal {
    width: 95% !important;
    margin-left: 2.5%;
  }

  /deep/ .slider.slider-horizontal .slider-track {
    height: 20px;
    vertical-align: middle;
  }

  /deep/ .slider.slider-horizontal .slider-tick,
  /deep/ .slider.slider-horizontal .slider-handle {
    margin-top: 5px;
  }

  /deep/ .d-inline-block {
    display: block !important;
  }
</style>
