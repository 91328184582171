<template>
  <div style="height: inherit">
    <div class="chartLabel">
      {{ label }}
    </div>
    <div class="chartPanel">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChartContainer",
  props: ["label"]
};
</script>

<style>
.chartLabel {
  padding-bottom: 10px;
  font-weight: bold;
}
.chartPanel {
  height: inherit;
}
/* Horizontally align the chart inside its container */
.apexcharts-canvas {
  margin: 0 auto;
}
</style>