<template>
  <BaseView @rerenderAfterGenericModal="rerenderAfterGenericModal">
    <div>
      <div class="text-center text-danger my-2" v-if="shipInfo == undefined" style="height: 350px; padding-top: 160px">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading... {{ this.shipInfo }} </strong>
      </div>
      <div v-else-if="shipInfo.length == 0">
        <h2 class="banner-class-text">You are not authorized to view the information of this project</h2>
      </div>
      <b-container fluid v-else>
        <!-- First set of information: Ship's Information -->
        <Collapsable label="SHIP INFO" visible id="shipInfo">
          <b-form>
            <b-row class="info-row-border" style="background-color: white">
              <b-col
                :class="field.explicitWidth ? field.explicitWidth : 'col-6'"
                v-for="(field, idx) in shipsSchema.filter(
                  (f) => f.onAdd && (f.conditionalRendering === undefined || f.conditionalRendering(shipInfo))
                )"
                :key="idx"
                style="height: 70px"
              >
                <b-row>
                  <b-col sm="3" style="font-weight: bold">
                    <span v-html="field.label"></span>
                  </b-col>
                  <b-col sm="8">
                    <b-checkbox v-if="field.checkbox" v-model="shipInfo[field.key]" disabled> </b-checkbox>
                    <b-form-group v-else>
                      <b-form-input :placeholder="shipInfo[field.key] ? shipInfo[field.key].toString() : ''" disabled />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </Collapsable>

        <!-- Tables -->
        <b-row>
          <b-col class="col-12" id="models">
            <Collapsable label="MODELS" visible>
              <Table
                :key="rerenderTableKey"
                apiEndpoint="ships/models"
                :apiParams="{ ship_id: this.$route.params.id, filter_matching: true }"
                stickyHeaderHeight="1125px"
                :fields="modelsSchema"
                v-bind:addButtonEnabled="() => false"
                v-bind:editButtonEnabled="() => false"
                v-bind:deleteButtonEnabled="() => false"
                v-bind:viewButtonEnabled="() => false"
                searchingEnabled
                v-bind:selectButtonEnabled="() => true"
                sortTableByColumn="model_name"
                @rerenderAfterGenericModal="rerenderAfterGenericModal"
                :templates="['is_model_active']"
              >
                <template v-slot:is_model_active="{ data }">
                  <div v-if="data.item['is_model_active']" style="text-align: center">&#128994;</div>
                  <div v-else style="text-align: center">&#9899;</div>
                </template>
              </Table>
            </Collapsable>
          </b-col>

          <b-col class="col-12" id="components">
            <Collapsable label="COMPONENTS" visible>
              <Table
                :key="rerenderTableKey"
                name="components"
                apiEndpoint="ships/components"
                :apiParams="{ ship_id: this.$route.params.id, filter_matching: true }"
                stickyHeaderHeight="1125px"
                :fields="componentsSchema"
                v-bind:addButtonEnabled="() => false"
                v-bind:editButtonEnabled="() => false"
                v-bind:deleteButtonEnabled="() => false"
                v-bind:viewButtonEnabled="() => false"
                searchingEnabled
                v-bind:selectButtonEnabled="() => true"
                @rerenderAfterGenericModal="rerenderAfterGenericModal"
              >
              </Table>
            </Collapsable>
          </b-col>

          <b-col class="col-12" id="filters">
            <Collapsable label="FILTERS" visible>
              <Table
                :key="rerenderTableKey"
                name="filters"
                apiEndpoint="ships/filters"
                :apiParams="{ ship_id: this.$route.params.id, filter_matching: true }"
                stickyHeaderHeight="1125px"
                :fields="filtersSchema"
                v-bind:addButtonEnabled="() => false"
                v-bind:editButtonEnabled="() => false"
                v-bind:deleteButtonEnabled="() => false"
                v-bind:viewButtonEnabled="() => false"
                searchingEnabled
                v-bind:selectButtonEnabled="() => true"
                @rerenderAfterGenericModal="rerenderAfterGenericModal"
              >
              </Table>
            </Collapsable>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </BaseView>
</template>

<script>
/* @ is a shortcut for ./src */
import BaseView from "./BaseView.vue";
import Collapsable from "@/components/containers/Collapsable.vue";
import Table from "@/components/charts/Table.vue";
import { callEndpoint } from "./../utils/apicall.js";
import { TABLE_SCHEMAS } from "./../config.js";

export default {
  name: "ShipsInfo",
  components: {
    BaseView,
    Collapsable,
    Table,
  },

  data() {
    return {
      shipsSchema: TABLE_SCHEMAS["Ships"],
      componentsSchema: this.getComponentModel(TABLE_SCHEMAS["Components"], "ships/components", "system_component_id"),
      filtersSchema: this.getComponentModel(TABLE_SCHEMAS["Filters"], "ships/filters", "system_filter_id"),
      modelsSchema: this.getComponentModel(
        TABLE_SCHEMAS["Models"].filter((e) =>
          [
            "model_id",
            "model_name",
            "capex",
            "opex",
            "capex_opex_currency",
            "min_flow_capacity_m3_h",
            "max_flow_capacity_m3_h",
          ].includes(e.key)
        ),
        "ships/models",
        "model_id"
      ),
      items: [],
      shipInfo: undefined,
      rerenderTableKey: false,
    };
  },

  methods: {
    rerenderAfterGenericModal() {
      this.rerenderTableKey = !this.rerenderTableKey;
    },

    getComponentModel(referenceModel, referenceModelGetAPI, referenceModelPrimaryKey, sortColumn) {
      return referenceModel.concat([
        {
          hidden: true,
          onSelect: true,
          componentName: "TableWithCheckbox",
          componentParams: () => ({
            apiEndpoint: referenceModelGetAPI,
            fields: referenceModel
              .concat([{ key: "count", label: "", noSearch: true, onAdd: true }])
              .filter((f) => f.onAdd == true),
            apiParamsFn: { ship_id: this.$route.params.id },
            tablePrimaryKey: referenceModelPrimaryKey,
            paginationRowsPerPage: 15,
            sortTableByColumnField: sortColumn,
            stickyHeaderHeight: "1125px",
          }),
        },
      ]);
    },
  },

  mounted() {
    callEndpoint(
      "ships",
      (data) => {
        if (data.length > 0) this.shipInfo = data[0];
        else this.shipInfo = [];
      },
      { ship_id: this.$route.params.id },
      "GET"
    );
  },
};
</script>

<style scoped>
.banner-class-text {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #593b8a;
}
/deep/ .form-control:disabled,
.form-control[readonly] {
  background-color: #f9f9f9 !important;
  opacity: 1;
}

/deep/ #shipInfo .mt-2.collapse.show,
/deep/ #models .mt-2.collapse.show,
/deep/ #components .mt-2.collapse.show,
/deep/ #filters .mt-2.collapse.show {
  padding-bottom: 40px;
}
</style>
