<template>
  <BaseView @rerenderAfterGenericModal="rerenderAfterGenericModal">
    <div>
      <div
        class="text-center text-danger my-2"
        v-if="systemInfo == undefined"
        style="height: 350px; padding-top: 160px"
      >
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading... {{ this.systemInfo }} </strong>
      </div>
      <div v-else-if="systemInfo.length == 0">
        <h2 class="banner-class-text">You are not authorized to view the information of this project</h2>
      </div>
      <b-container fluid v-else>
        <!-- First set of information: System's Information -->
        <Collapsable label="SYSTEM INFO" visible id="systemInfo">
          <b-form>
            <b-row class="info-row-border" style="background-color: white">
              <b-col
                :class="field.explicitWidth ? field.explicitWidth : 'col-6'"
                v-for="(field, idx) in systemsSchema.filter(
                  (f) => f.onAdd && (f.conditionalRendering === undefined || f.conditionalRendering(systemInfo))
                )"
                :key="idx"
                style="height: 70px"
              >
                <b-row>
                  <b-col sm="3" style="font-weight: bold">
                    <span v-html="field.label"></span>
                  </b-col>
                  <b-col sm="8">
                    <b-checkbox v-if="field.checkbox" v-model="systemInfo[field.key]" disabled> </b-checkbox>
                    <b-form-group v-else>
                      <b-form-input
                        :placeholder="
                          systemInfo[field.key] !== undefined && systemInfo[field.key] !== null
                            ? systemInfo[field.key].toString()
                            : ''
                        "
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </Collapsable>

        <!-- Tables -->
        <b-row>
          <b-col class="col-12" id="models">
            <Collapsable label="MODELS" visible>
              <Table
                :key="rerenderTableKey"
                apiEndpoint="models"
                :apiParams="{ system_id: this.$route.params.id }"
                stickyHeaderHeight="1125px"
                :fields="modelsSchema"
                searchingEnabled
                sortTableByColumn="model_name"
                v-bind:addButtonEnabled="
                  () => {
                    return isUserAdminBallastAdmin() || (isUserManufacturer() && isUsersRecord(this.systemInfo));
                  }
                "
                v-bind:editButtonEnabled="
                  (row) => {
                    return isUserAdminBallastAdmin() || (isUserManufacturer() && isUsersRecord(this.systemInfo));
                  }
                "
                v-bind:deleteButtonEnabled="
                  (row) => {
                    return isUserAdminBallastAdmin() || (isUserManufacturer() && isUsersRecord(this.systemInfo));
                  }
                "
                v-bind:viewButtonEnabled="() => false"
                @rerenderAfterGenericModal="rerenderAfterGenericModal"
                :templates="['is_model_active']"
              >
                <template v-slot:is_model_active="{ data }">
                  <!-- https://emojiguide.org/green-circle -->
                  <div v-if="data.item['is_model_active']" style="text-align: center">&#128994;</div>
                  <div v-else style="text-align: center">&#9899;</div>
                </template>
              </Table>
            </Collapsable>
          </b-col>

          <b-col class="col-12" id="components">
            <Collapsable label="COMPONENTS" visible>
              <Table
                :key="rerenderTableKey"
                name="components"
                apiEndpoint="systems/components"
                :apiParams="{ system_id: this.$route.params.id, filter_matching: true }"
                stickyHeaderHeight="1125px"
                :fields="componentsSchema"
                searchingEnabled
                v-bind:addButtonEnabled="
                  () => {
                    return isUserAdminBallastAdmin() || (isUserManufacturer() && isUsersRecord(this.systemInfo));
                  }
                "
                v-bind:editButtonEnabled="
                  (row) => {
                    return isUserAdminBallastAdmin() || (isUserManufacturer() && isUsersRecord(this.systemInfo));
                  }
                "
                v-bind:deleteButtonEnabled="
                  (row) => {
                    return isUserAdminBallastAdmin() || (isUserManufacturer() && isUsersRecord(this.systemInfo));
                  }
                "
                v-bind:viewButtonEnabled="() => false"
                :multilineColumns="['suitable_models']"
                @rerenderAfterGenericModal="rerenderAfterGenericModal"
              >
              </Table>
            </Collapsable>
          </b-col>

          <b-col class="col-12" id="filters">
            <Collapsable label="FILTERS" visible>
              <Table
                :key="rerenderTableKey"
                name="filters"
                :apiEndpoint="'systems/filters'"
                :apiParams="{ system_id: this.$route.params.id, filter_matching: true }"
                stickyHeaderHeight="1125px"
                :fields="filtersSchema"
                searchingEnabled
                v-bind:addButtonEnabled="
                  () => {
                    return isUserAdminBallastAdmin() || (isUserManufacturer() && isUsersRecord(this.systemInfo));
                  }
                "
                v-bind:editButtonEnabled="
                  (row) => {
                    return isUserAdminBallastAdmin() || (isUserManufacturer() && isUsersRecord(this.systemInfo));
                  }
                "
                v-bind:deleteButtonEnabled="
                  (row) => {
                    return isUserAdminBallastAdmin() || (isUserManufacturer() && isUsersRecord(this.systemInfo));
                  }
                "
                v-bind:viewButtonEnabled="() => false"
                :multilineColumns="['suitable_models']"
                @rerenderAfterGenericModal="rerenderAfterGenericModal"
              >
              </Table>
            </Collapsable>
          </b-col>

          <b-col class="col-12" id="imoapprovals">
            <Collapsable label="IMO APPROVALS" visible>
              <Table
                :key="rerenderTableKey"
                name="IMO Approvals"
                :apiEndpoint="'systems/imo_approvals'"
                :apiParams="{ system_id: this.$route.params.id }"
                stickyHeaderHeight="1125px"
                :fields="imoApprovalsSchema"
                searchingEnabled
                v-bind:addButtonEnabled="
                  () => {
                    return isUserAdminBallastAdmin() || (isUserManufacturer() && isUsersRecord(this.systemInfo));
                  }
                "
                v-bind:editButtonEnabled="
                  (row) => {
                    return isUserAdminBallastAdmin() || (isUserManufacturer() && isUsersRecord(this.systemInfo));
                  }
                "
                v-bind:deleteButtonEnabled="
                  (row) => {
                    return isUserAdminBallastAdmin() || (isUserManufacturer() && isUsersRecord(this.systemInfo));
                  }
                "
                v-bind:viewButtonEnabled="() => false"
                @rerenderAfterGenericModal="rerenderAfterGenericModal"
              >
              </Table>
            </Collapsable>
          </b-col>

          <b-col class="col-12" id="classocieties">
            <Collapsable label="CLASS SOCIETIES" visible>
              <Table
                :key="rerenderTableKey"
                name="class societies"
                :apiEndpoint="'systems/class_type_approvals'"
                :apiParams="{ system_id: this.$route.params.id }"
                stickyHeaderHeight="1125px"
                searchingEnabled
                :fields="classSocietiesSchema"
                v-bind:addButtonEnabled="
                  () => {
                    return isUserAdminBallastAdmin() || (isUserManufacturer() && isUsersRecord(this.systemInfo));
                  }
                "
                v-bind:editButtonEnabled="
                  (row) => {
                    return isUserAdminBallastAdmin() || (isUserManufacturer() && isUsersRecord(this.systemInfo));
                  }
                "
                v-bind:deleteButtonEnabled="
                  (row) => {
                    return isUserAdminBallastAdmin() || (isUserManufacturer() && isUsersRecord(this.systemInfo));
                  }
                "
                v-bind:viewButtonEnabled="() => false"
                @rerenderAfterGenericModal="rerenderAfterGenericModal"
              >
              </Table>
            </Collapsable>
          </b-col>

          <b-col class="col-12" id="technologies">
            <Collapsable label="TECHNOLOGIES" visible>
              <Table
                :key="rerenderTableKey"
                name="technologies"
                :apiEndpoint="'systems/technologies'"
                :apiParams="{ system_id: this.$route.params.id, filter_matching: true }"
                stickyHeaderHeight="1125px"
                :fields="technologiesSchema"
                searchingEnabled
                v-bind:addButtonEnabled="() => false"
                v-bind:editButtonEnabled="() => false"
                v-bind:deleteButtonEnabled="() => false"
                v-bind:selectButtonEnabled="
                  () => {
                    return isUserAdminBallastAdmin() || (isUserManufacturer() && isUsersRecord(this.systemInfo));
                  }
                "
                v-bind:viewButtonEnabled="() => false"
                @rerenderAfterGenericModal="rerenderAfterGenericModal"
              >
              </Table>
            </Collapsable>
          </b-col>

          <b-col class="col-12" id="documents">
            <Collapsable label="DOCUMENTS" visible id="documents">
              <FileManager :rootDir="'/systems/' + this.systemInfo['system_id'] + '/'" />
            </Collapsable>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </BaseView>
</template>

<script>
/* @ is a shortcut for ./src */
import BaseView from "./../BaseView.vue";
import Collapsable from "@/components/containers/Collapsable.vue";
import Table from "@/components/charts/Table.vue";
import { callEndpoint } from "./../../utils/apicall.js";
import { TABLE_SCHEMAS } from "./../../config.js";
import {
  isUserAdminBallastAdminManufacturer,
  isUserAdminBallastAdmin,
  isUsersRecord,
  isUserManufacturer,
} from "./../../utils/helpers.js";

export default {
  name: "SystemsInfo",
  components: {
    BaseView,
    Collapsable,
    Table,
  },

  data() {
    return {
      systemsSchema: TABLE_SCHEMAS["Systems"],
      componentsSchema: [
        { key: "system_component_id", primaryKey: true, hidden: true },
        { key: "system_id", copyValue: parseInt(this.$route.params.id), hidden: true },
        {
          key: "component_id",
          label: "Component Model",
          virtualAttr: true,
          isDropdown: true,
          onAdd: true,
          fullLine: true,
          hidden: true,
        },
        { key: "component_type_name", label: "Component Type" },
        { key: "name", label: "Manufacturer" },
        { key: "model_name", label: "Component Model" },
        {
          key: "suitable_models",
          label: "Suitable Models",
          onAdd: true,
          isMultiSelectDropdown: true,
          apiEndpoint: "systems/components/models",
          apiParams: { system_id: this.$route.params.id },
        },
        { key: "quantity", label: "Quantity", type: "number", onAdd: true, fullLine: true },
      ],
      imoApprovalsSchema: TABLE_SCHEMAS["ImoApprovals"].concat([
        { key: "system_id", copyValue: parseInt(this.$route.params.id), hidden: true },
      ]),
      classSocietiesSchema: [
        { key: "system_class_type_approvals_id", primaryKey: true, hidden: true },
        {
          key: "class_type_approval_name",
          label: "Class Society Name",
          onAdd: true,
          fullLine: true,
          virtualAttr: true,
          isDropdown: true,
        },
        { key: "system_id", copyValue: parseInt(this.$route.params.id), hidden: true },
        {
          key: "approval_status",
          label: "Approval Status",
          onAdd: true,
          options: ["Ongoing", "Approved"],
          isDropdown: true,
          fullLine: true,
        },
      ],
      filtersSchema: [
        { key: "system_filter_id", primaryKey: true, hidden: true },
        { key: "system_id", copyValue: parseInt(this.$route.params.id), hidden: true },
        {
          key: "filter_id",
          label: "Filter Model",
          virtualAttr: true,
          isDropdown: true,
          onAdd: true,
          fullLine: true,
          hidden: true,
        },
        { key: "model_name", label: "Filter Model" },
        { key: "name", label: "Manufacturer" },
        { key: "capacity_m3h_min", label: "Min Capacity (m<sup>3</sup>/h)" },
        { key: "capacity_m3h_max", label: "Max Capacity (m<sup>3</sup>/h)" },
        { key: "mesh_size_microm", label: "Mesh Size (μm)" },
        {
          key: "suitable_models",
          label: "Suitable Models",
          onAdd: true,
          isMultiSelectDropdown: true,
          apiEndpoint: "systems/filters/models",
          apiParams: { system_id: this.$route.params.id },
        },
        { key: "quantity", label: "Quantity", type: "number", onAdd: true, fullLine: true },
      ],
      modelsSchema: TABLE_SCHEMAS["Models"].concat([
        { key: "system_id", copyValue: parseInt(this.$route.params.id), hidden: true },
      ]),
      technologiesSchema: this.getComponentModel(
        TABLE_SCHEMAS["Technologies"],
        "systems/technologies",
        "technology_id"
      ),
      items: [],
      systemInfo: undefined,
      rerenderTableKey: false,
    };
  },

  methods: {
    isUserAdminBallastAdminManufacturer,
    isUserAdminBallastAdmin,
    isUsersRecord,
    isUserManufacturer,

    rerenderAfterGenericModal() {
      this.rerenderTableKey = !this.rerenderTableKey;
    },

    getComponentModel(referenceModel, referenceModelGetAPI, referenceModelPrimaryKey, sortColumn) {
      return referenceModel.concat([
        {
          hidden: true,
          onSelect: true,
          componentName: "TableWithCheckbox",
          componentParams: () => ({
            apiEndpoint: referenceModelGetAPI,
            fields: referenceModel
              .concat([{ key: "count", label: "", noSearch: true, onAdd: true }])
              .filter((f) => f.onAdd == true),
            apiParamsFn: { is_approved: true, system_id: this.$route.params.id },
            tablePrimaryKey: referenceModelPrimaryKey,
            paginationRowsPerPage: 15,
            sortTableByColumnField: sortColumn,
            stickyHeaderHeight: "1125px",
          }),
        },
      ]);
    },
  },

  mounted() {
    callEndpoint(
      "systems",
      (data) => {
        if (data.length > 0) this.systemInfo = data[0];
        else this.systemInfo = [];
      },
      { system_id: this.$route.params.id },
      "GET"
    );
  },
};
</script>

<style scoped>
.banner-class-text {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #593b8a;
}
/deep/ .form-control:disabled,
.form-control[readonly] {
  background-color: #f9f9f9 !important;
  opacity: 1;
}

/deep/ #systemInfo .mt-2.collapse.show,
/deep/ #models .mt-2.collapse.show,
/deep/ #components .mt-2.collapse.show,
/deep/ #filters .mt-2.collapse.show,
/deep/ #imoapprovals .mt-2.collapse.show,
/deep/ #classocieties .mt-2.collapse.show,
/deep/ #technologies .mt-2.collapse.show {
  padding-bottom: 40px;
}
</style>
