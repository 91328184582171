<template>
  <BaseView @rerenderAfterGenericModal="rerenderAfterGenericModal">
    <div
      class="text-center text-danger my-2"
      v-if="projectInfo == undefined"
      style="height: 350px; padding-top: 160px;"
    >
      <b-spinner class="align-middle"></b-spinner>
      <strong>Loading... {{ this.projectInfo }} </strong>
    </div>
    <div v-else-if="projectInfo.length == 0">
      <h2 class="banner-class-text">
        You are not authorized to view the information of this project
      </h2>
    </div>
    <b-container fluid v-else>
      <!-- Download quote button -->
      <b-button variant="outline-info" style="float: right;" v-on:click="downloadProjectQuote()" v-if="isAdminUser()">
        Generate Quote
      </b-button>

      <!-- First set of information: Account info and Project description -->
      <Collapsable label="PROJECT INFO" visible id="project-info">
        <br />
        <b-form>
          <b-row class="info-row-border" style="background-color: white;">
            <b-col
              :sm="6"
              v-for="(field, idx) in projectSchema.filter(
                (f) =>
                  f.onAdd &&
                  f.key != 'project_description' &&
                  (f.conditionalRendering === undefined || f.conditionalRendering())
              )"
              :key="idx"
              style="height: 70px;"
            >
              <b-row>
                <b-col sm="3" style="font-weight: bold;">
                  <span v-html="field.label"></span>
                </b-col>
                <b-col sm="8">
                  <b-checkbox v-if="field.checkbox" v-model="projectInfo[field.key]" disabled> </b-checkbox>
                  <b-form-group v-else>
                    <b-form-input
                      :placeholder="projectInfo[field.key] ? projectInfo[field.key].toString() : ''"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </Collapsable>
      <Collapsable label="Description" visible>
        <p style="white-space: pre-wrap;">
          {{ projectInfo.project_description }}
        </p>
      </Collapsable>

      <!-- Second set of information: Service and project estimates -->
      <b-row>
        <div class="col-12">
          <Collapsable label="SERVICES" id="services" v-if="isUserInternalResource()">
            <Table
              apiEndpoint="services"
              name="services"
              :key="rerenderTableKey"
              :fields="servicesRequestedSchema"
              :apiParams="{ projectId: this.$route.params.id }"
              v-bind:viewButtonEnabled="() => false"
              @rerenderAfterGenericModal="rerenderAfterGenericModal"
            >
            </Table>
          </Collapsable>
        </div>
        <div class="col-12">
          <Collapsable label="PRODUCTS" v-if="isUserInternalResource()" id="products">
            <Table
              apiEndpoint="products-requested"
              :key="rerenderTableKey"
              :fields="productsRequestedSchema"
              name="Product"
              :apiParams="{ projectId: this.$route.params.id }"
              v-bind:viewButtonEnabled="() => false"
              @rerenderAfterGenericModal="rerenderAfterGenericModal"
            />
          </Collapsable>

          <Collapsable label="DOCUMENTS" id="documents">
            <FileManager
              :key="rerenderTableKey"
              :showShareColumn="this.projectInfo.project_shared == 'SHARED'"
              :rootDir="'/projects/' + this.projectInfo['project_id'] + '/'"
            />
          </Collapsable>

          <Collapsable label="EXTERNAL LINKS" id="external-links">
            <Table
              apiEndpoint="links"
              :key="rerenderTableKey"
              :fields="linksSchema"
              :apiParams="{ projectId: this.$route.params.id }"
              name="external link"
              :urlColumns="{ 'link_url': 'link_name' }"
              v-bind:viewButtonEnabled="() => false"
              @rerenderAfterGenericModal="rerenderAfterGenericModal"
            />
          </Collapsable>

          <Collapsable label="TEAM MEMBERS" v-if="isUserInternalResource()">
            <Table
              apiEndpoint="team-members"
              :key="rerenderTableKey"
              :fields="teamMembersSchema"
              :apiParams="{ projectId: this.$route.params.id }"
              name="team member"
              v-bind:viewButtonEnabled="() => false"
              @rerenderAfterGenericModal="rerenderAfterGenericModal"
            />
          </Collapsable>
        </div>
      </b-row>
    </b-container>
  </BaseView>
</template>

<script>
import BaseView from "@/views/BaseView.vue";
import Collapsable from "@/components/containers/Collapsable.vue";
import Table from "@/components/charts/Table.vue";
import { callEndpoint } from "@/utils/apicall.js";
import { currencyFormatter } from "@/components/style_formatters/currencyFormatter.js";
import store from "@/views/auth/store.js";
import { TABLE_SCHEMAS } from "@/config.js";
import { isAdminUser, isUserInternalResource } from "@/utils/helpers.js";

export default {
  name: "ProjectInfo",

  components: {
    BaseView,
    Collapsable,
    Table,
  },

  data() {
    return {
      store,
      items: [],
      projectInfo: undefined,
      projectSchema: TABLE_SCHEMAS["Projects"],
      linksSchema: TABLE_SCHEMAS["Links"].concat([
        { key: "project_id", copyValue: parseInt(this.$route.params.id), hidden: true },
      ]),
      servicesRequestedSchema: TABLE_SCHEMAS["ServicesRequested"].concat([
        { key: "project_id", copyValue: parseInt(this.$route.params.id), hidden: true },
      ]),
      productsRequestedSchema: TABLE_SCHEMAS["ProductsRequested"].concat([
        { key: "project_id", copyValue: parseInt(this.$route.params.id), hidden: true },
      ]),
      teamMembersSchema: TABLE_SCHEMAS["TeamMembers"].concat([
        { key: "project_id", copyValue: parseInt(this.$route.params.id), hidden: true },
      ]),
      rerenderTableKey: false,
    };
  },

  methods: {
    isAdminUser,
    isUserInternalResource,
    currencyFormatter,

    rerenderAfterGenericModal() {
      this.rerenderTableKey = !this.rerenderTableKey;
    },

    downloadProjectQuote() {
      const currDate = new Date().toJSON().slice(0, 10).replace(/-/g, "");
      callEndpoint(
        "download-quote",
        (data) => {
          var fileURL = window.URL.createObjectURL(data);
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", this.projectInfo["project_title"] + "_quote_" + currDate + ".docx");
          document.body.appendChild(fileLink);
          fileLink.click();
        },
        { id: this.$route.params.id, obj_type: "project" },
        "GET",
        {},
        null,
        "blob"
      );
    },
  },

  mounted() {
    callEndpoint(
      "projects",
      (data) => {
        if (data.length > 0) this.projectInfo = data[0];
        else this.projectInfo = [];
      },
      { projectId: this.$route.params.id },
      "GET"
    );
  },
};
</script>

<style>
.banner-class-text {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #593b8a;
}
.info-row-border {
  margin-left: 2px !important;
  margin-right: 2px !important;
  border: 2px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-color: #ddd;
  padding-top: 30px;
  padding-bottom: 0px;
}

/* Controls how much space there should be after specific tables only when the table is in a collapsable and it is in shown state */
#project-info .mt-2.collapse.show,
#services .mt-2.collapse.show,
#documents .mt-2.collapse.show,
#external-links .mt-2.collapse.show,
#products .mt-2.collapse.show {
  padding-bottom: 32px;
}
</style>
