<template>
  <div :key="resetTransition">
    <b-modal
      :id="this.$options.name"
      hide-footer
      centered
      no-stacking
      no-close-on-backdrop
      size="lg"
      header-class="forget-password-header-class"
      footer-class="forget-password-footer-class"
      content-class="forget-password-content-class"
      body-class="forget-password-body-class"
      @ok="handleEvent"
      @close="resetModal"
    >
      <transition name="fade" mode="out-in">
        <div v-if="resetPwPaneShown" key="pane#1" class="forget-password-root-div-conf">
          <div class="forgot-password-label">
            <h1>Forgot your password?</h1>
          </div>
          <div>
            Enter your email address in the field below to receive an e-mail link for resetting your password:
            <div style="height:100px; max-height:100px; width: 450px; display: inline-block; margin-top:20px">
              <b-form @submit="handleEvent">
                <b-form-input id="reset-password-field" v-model="email" required></b-form-input>
                <div style="padding-top: 20px; height: 110px; min-height: 110px">
                  <div style="padding-left: 73px">
                    <VueRecaptcha
                      @verify="markRecaptchaAsVerified"
                      @expired="markRecaptchaAsInvalid"
                      @error="markRecaptchaAsInvalid"
                      sitekey="6Lcov-gUAAAAABDeprjgVyVSfvKQUv70KJFTgdSO"
                      :loadRecaptchaScript="true"
                    ></VueRecaptcha>
                  </div>
                  <b-form-invalid-feedback :state="captchaFilled()"
                    >Please complete the captcha</b-form-invalid-feedback
                  >
                </div>
                <b-button type="submit" variant="primary" style="margin-top:30px">SUBMIT</b-button>
              </b-form>
            </div>
          </div>
        </div>
        <div
          v-else
          key="pane#2"
          class="forgot-password-label forget-password-root-div-conf"
          style="padding-top: 105px;"
        >
          <h2>
            An email to reset your password
            <br />was sent to your inbox
          </h2>
        </div>
      </transition>
    </b-modal>
  </div>
</template>

<script>
import { callEndpoint } from "./../utils/apicall.js";

export default {
  name: "ForgotPasswordModal",

  data() {
    return {
      email: null,
      resetPwPaneShown: true,
      resetTransition: true,
      captchaCompleted: false,
      attemptedSubmit: false
    };
  },

  methods: {
    markRecaptchaAsVerified() {
      this.captchaCompleted = true;
    },

    markRecaptchaAsInvalid() {
      this.captchaCompleted = false;
    },

    captchaFilled() {
      return !(this.attemptedSubmit == true && this.captchaCompleted == false);
    },

    handleEvent(evt) {
      evt.preventDefault();
      if (this.resetPwPaneShown) {
        this.handleResetPwPaneClose();
      } else this.handleResetMailSendPaneClose(evt);
    },

    handleResetPwPaneClose() {
      this.attemptedSubmit = true;
      if (this.email == null) return;
      if (this.captchaFilled() == false) return;

      callEndpoint(
        "send-email",
        /* success callback */
        () => {},
        {
          receiver_email: this.email,
          receiver_os: this.getOS(),
          receiver_browser: this.getBrowserVersion(),
          email_subject: "Mouawad Consulting Portal: Password Reset Link",
          email_template_name: "reset-password-email-template"
        },
        "PUT",
        {} /* No special body, we pass data as parameters */,
        /* error handler */
        () => {}
      );

      /* Switch to the "your password has been send pane" */
      this.resetPwPaneShown = false;
    },

    handleResetMailSendPaneClose() {
      /* Just close the modal */
      this.resetModal();
      this.$bvModal.hide(this.$options.name);
    },

    resetModal() {
      this.email = null;
      this.resetPwPaneShown = true;
      this.resetTransition = !this.resetTransition;
      this.attemptedSubmit = false;
      this.captchaCompleted = false;
    },

    getBrowserVersion() {
      // Source: https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
      /* eslint-disable */
      if ((!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0) return "Opera";
      else if (typeof InstallTrigger !== "undefined") return "Firefox";
      else if (
        /constructor/i.test(window.HTMLElement) ||
        (function(p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(!window["safari"] || (typeof safari !== "undefined" && safari.pushNotification))
      )
        return "Safari";
      else if (/*@cc_on!@*/ false || !!document.documentMode) return "Internet Explorer";
      else if (!!window.StyleMedia) return "Edge";
      else return "Chrome";
      /* eslint-enable */
    },

    getOS() {
      // Source of platforms: https://www.w3schools.com/jsref/prop_nav_platform.asp
      if (navigator.platform.indexOf("Win") != -1) return "Windows OS";
      else if (navigator.platform.indexOf("Mac") != -1) return "MacOS";
      else if (navigator.platform.indexOf("Linux") != -1) return "Linux OS";
      else return "UNIX OS";
    }
  }
};
</script>

<style>
.forgot-password-label {
  color: #593b8a;
  text-align: center;
  padding-bottom: 10px;
}
.forget-password-footer-class {
  border-top: 0px solid white !important;
  display: inline-block;
  text-align: center;
}
.forget-password-body-class {
  padding-bottom: 0px !important;
}
.forget-password-root-div-conf {
  text-align: center;
}
.forget-password-content-class {
  height: 420px !important;
  max-height: 420px !important;
}
.forget-password-header-class {
  border-bottom: 0px solid white !important;
}
/* Transition CSS */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>