<template>
  <BaseView>
    <Table
      :key="rerenderTableKey"
      apiEndpoint="accounts"
      stickyHeaderHeight="1125px"
      :fields="accountsSchema"
      :emailColumns="['email']"
      searchingEnabled
      @rerenderAfterGenericModal="rerenderAfterGenericModal"
    >
    </Table>
  </BaseView>
</template>

<script>
/* @ is a shortcut for ./src */
import BaseView from "./BaseView.vue";
import Table from "@/components/charts/Table.vue";
import { phoneNumberFormatter } from "@/components/style_formatters/phoneNumberFormatter.js";
import store from "./../views/auth/store.js";
import { TABLE_SCHEMAS } from "./../config.js";

export default {
  name: "Accounts",
  components: {
    BaseView,
    Table,
  },
  data() {
    return {
      store,
      rerenderTableKey: false,
      accountsSchema: TABLE_SCHEMAS[this.$options.name],
    };
  },
  methods: {
    phoneNumberFormatter,
    rerenderAfterGenericModal() {
      this.rerenderTableKey = !this.rerenderTableKey;
    },
  },
};
</script>
