<template>
  <BaseView>
    <Table
      :key="rerenderTableKey"
      apiEndpoint="technologies"
      stickyHeaderHeight="1125px"
      :fields="technologiesSchema"
      searchingEnabled
      v-bind:addButtonEnabled="
        () => {
          return isUserAdminBallastAdminManufacturer();
        }
      "
      v-bind:editButtonEnabled="
        (row) => {
          return isUserAdminBallastAdmin() || (row !== undefined && isUsersRecord(row));
        }
      "
      v-bind:deleteButtonEnabled="
        (row) => {
          return isUserAdminBallastAdmin() || (row !== undefined && isUsersRecord(row));
        }
      "
      v-bind:viewButtonEnabled="() => false"
      sortTableByColumn="technology_name"
      @rerenderAfterGenericModal="rerenderAfterGenericModal"
    >
    </Table>
  </BaseView>
</template>

<script>
/* @ is a shortcut for ./src */
import BaseView from "./../BaseView.vue";
import Table from "@/components/charts/Table.vue";
import { currencyFormatter } from "@/components/style_formatters/currencyFormatter.js";
import store from "./../../views/auth/store.js";
import { TABLE_SCHEMAS, APPROVE_BUTTON_FIELD } from "./../../config.js";
import { isUserAdminBallastAdminManufacturer, isUserAdminBallastAdmin, isUsersRecord } from "./../../utils/helpers.js";

export default {
  name: "Technologies",
  components: {
    BaseView,
    Table,
  },
  data() {
    return {
      store,
      technologiesSchema: TABLE_SCHEMAS[this.$options.name].concat([
        { key: "count", label: "Used by # active systems", noSearch: true, thClass: "buttonClassx8" },
        APPROVE_BUTTON_FIELD,
      ]),
      rerenderTableKey: false,
    };
  },
  methods: {
    isUserAdminBallastAdminManufacturer,
    isUserAdminBallastAdmin,
    isUsersRecord,
    currencyFormatter,
    rerenderAfterGenericModal() {
      this.rerenderTableKey = !this.rerenderTableKey;
    },
  },
};
</script>
