<template>
  <BaseView>
    <Table
      :key="rerenderTableKey"
      apiEndpoint="service-types"
      stickyHeaderHeight="1125px"
      name="Service"
      :fields="servicesSchema"
      searchingEnabled
      @rerenderAfterGenericModal="rerenderAfterGenericModal"
    >
    </Table>
  </BaseView>
</template>

<script>
import BaseView from "@/views/BaseView.vue";
import Table from "@/components/charts/Table.vue";
import { TABLE_SCHEMAS } from "@/config.js";

export default {
  name: "Services",
  components: {
    BaseView,
    Table
  },
  data() {
    return {
      rerenderTableKey: true,
      servicesSchema: TABLE_SCHEMAS[this.$options.name]
    };
  },
  methods: {
    rerenderAfterGenericModal() {
      this.rerenderTableKey = !this.rerenderTableKey;
    }
  }
};
</script>
