<template>
  <div style="text-align: left !important;">
    <b-dropdown :text="menuTitle" split split-variant="outline-primary" variant="primary" menu-class="w-100" block>
      <b-dropdown-item v-for="item in menuItems" :key="item" @click="onChange($event)">
        {{ item }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { callEndpoint } from "../../utils/apicall.js";

export default {
  name: "DropdownMenu",
  props: {
    items: Array,
    apiEndpoint: String,
    allOptionsText: String,
    defaultOption: String,
    apiParams: {},
    // Prop used to extract one or more fields after data are returned from the API
    fieldExtractor: {
      type: Function,
      default: e => e
    }
  },
  data() {
    return {
      menuItems: [],
      menuTitle: this.defaultOption || this.allOptionsText
    };
  },
  created() {
    if (this.apiEndpoint) {
      callEndpoint(
        this.apiEndpoint,
        data => {
          this.menuItems = data.map(this.fieldExtractor);
          this.menuItems.push(this.allOptionsText);
        },
        this.apiParams
      );
    } else {
      this.menuItems = this.items.map(x => x);
      this.menuItems.push(this.allOptionsText);
    }
  },
  methods: {
    // Triggered when one item from the menu is selected
    onChange(event) {
      const eventTxt = event.srcElement.text.trim();
      this.menuTitle = eventTxt;
      const emitEvent = eventTxt == this.allOptionsText ? "" : eventTxt;
      this.$emit("dropdownSelected", emitEvent);
    }
  }
};
</script>

<style>
.btn-block {
  text-align: left !important;
  border-color: #593b8a !important;
  color: #593b8a !important;
}
.btn-outline-primary {
  background-color: white !important;
}
.dropdown-toggle {
  color: #593b8a !important;
  background-color: white !important;
  border-color: #593b8a !important;
}
.dropdown-item {
  color: #593b8a !important;
}
</style>