<template>
  <BaseView backgroundColor="white">
    <b-container fluid>
      <!-- Dropdowns on top -->
      <b-row class="mb" v-if="isUserInternalResource()">
        <b-col>
          <DropdownMenu
            allOptionsText="ALL REGIONS"
            :items="this.store.state.regions"
            @dropdownSelected="region = $event"
          />
        </b-col>
        <b-col>
          <DropdownMenu
            allOptionsText="ALL TIME"
            :items="['THIS YEAR', 'THIS MONTH', 'THIS WEEK']"
            defaultOption="ALL TIME"
            @dropdownSelected="timePeriod = $event"
          />
        </b-col>
        <b-col v-if="isAdminUser()">
          <DropdownMenu
            allOptionsText="ALL MANAGERS"
            apiEndpoint="users/fullnames"
            :fieldExtractor="(user) => user['full_name']"
            @dropdownSelected="managerName = $event"
            :apiParams="{ role: ['Administrator', 'Project Manager'] }"
          />
        </b-col>
      </b-row>

      <!-- Statistics pane -->
      <b-row class="mb" v-if="isUserInternalResource()">
        <b-col v-on:click="focusComponent('leads')" v-if="isAdminUser()">
          <StatisticsContainer
            text="New Leads"
            backgroundColor="lightgrey"
            apiEndpoint="leads/count"
            :apiParams="Object.assign({}, { status: ['New'] }, apiParams)"
            :key="this.dropdownChangedKey"
          />
        </b-col>
        <b-col v-on:click="focusComponent('opportunities')" v-if="isAdminUser()">
          <StatisticsContainer
            text="Open Opportunities"
            apiEndpoint="opportunities/count"
            :apiParams="Object.assign({}, { status: ['New'] }, apiParams)"
            :key="this.dropdownChangedKey"
          />
        </b-col>
        <b-col v-on:click="focusComponent('open-projects')">
          <StatisticsContainer
            text="Open Projects"
            backgroundColor="lightgrey"
            apiEndpoint="projects/count"
            :apiParams="Object.assign({}, { projectStatus: ['In Progress', 'Pending'] }, apiParams)"
            :key="this.dropdownChangedKey"
          />
        </b-col>
        <b-col v-on:click="focusComponent('ships')" v-if="isAdminUser()">
          <StatisticsContainer
            text="New Ships"
            apiEndpoint="ships/count"
            :apiParams="apiParams"
            :key="this.dropdownChangedKey"
          />
        </b-col>
        <b-col v-on:click="focusComponent('open-projects')">
          <StatisticsContainer
            text="Overdue Services(s)"
            apiEndpoint="projects/count/overdue-services"
            backgroundColor="lightgrey"
            :apiParams="apiParams"
            :key="this.dropdownChangedKey"
          />
        </b-col>
      </b-row>

      <!-- Graphs -->
      <b-row class="mb" style="height: 330px" v-if="isUserInternalResource()">
        <b-col>
          <div class="dashboardGraphBgColor">
            <div class="align-middle-if-small-res" style="height: 60px; margin: 0 auto; display: table">
              <b> NUMBER OF PROJECTS <span style="display: inline-block; font-stretch: 100%">PER PROJECT TYPE</span></b>
            </div>
            <div style="height: 270px; max-width: 430px; margin: 0 auto">
              <PieChart
                apiEndpoint="projects/count/type"
                :apiParams="{
                  region: this.region,
                  managerName: this.managerName,
                  startDate: this.getStartDate(),
                }"
                :key="this.dropdownChangedKey"
              />
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="dashboardGraphBgColor">
            <div class="align-middle-if-small-res" style="height: 60px; margin: 0 auto; display: table">
              <b>HOURS LOGGED <span style="display: inline-block">PER PROJECT TYPE</span></b>
            </div>
            <div style="height: 270px; max-width: 430px; margin: 0 auto">
              <PieChart
                apiEndpoint="projects/count/hours-logged/type"
                :apiParams="{
                  region: this.region,
                  managerName: this.managerName,
                  startDate: this.getStartDate(),
                }"
                :key="this.dropdownChangedKey"
              />
            </div>
          </div>
        </b-col>
        <b-col v-if="isAdminUser()">
          <div class="dashboardGraphBgColor">
            <div class="align-middle-if-small-res" style="height: 60px; padding-left: 30px; display: table">
              <b>TOTAL VALUE <span style="display: inline-block">PER PROJECT TYPE</span></b>
            </div>
            <div style="height: 270px; max-width: 430px; margin: 0 auto">
              <PieChart
                apiEndpoint="projects/sum/value/type"
                :apiParams="{
                  currency: this.currencyForChart,
                  region: this.region,
                  managerName: this.managerName,
                  startDate: this.getStartDate(),
                }"
                :key="this.dropdownChangedKeyForChart"
              />
            </div>
          </div>
          <div style="position: absolute; right: 15px; top: 0">
            <DropdownMenu
              defaultOption="NOK"
              :items="['NOK', 'EUR', 'GBP', 'USD']"
              allOptionsText="ALL"
              @dropdownSelected="currencyForChart = $event"
            />
          </div>
        </b-col>
      </b-row>

      <!-- Tables -->
      <div class="row-border mb-3" style="background-color: white">
        <Collapsable label="Active Projects" id="projects" ref="open-projects" v-if="isUserInternalResource()">
          <Table
            apiEndpoint="projects"
            :fields="[
              { key: 'project_title', label: 'Project Title' },
              { key: 'name', label: 'Account' },
              { key: 'project_type', label: 'Project Type' },
              { key: 'manager_full_name', label: 'Project Manager' },
              { key: 'issued_amount', label: 'Total Amount', formatter: currencyFormatter('issued_amount') },
              { key: 'num_hours_registered', label: '#Hours Registered' },
              { key: 'num_overdue_services', label: 'Num Overdue Services' },
              { key: 'validity_date', label: 'Due Date' },
              { key: 'region', label: 'Region' },
            ]"
            :apiParams="{
              region: this.region,
              managerName: this.managerName,
              startDate: this.getStartDate(),
              projectStatus: ['In Progress', 'Pending'],
            }"
            :key="this.dropdownChangedKey"
            :rowClickedHandler="navigateToProjectPage"
            v-bind:addButtonEnabled="() => false"
            v-bind:editButtonEnabled="() => false"
            v-bind:deleteButtonEnabled="() => false"
            v-bind:viewButtonEnabled="() => false"
            searchingEnabled
            disableTableBorder
          />
        </Collapsable>

        <Collapsable label="Opportunities" id="opportunities" ref="opportunities" v-if="isAdminUser()">
          <Table
            apiEndpoint="opportunities"
            :fields="opportunitiesSchema"
            :apiParams="{
              region: this.region,
              managerName: this.managerName,
              startDate: this.getStartDate(),
              status: ['New'],
            }"
            :key="this.dropdownChangedKey"
            v-bind:addButtonEnabled="() => false"
            v-bind:editButtonEnabled="() => false"
            v-bind:deleteButtonEnabled="() => false"
            v-bind:viewButtonEnabled="() => false"
            searchingEnabled
            disableTableBorder
          >
          </Table>
        </Collapsable>

        <Collapsable label="Leads" id="leads" ref="leads" v-if="isAdminUser()">
          <Table
            apiEndpoint="leads"
            :fields="leadsSchema"
            :apiParams="{
              region: this.region,
              managerName: this.managerName,
              startDate: this.getStartDate(),
              status: ['New'],
            }"
            :key="this.dropdownChangedKey"
            :emailColumns="['Account Email']"
            searchingEnabled
            disableTableBorder
            v-bind:addButtonEnabled="() => false"
            v-bind:editButtonEnabled="() => false"
            v-bind:deleteButtonEnabled="() => false"
            v-bind:viewButtonEnabled="() => false"
          >
          </Table>
        </Collapsable>

        <Collapsable label="Ships" id="ships" ref="ships" v-if="isAdminUser()">
          <Table
            apiEndpoint="ships"
            :fields="shipsSchema"
            :apiParams="{
              region: this.region,
              startDate: this.getStartDate(),
              status: leadsStatusOptions,
            }"
            :key="this.dropdownChangedKey"
            searchingEnabled
            disableTableBorder
            v-bind:addButtonEnabled="() => false"
            v-bind:editButtonEnabled="() => false"
            v-bind:deleteButtonEnabled="() => false"
            v-bind:viewButtonEnabled="() => false"
          />
        </Collapsable>

        <Collapsable label="Own Components" id="components" ref="components" v-if="isUserManufacturer()">
          <Table
            apiEndpoint="components"
            :fields="componentsSchema"
            :apiParams="{ username: getUsername() }"
            searchingEnabled
            disableTableBorder
            v-bind:addButtonEnabled="() => false"
            v-bind:editButtonEnabled="() => false"
            v-bind:deleteButtonEnabled="() => false"
          >
          </Table>
        </Collapsable>

        <Collapsable label="Own Filters" id="filters" ref="filters" v-if="isUserManufacturer()">
          <Table
            apiEndpoint="filters"
            :fields="filtersSchema"
            :apiParams="{ username: getUsername() }"
            searchingEnabled
            disableTableBorder
            v-bind:addButtonEnabled="() => false"
            v-bind:editButtonEnabled="() => false"
            v-bind:deleteButtonEnabled="() => false"
          >
          </Table>
        </Collapsable>

        <Collapsable label="Own Systems" id="systems" ref="systems" v-if="isUserManufacturer()">
          <Table
            apiEndpoint="systems"
            :fields="systemsSchema"
            :apiParams="{ username: getUsername() }"
            searchingEnabled
            disableTableBorder
            v-bind:addButtonEnabled="() => false"
            v-bind:editButtonEnabled="() => false"
            v-bind:deleteButtonEnabled="() => false"
          >
          </Table>
        </Collapsable>
      </div>
    </b-container>
  </BaseView>
</template>

<script>
/* @ is a shortcut for ./src */
import BaseView from "@/views/BaseView.vue";
import PieChart from "@/components/charts/PieChart.vue";
import Collapsable from "@/components/containers/Collapsable.vue";
import StatisticsContainer from "@/components/containers/StatisticsContainer.vue";
import DropdownMenu from "@/components/containers/DropdownMenu.vue";
import Table from "@/components/charts/Table.vue";
import { currencyFormatter } from "@/components/style_formatters/currencyFormatter.js";
import { phoneNumberFormatter } from "@/components/style_formatters/phoneNumberFormatter.js";
import {
  convertDateToAPIFormat,
  isAdminUser,
  isUserInternalResource,
  isUserManufacturer,
  getUsername,
} from "@/utils/helpers.js";
import store from "@/views/auth/store.js";
import { TABLE_SCHEMAS, TABLE_ENUMS } from "@/config.js";

export default {
  name: "DashboardGeneral",

  components: {
    BaseView,
    PieChart,
    Collapsable,
    StatisticsContainer,
    DropdownMenu,
    Table,
  },

  data() {
    return {
      store,
      region: "",
      managerName: "",
      timePeriod: "ALL TIME",
      currencyForChart: "NOK",
      leadsSchema: TABLE_SCHEMAS["Leads"],
      leadsStatusOptions: TABLE_ENUMS["LeadsStatusOptions"],
      opportunitiesSchema: TABLE_SCHEMAS["Opportunities"],
      filtersSchema: TABLE_SCHEMAS["Filters"],
      componentsSchema: TABLE_SCHEMAS["Components"],
      systemsSchema: TABLE_SCHEMAS["Systems"],
      shipsSchema: TABLE_SCHEMAS["Ships"],
    };
  },

  methods: {
    currencyFormatter,
    phoneNumberFormatter,
    isAdminUser,
    isUserInternalResource,
    isUserManufacturer,
    getUsername,

    navigateToProjectPage(row) {
      this.$router.push("/projects/" + row["project_id"]);
    },

    getStartDate() {
      const date = new Date();
      switch (this.timePeriod) {
        case "THIS WEEK":
          date.setDate(date.getDate() - 7);
          return convertDateToAPIFormat(date);
        case "THIS MONTH":
          date.setMonth(date.getMonth() - 1);
          return convertDateToAPIFormat(date);
        case "THIS YEAR":
          date.setFullYear(date.getFullYear() - 1);
          return convertDateToAPIFormat(date);
        default:
          return ""; // No filtering
      }
    },

    focusComponent(componentId) {
      this.$refs[componentId].$el.scrollIntoView();
      if (this.$refs[componentId].visibility == false) this.$root.$emit("bv::toggle::collapse", componentId);
    },
  },

  computed: {
    dropdownChangedKey: function () {
      return this.region + "/" + this.managerName + "/" + this.timePeriod;
    },
    dropdownChangedKeyForChart: function () {
      return this.dropdownChangedKey + "/" + this.currencyForChart;
    },
    apiParams: function () {
      return {
        region: this.region,
        managerName: this.managerName,
        startDate: this.getStartDate(),
      };
    },
  },
};
</script>

<style lang="scss">
.dashboardGraphBgColor {
  // border-style: solid;
  // border-width: 1.5px;
  // border-color: lightgray;
  background-color: white;
}
.dashboardGraphPadding {
  padding-left: 15px;
  padding-right: 15px;
}
.row-border {
  /* Controls the padding between the container shadow / border and the table inside */
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 18px;
  padding-bottom: 18px;
  border: 2px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-color: #ddd;
}
/* Controls how much space there should be after specific tables only when the table is in a collapsable and it is in shown state */
#leads .mt-2.collapse.show,
#opportunities .mt-2.collapse.show,
#projects .mt-2.collapse.show,
#components .mt-2.collapse.show,
#filters .mt-2.collapse.show {
  padding-bottom: 40px;
}
.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

@media (max-width: 1360px) {
  /* CSS that should be displayed if width is equal to or less than 1300px goes here */
  .align-middle-if-small-res {
    text-align: center;
  }
}
@media (max-width: 1200px) {
  .removing-padding-if-small-res > .col-4 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .align-middle-if-small-res {
    text-align: left;
  }
}
.mb {
  margin-bottom: 2.25rem !important;
}
</style>
