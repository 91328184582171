<template>
  <div class="menu" :style="getMenuGlobalStyle">
    <!-- 1st level menu elements (taken from router) -->
    <ul>
      <!-- Top, 1st row Info -->
      <li class="menu__top_row">
        <div><img :src="logoIconPath" class="menu__logo" /></div>
        <div v-if="!menuIsCollapsed" class="menu__title">{{ menuTitle }}</div>
        <div>
          <i :class="getCollapseMenuIconClasses" @click.prevent="collapseMenuWidth()" aria-hidden="true"></i>
        </div>
      </li>

      <!-- Actual menu elements -->
      <li
        v-for="(value, label) in menuData"
        :key="label"
        @click.prevent="firstLevelMenuItemClicked(value)"
        :class="getMenuItemClass(label)"
        @mouseleave.prevent="setHover(label, false)"
      >
        <a>
          <span class="menu__icon">
            <i :class="value.icon" @mouseover.prevent="setHover(label, true)"></i>
          </span>
          <span v-if="!menuIsCollapsed || hover[label] == true">
            <!-- Display the text only if the menu is not collapsed or we are hovering over it -->
            {{ value.name }}
          </span>
        </a>
      </li>

      <div v-if="!menuIsCollapsed" style="position: absolute; bottom: 0; padding: 13px; z-index: 1000">Created by <strong><a href="https://innovum.co" style="color: white;">Innovum</a></strong></div>
    </ul>

    <!-- context menu: childs of root level items (appearing on button click of 1st level elements, hence the transition) -->
    <transition name="slide-fade">
      <div class="submenu__container" v-show="showContextMenu">
        <ul>
          <!-- Add submenu title -->
          <li class="submenu__title">
            <h5>{{ this.activeSubMenu.submenuTitle ? this.activeSubMenu.submenuTitle : this.activeSubMenu.folder }}</h5>
            <div @click.prevent="submenuMenuItemClicked" class="submenu__btn-close">
              <i class="fa fa-window-close" aria-hidden="true"></i>
            </div>
          </li>
          <!-- Add all submenus -->
          <li
            v-for="(item, index) in this.activeSubMenu.submenuItems"
            :key="index"
            @click.prevent="openSubmenu(item)"
            class="submenu__link"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
  import { UI_CONSTANTS } from "./../../config.js";

  export default {
    name: "Menu",

    data() {
      return {
        hover: {},
        menuIsCollapsed: false,
        activeSubMenu: ""
      };
    },

    props: ["router", "logoIconPath", "menuTitle"],

    methods: {
      getMenuItemClass(label) {
        return {
          highlightedHoverableMenuItem: this.activeSubMenu == "" && (!this.menuIsCollapsed || this.hover[label] == true)
        };
      },

      collapseMenuWidth() {
        this.menuIsCollapsed = !this.menuIsCollapsed;
        sessionStorage.setItem("menuIsCollapsed", this.menuIsCollapsed);
      },

      getMenuItemName(route) {
        // If there is no override for the name that should be displayed in the menu, take the name of the route as default
        return route.inMenu.txt === undefined ? route.name : route.inMenu.txt;
      },

      // Triggered when one of the 1st level menu items is clicked
      firstLevelMenuItemClicked(topLevelMenu) {
        if (topLevelMenu.submenuItems === undefined) this.$router.push(topLevelMenu.path);
        else if (topLevelMenu.submenuItems.length == 1) this.$router.push(topLevelMenu.submenuItems[0].path);
        else this.activeSubMenu = topLevelMenu;
      },

      setHover(label, value) {
        if (this.menuIsCollapsed) {
          this.$set(this.hover, label, value);
        }
      },

      isHover(label) {
        const f = this.hover[label];
        return f != undefined && f == true;
      },

      // Submenu methods
      openSubmenu(item) {
        this.$router.push(item.path);
      },

      submenuMenuItemClicked() {
        this.activeSubMenu = "";
      }
    },

    computed: {
      showContextMenu() {
        return this.activeSubMenu != "";
      },

      menuData() {
        return (
          this.router.options.routes
            // Filter the routes that are set to be displayed in the menu
            .filter(route => route.inMenu !== undefined && this.router.options.authenticate(route))
            .reduce((acc, elem) => {
              let menuItem = elem.inMenu;
              // Special handling for folders of multiple navigation
              if (menuItem.folder !== undefined) {
                // Have we encountered this folder before? If no, initialize the list of submenuItems for this folder
                const folder = acc.find(e => e.folder == menuItem.folder);
                if (folder == undefined) {
                  menuItem.submenuItems = [];
                  menuItem.name = menuItem.folder;
                  acc.push(menuItem);
                } else menuItem = folder;
                // Add the submenu to the folder
                menuItem.submenuItems.push({
                  name: this.getMenuItemName(elem),
                  path: elem.path
                });
              } else {
                // Simple link, just insert to the menu
                menuItem.name = this.getMenuItemName(elem);
                menuItem.path = elem.path;
                acc.push(menuItem);
              }
              return acc;
            }, [])
        );
      },

      // Dynamically enabled css classes
      getCollapseMenuIconClasses() {
        return {
          fa: true,
          menu__collapse_icon: true,
          "fa-angle-double-left": !this.menuIsCollapsed,
          "menu__collapse_icon__collapsed fa-angle-double-right": this.menuIsCollapsed
        };
      },

      getMenuGlobalStyle() {
        const width = this.menuIsCollapsed ? UI_CONSTANTS.COLLAPSED_MENU_WIDTH : UI_CONSTANTS.EXPANDED_MENU_WIDTH;
        return "width:" + width + "px";
      }
    },

    created() {
      this.menuIsCollapsed = sessionStorage.getItem("menuIsCollapsed") == "true" ? true : false;
    }
  };
</script>

<style scoped lang="scss">
  // -----------------------------------------------------------------------
  // Global config
  // -----------------------------------------------------------------------
  .menu {
    font-size: 15px;
    background: #31373d;
    transition: width 0.5s ease;
    color: white;
    float: left; // needed so that baseview go to the right of the menu (containers side-by-side)
    white-space: nowrap; // so that text does not break into two lines
    height: 100%;
    z-index: 5; // 5, because sticky-headers of tables have z-index: 2 and pagination of tables have 3, so we set baseview header to 4 and menu to 5
    flex-shrink: 0;
  }

  // -----------------------------------------------------------------------
  // 1ST LEVEL MENU CONFIG
  // -----------------------------------------------------------------------

  // TOP ROW (LOGO, TITLE, EXPAND / COLLAPSE BUTTON)
  .menu__logo {
    width: 26px;
    height: 26px;
    margin-left: -4px; // offset this icon a bit to the left since it is biggen than the other icons in the menu
    margin-right: 10px;
    cursor: default;
  }

  .menu__title {
    // Make title a bit bigger, just for emphasis
    font-size: 16px;
    font-weight: bold;
    cursor: default;
  }

  .menu__collapse_icon {
    cursor: pointer;
    padding-left: 13px;
  }

  .menu__collapse_icon__collapsed {
    color: #31373d;
    padding-left: 15px;
  }

  // Global config for each list/menu item (including first row, including submenus)
  ul {
    margin: 0;
    padding: 0;
    list-style: none; // Disables dots from list/menu items
    cursor: pointer;
  }

  ul li {
    height: 45px; // height of all children other than the first
    padding-left: 15px;
  }

  .menu__top_row {
    border-bottom: 1px solid grey;
    height: 60px; // Make first child a bit taller
  }

  ul li a,
  ul li div {
    height: inherit; // needed so that vertical-align takes effect
    vertical-align: middle;
    display: table-cell;
  }

  // Config for all items other than the first
  .menu__icon {
    padding-right: 10px;
    font-size: inherit;
  }

  .highlightedHoverableMenuItem {
    &:hover {
      background: #3c444b;
      width: 240px;
    }
  }

  // -----------------------------------------------------------------------
  // 2ND LEVEL MENU CONFIG
  // -----------------------------------------------------------------------
  .submenu__container {
    position: absolute;
    left: 48px;
    top: 0;
    background: lighten(#31373d, 10%);
    width: 192px;
    height: 100%;
  }

  .submenu__title {
    border-bottom: 1px solid grey;
    line-height: 60px;
    height: 60px;
    vertical-align: middle;
  }

  // Remove the irritating margin below h5
  h5 {
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block; // to have the close button next to the submenu top title
  }

  .submenu__btn-close {
    float: right;
    margin-right: 12px;
  }

  .submenu__link {
    font-size: 16px;
    background: transparent;
    vertical-align: middle;
    line-height: 45px;

    &:hover {
      background: #3c444b;
      cursor: pointer;
    }
  }

  // Submenu animation
  .slide-fade-enter-active {
    transition: all 0.2s ease;
  }

  .slide-fade-leave-active {
    transition: all 0.2s ease;
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translate3d(-30px, 0, 0);
    opacity: 0;
  }
</style>
