<template>
  <div class="timelineChartDivClass">
    <div v-if="this.items === undefined" style="height: 150px">
      <h3 style="text-align: center; padding-top: 50px; padding-bottom: 20px; color: #593b8a">
        No records found
      </h3>
    </div>
    <div
      class="text-center text-danger my-2"
      v-else-if="this.items.length == 0"
      style="height: 150px; padding-top:60px"
    >
      <b-spinner class="align-middle"></b-spinner>
      <strong>Loading...</strong>
    </div>
    <VueApexCharts
      v-else
      :height="this.series.length * 200"
      type="rangeBar"
      :options="options"
      :series="series"
    ></VueApexCharts>
  </div>
</template>


<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts
  },

  name: "TimelineChart",

  props: ["items"],

  data() {
    return {
      options: {
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        xaxis: {
          type: "datetime"
        }
      },
      series: [{ data: this.items }]
    };
  }
};
</script>

<style>
.timelineChartDivClass {
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-color: #ddd;
  padding: 15px;
}
</style>
