<template>
  <BaseView>
    <Table
      :key="rerenderTableKey"
      apiEndpoint="opportunities"
      :apiParams="{ status: opportunitiesStatusOptions }"
      stickyHeaderHeight="1125px"
      :fields="opportunitiesSchema"
      searchingEnabled
      @detailsClicked="navigateToOpportunityPage(...arguments)"
      :convertButtonEnabled="{
        convertToSchema: projectsSchema,
        copyFields: [
          ['name', 'name'],
          ['region', 'region'],
          ['manager_full_name', 'manager_full_name'],
          ['project_type', 'project_type'],
          ['opportunity_title', 'project_title'],
          ['opportunity_id', 'origin_opportunity_id'],
          ['opportunity_seq_num', 'opportunity_seq_num'],
        ],
        convertToApiEndpoint: 'projects',
        updateCurrRecord: (rec) => (rec['status'] = 'Qualified'),
      }"
      v-bind:detailsButtonEnabled="() => true"
      :sortTableByFn="(a, b) => (a['status'] == 'Qualified' || b['status'] == 'Qualified' ? -1 : 1)"
      @rerenderAfterGenericModal="rerenderAfterGenericModal"
    >
    </Table>
  </BaseView>
</template>

<script>
import BaseView from "@/views/BaseView.vue";
import Table from "@/components/charts/Table.vue";
import { TABLE_SCHEMAS, TABLE_ENUMS } from "@/config.js";

export default {
  name: "Opportunities",
  components: {
    BaseView,
    Table,
  },
  data() {
    return {
      opportunitiesSchema: TABLE_SCHEMAS[this.$options.name],
      opportunitiesStatusOptions: TABLE_ENUMS["OpportunitiesStatusOptions"],
      projectsSchema: TABLE_SCHEMAS["Projects"],
      rerenderTableKey: true,
    };
  },
  methods: {
    rerenderAfterGenericModal() {
      this.rerenderTableKey = !this.rerenderTableKey;
    },
    navigateToOpportunityPage(row) {
      this.$router.push("/opportunities/" + row.item["opportunity_id"]);
    },
  },
};
</script>
