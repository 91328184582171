import { currencyFormatter } from "./components/style_formatters/currencyFormatter.js";
import { phoneNumberFormatter } from "./components/style_formatters/phoneNumberFormatter.js";
import store from "./views/auth/store.js";
import { compareDates, isOnPage, isAdminUser, isIRorBallastAdmin } from "./utils/helpers.js";

export const UI_CONSTANTS = {
  EXPANDED_MENU_WIDTH: 240,
  COLLAPSED_MENU_WIDTH: 50,
  ENABLE_TABLE_PAGINATION_ROW_COUNT_LIMIT: 15,
};

export const VIRTUAL_ATTRIBUTES = {
  manager_full_name: {
    targetAttrKey: "project_manager_username",
    sourceAttrValue: "username",
    sourceAttrText: "full_name",
    endpoint: "users/fullnames",
    apiParams: { role: ["Administrator", "Project Manager"] },
  },
  name: { sourceAttrValue: "account_id", endpoint: "accounts/names" },
  system_title: {
    targetAttrKey: "bwms",
    sourceAttrValue: "system_id",
    sourceAttrText: "system_title",
    endpoint: "systems",
    apiParams: { is_approved: true },
  },
  product_name: {
    sourceAttrValue: "product_id",
    sourceAttrText: (rec) => {
      return rec["product_name"] + " - Standard Price: " + currencyFormatter("standard_price")(undefined, undefined, rec);
    },
    endpoint: "products",
  },
  account_id: {
    targetAttrKey: "account_id",
    sourceAttrValue: "account_id",
    sourceAttrText: "name",
    endpoint: "accounts/names",
  },
  component_type_name: {
    sourceAttrValue: "component_type_id",
    endpoint: "component-types",
    apiParams: { is_approved: true },
  },
  technology_group_name: {
    sourceAttrValue: "technology_group_id",
    endpoint: "technologies-group",
    apiParams: { is_approved: true },
  },
  class_type_approval_name: {
    sourceAttrValue: "class_type_approval_id",
    endpoint: "class-type-approvals",
    apiParams: { is_approved: true },
  },
  project_type: {
    sourceAttrValue: "project_type",
    endpoint: "project-types",
  },
  service_type: {
    sourceAttrValue: "service_type",
    endpoint: "service-types",
  },
  userrole: {
    endpoint: "userroles",
  },
  scanner_name: {
    endpoint: "scanners/names",
  },
  company_admin: {
    targetAttrKey: "company_admin",
    sourceAttrValue: "name",
    sourceAttrText: "name",
    endpoint: "accounts",
  },
  manufacturer_name: {
    targetAttrKey: "manufacturer_id",
    sourceAttrValue: "manufacturer_id",
    sourceAttrText: "name",
    endpoint: "manufacturers",
    apiParams: { is_approved: true },
  },
  component_id: {
    sourceAttrValue: "component_id",
    sourceAttrText: "model_name",
    endpoint: "components",
    apiParams: { is_approved: true },
  },
  filter_id: {
    sourceAttrValue: "filter_id",
    sourceAttrText: "model_name",
    endpoint: "filters",
    apiParams: { is_approved: true },
  },
  team_member_full_name: {
    targetAttrKey: "project_manager_username",
    sourceAttrValue: "username",
    sourceAttrText: "full_name",
    endpoint: "users/fullnames",
    apiParams: { role: ["Project Manager", "Employee"] },
  },
  extra_user_fullname: {
    targetAttrKey: "username",
    sourceAttrValue: "username",
    sourceAttrText: (rec) => {
      return rec["firstname"] + " " + rec["lastname"] + ", " + rec["email"];
    },
    endpoint: "users",
  },
};

export const TABLE_ENUMS = {
  LeadsStatusOptions: ["New", "Contacted", "Lost", "Cancelled"],
  OpportunitiesStatusOptions: ["New", "Lost", "Cancelled"],
};

export const TABLE_SCHEMAS = {
  Opportunities: [
    { key: "opportunity_id", primaryKey: true, hidden: true },
    { key: "opportunity_title", label: "Title", onAdd: true, fullLine: true },
    {
      key: "opportunity_description",
      label: "Opportunity Description",
      onAdd: true,
      hidden: true,
      textarea: true,
    },
    {
      key: "name",
      label: "Account",
      onAdd: true,
      onEdit: false,
      virtualAttr: true,
      isDropdown: true,
      addItemFn: (router) => router.push("/accounts"),
    },
    {
      key: "project_type",
      label: "Project Type",
      onAdd: true,
      isDropdown: true,
    },
    {
      key: "manager_full_name",
      label: "Project Manager",
      onAdd: true,
      virtualAttr: true,
      isDropdown: true,
    },
    {
      key: "estimated_revenue",
      label: "Estimated Revenue",
      formatter: currencyFormatter("estimated_revenue"),
      onAdd: true,
      type: "number",
    },
    {
      key: "currency",
      label: "Currency",
      hidden: true,
      onAdd: true,
      isDropdown: true,
      options: () => store.state.currencies,
    },
    { key: "validity_date", label: "Due Date", onAdd: true, format: "date" },
    {
      key: "region",
      label: "Region",
      onAdd: true,
      isDropdown: true,
      hidden: true,
      options: () => store.state.regions,
    },
    // Note that qualified is not in the options below for status, as a user shouldn't be able to add or edit a lead
    // with the qualified status (this only happens during conversions). Still for view, we should see the "Qualified"
    // as text
    {
      key: "status",
      label: "Status",
      onAdd: true,
      options: TABLE_ENUMS["OpportunitiesStatusOptions"],
      optionsView: TABLE_ENUMS["OpportunitiesStatusOptions"].concat(["Qualified"]),
    },
    { key: "date_created", label: "Date Created" },
  ],

  Leads: [
    { key: "lead_id", primaryKey: true, hidden: true },
    { key: "lead_name", label: "Name", onAdd: true, fullLine: true },
    {
      key: "lead_description",
      label: "Lead Description",
      onAdd: true,
      hidden: true,
      textarea: true,
    },
    {
      key: "name",
      label: "Account",
      onAdd: true,
      onEdit: false,
      virtualAttr: true,
      isDropdown: true,
      addItemFn: (router) => router.push("/accounts"),
    },
    { key: "email", label: "Account Email", type: "email" },
    {
      key: "manager_full_name",
      label: "Responsible",
      onAdd: true,
      virtualAttr: true,
      isDropdown: true,
    },
    {
      key: "region",
      label: "Region",
      onAdd: true,
      isDropdown: true,
      hidden: true,
      options: () => store.state.regions,
    },
    // Note that qualified is not in the options below for status, as a user shouldn't be able to add or edit a lead
    // with the qualified status (this only happens during conversions). Still for view, we should see the "Qualified"
    // as text
    {
      key: "status",
      label: "Status",
      onAdd: true,
      isDropdown: true,
      options: TABLE_ENUMS["LeadsStatusOptions"],
      optionsView: TABLE_ENUMS["LeadsStatusOptions"].concat(["Qualified"]),
    },
    { key: "date_created", label: "Date Created" },
  ],

  Projects: [
    { key: "project_id", primaryKey: true, hidden: true },
    { key: "project_title", label: "Project Title", onAdd: true, fullLine: () => isOnPage("projects") },
    {
      key: "name",
      label: "Account",
      onAdd: true,
      onEdit: false,
      virtualAttr: true,
      isDropdown: true,
      addItemFn: (router) => router.push("/accounts"),
    },
    {
      key: "project_description",
      label: "Project Description",
      onAdd: true,
      hidden: true,
      textarea: true,
    },
    {
      key: "project_type",
      label: "Project Type",
      onAdd: true,
      isDropdown: true,
    },
    {
      key: "manager_full_name",
      label: "Project Manager",
      onAdd: true,
      virtualAttr: true,
      isDropdown: true,
    },
    {
      key: "issued_amount",
      label: "Total Amount",
      type: "number",
      formatter: currencyFormatter("issued_amount"),
      onAdd: true,
      conditionalRendering: () => isAdminUser(),
    },
    {
      key: "currency",
      label: "Currency",
      hidden: true,
      onAdd: true,
      isDropdown: true,
      options: () => store.state.currencies,
      conditionalRendering: () => isAdminUser(),
    },
    { key: "validity_date", label: "Due Date", onAdd: true, format: "date" },
    {
      key: "region",
      label: "Region",
      onAdd: true,
      isDropdown: true,
      hidden: true,
      options: () => store.state.regions,
    },
    {
      key: "project_status",
      label: "Project Status",
      onAdd: true,
      options: ["Not Started", "In Progress", "Pending", "Done"],
    },
  ],

  /****************************************************
   * SERVICES AND SERVICE TYPES
   ****************************************************/
  Services: [
    { key: "service_type_id", primaryKey: true, hidden: true },
    { key: "service_type", label: "Service Name", onAdd: true, fullLine: true },
    {
      key: "service_type_description",
      label: "Service Description",
      onAdd: true,
      textarea: true,
    },
    {
      key: "service_type_quote_details",
      hidden: true,
      isTable: true,
      onEdit: true,
      tableApiEndpoint: "service-types-details",
      tableApiParams: (form) => {
        return { service_type_id: form["service_type_id"] };
      },
      copyApiParamsToBody: true,
      tableFields: [
        { key: "service_type_detail_id", primaryKey: true, hidden: true },
        {
          key: "service_type_detail_name",
          label: "Quote Part Name",
          onAdd: true,
          fullLine: true,
          isDropdown: true,
          options: ["Scope of Work", "Deliverables", "Work Details", "Assistance From The Crew", "Cost and Terms", "Assumptions", "Not included in Scope", "Timeframe"],
        },
        {
          key: "service_type_detail_description",
          label: "Quote Part Text",
          onAdd: true,
          richeditor: true,
          hidden: true,
        },
      ],
      tableName: "Quote Details",
      disableTableBorder: true,
    },
  ],

  ServicesRequested: [
    { key: "service_id", primaryKey: true, hidden: true },
    {
      key: "service_type",
      label: "Service Type",
      onAdd: true,
      isDropdown: true,
      virtualAttr: true,
    },
    {
      key: "service_status",
      label: "Service Status",
      onAdd: true,
      options: ["Not Started", "In Progress", "Pending", "Done"],
    },
    {
      key: "issued_amount",
      label: "Total Amount",
      formatter: currencyFormatter("issued_amount"),
      onAdd: true,
      type: "number",
    },
    {
      key: "currency",
      label: "Currency",
      onAdd: true,
      options: () => store.state.currencies,
      hidden: true,
    },
    { key: "start_date", label: "Start Date", onAdd: true, format: "date" },
    {
      key: "end_date",
      label: "End Date",
      onAdd: true,
      format: "date",
      validationFn: (form) => {
        if (compareDates(form["start_date"], form["end_date"]) > 0) {
          return "End date cannot be before the starting date";
        } else return "";
      },
    },
    { key: "datediff", label: "Duration (days)" },
    {
      key: "scanner_name",
      label: "Scanner Name",
      onAdd: true,
      hidden: true,
      isDropdown: true,
      conditionalRendering: (row) => row["service_type"] == "3D Scanning",
    },
    { key: "project_id", hidden: true, type: "number" },
    { key: "opportunity_id", hidden: true, type: "number" },
  ],

  /****************************************************
   * PRODUCTS AND PRODUCT TYPES
   ****************************************************/
  Products: [
    { key: "product_id", primaryKey: true, hidden: true },
    { key: "sku_serial", label: "SKU SERIAL", onAdd: true, type: "number" },
    { key: "product_name", label: "Product Name", onAdd: true },
    { key: "supplier", label: "Supplier", onAdd: true },
    { key: "unit", label: "Unit", onAdd: true },
    {
      key: "standard_price",
      label: "Standard Price",
      onAdd: true,
      formatter: currencyFormatter("standard_price"),
      type: "number",
    },
    {
      key: "currency",
      label: "Currency",
      hidden: true,
      onAdd: true,
      isDropdown: true,
      options: () => store.state.currencies,
    },
    {
      hidden: true,
      onEdit: true,
      componentName: "FileManager",
      componentParams: (form) => ({
        rootDir: "/products/" + form["product_id"] + "/",
        directoryUploadEnabled: false,
      }),
    },
  ],

  ProductsRequested: [
    { key: "product_requested_id", primaryKey: true, hidden: true },
    {
      key: "product_name",
      label: "Product",
      onAdd: true,
      isDropdown: true,
      virtualAttr: true,
      searchable: true,
    },
    { key: "qty", label: "QTY", onAdd: true, type: "number" },
    {
      key: "price_per_unit",
      label: "Price/Unit",
      formatter: currencyFormatter("price_per_unit"),
      onAdd: true,
      type: "number",
    },
    {
      key: "discount",
      label: "Discount (%)",
      onAdd: true,
      type: "number",
      min: 0,
      max: 100,
    },
    {
      key: "extended_amount",
      label: "Extended Amount",
      formatter: currencyFormatter("extended_amount"),
      type: "number",
    },
    {
      key: "currency",
      label: "Currency",
      hidden: true,
      onAdd: true,
      isDropdown: true,
      options: () => store.state.currencies,
    },
    {
      key: "paid_amount",
      label: "Paid Amount",
      formatter: currencyFormatter("paid_amount"),
      onAdd: true,
      type: "number",
    },
    { key: "project_id", hidden: true },
    { key: "opportunity_id", hidden: true },
  ],

  TeamMembers: [
    { key: "id", primaryKey: true, hidden: true },
    {
      key: "team_member_full_name",
      label: "Team Member",
      onAdd: true,
      virtualAttr: true,
      isDropdown: true,
    },
    { key: "date_from", label: "From", onAdd: true, format: "date" },
    {
      key: "date_to",
      label: "To",
      onAdd: true,
      format: "date",
      validationFn: (form) => {
        if (compareDates(form["date_from"], form["date_to"]) > 0) {
          return "To date cannot be before the From date";
        } else return "";
      },
    },
    {
      key: "required_hours",
      label: "Required Hours",
      onAdd: true,
      type: "number",
    },
  ],

  Systems: [
    { key: "system_id", primaryKey: true, hidden: true },
    { key: "system_title", label: "Title", onAdd: true },
    {
      key: "manufacturer_name",
      label: "Manufacturer",
      onAdd: true,
      virtualAttr: true,
      isDropdown: true,
      extraApiParams: { bwms_manufacturer: true },
    },
    {
      key: "tss_max",
      label: "TSS MAX (mg/l)",
      onAdd: true,
      hidden: true,
      type: "number",
      required: false,
    },
    {
      key: "stage_uscg_approval",
      label: "Stage of USCG Approval",
      onAdd: true,
      options: ["Ongoing", "Approved"],
    },
    {
      key: "uv_t_min_imo",
      label: "UV-T MIN IMO (%)",
      onAdd: true,
      hidden: true,
      type: "number",
      required: false,
    },
    {
      key: "uv_t_min_uscg",
      label: "UV-T MIN USCG (%)",
      onAdd: true,
      hidden: true,
      type: "number",
      required: false,
    },
    {
      key: "holding_time_imo",
      label: "Holding Time IMO (hours)",
      onAdd: true,
      type: "number",
      hidden: true,
      required: false,
    },
    {
      key: "holding_time_ucsg",
      label: "Holding Time UCSG (hours)",
      onAdd: true,
      type: "number",
      hidden: true,
      required: false,
    },
    {
      key: "active_substrances",
      label: "Active Substrances",
      onAdd: true,
      checkbox: true,
      explicitWidth: "col-6",
    },
    {
      key: "ams_designation",
      label: "AMS Designation",
      onAdd: true,
      checkbox: true,
      explicitWidth: "col-6",
    },
    {
      key: "explosion_proofing",
      label: "Explosion Proofing",
      onAdd: true,
      checkbox: true,
      explicitWidth: "col-6",
    },
    {
      key: "in_tank_voyage_treatment",
      label: "In-tank / In-voyage treatment",
      onAdd: true,
      hidden: true,
      checkbox: true,
      explicitWidth: "col-6",
    },
    {
      key: "alternative_weight_temperature_salinity",
      label: "Alternative weight for temp. / salinity",
      onAdd: true,
      hidden: true,
      checkbox: true,
      explicitWidth: "col-6",
    },
    {
      key: "apparatus_group",
      label: "Apparatus Group",
      onAdd: true,
      hidden: true,
      isDropdown: true,
      options: ["IIA", "IIB", "IIC"],
      conditionalRendering: (row) => row["explosion_proofing"] == true,
    },
    {
      key: "temperature_group",
      label: "Temperature Group",
      onAdd: true,
      hidden: true,
      isDropdown: true,
      options: ["T1", "T2", "T3", "T4", "T5", "T6"],
      default: "T3",
      conditionalRendering: (row) => row["explosion_proofing"] == true,
    },
    {
      key: "safety",
      label: "Safety",
      onAdd: true,
      options: ["Good", "Average", "Poor"],
    },
    {
      key: "corrosion",
      label: "Corrosion",
      onAdd: true,
      options: ["Good", "Average", "Poor"],
    },
    {
      key: "min_salinity",
      label: "Min Salinity (PSU)",
      onAdd: true,
      type: "number",
      hidden: true,
      required: false,
    },
    {
      key: "min_temp",
      label: "Min Temp (&#8451;)",
      onAdd: true,
      hidden: true,
      type: "number",
      required: false,
    },
    {
      key: "requires_stored_onboard_chemicals",
      label: "Required Chemicals",
      onAdd: true,
      options: ["", "Neutralising Agent", "Cleaning Agent", "Stored Biocide"],
      required: false,
    },
    {
      key: "is_active",
      label: "Is Active",
      onEdit: true,
      checkbox: true,
      explicitWidth: "col-6",
      noSearch: true,
      conditionalRendering: () => isAdminUser(),
    },
  ],

  Technologies: [
    { key: "technology_id", primaryKey: true, hidden: true },
    { key: "technology_name", label: "Name", onAdd: true, fullLine: true },
    {
      key: "technology_group_name",
      label: "Technology Group",
      virtualAttr: true,
      onAdd: true,
      isDropdown: true,
      fullLine: true,
    },
  ],

  TechnologiesGroup: [
    { key: "technology_group_id", primaryKey: true, hidden: true },
    {
      key: "technology_group_name",
      label: "Name",
      onAdd: true,
      fullLine: true,
    },
  ],

  Filters: [
    { key: "filter_id", primaryKey: true, hidden: true },
    { key: "model_name", label: "Filter Model", onAdd: true },
    {
      key: "manufacturer_name",
      label: "Manufacturer",
      onAdd: true,
      virtualAttr: true,
      isDropdown: true,
      extraApiParams: { component_manufacturer: true },
    },
    {
      key: "capacity_m3h_min",
      label: "Min Capacity (m<sup>3</sup>/h)",
      onAdd: true,
      type: "number",
      required: false,
    },
    {
      key: "capacity_m3h_max",
      label: "Max Capacity (m<sup>3</sup>/h)",
      onAdd: true,
      type: "number",
      required: false,
    },
    {
      key: "mesh_size_microm",
      label: "Mesh Size (μm)",
      onAdd: true,
      type: "number",
      required: false,
    },
  ],

  Models: [
    { key: "model_id", primaryKey: true, hidden: true },
    {
      key: "model_name",
      label: "BWMS Model Name",
      onAdd: true,
    },
    {
      key: "min_flow_capacity_m3_h",
      label: "Min Flow Capacity (m<sup>3</sup>/h)",
      onAdd: true,
      type: "number",
      required: false,
    },
    {
      key: "max_flow_capacity_m3_h",
      label: "Max Flow Capacity (m<sup>3</sup>/h)",
      onAdd: true,
      type: "number",
      required: false,
    },
    {
      key: "capex",
      label: "CAPEX",
      formatter: currencyFormatter("capex", "capex_opex_currency"),
      onAdd: true,
      type: "number",
      required: false,
    },
    {
      key: "opex",
      label: "OPEX",
      formatter: currencyFormatter("opex", "capex_opex_currency"),
      onAdd: true,
      type: "number",
      required: false,
    },
    {
      key: "capex_opex_currency",
      label: "CAPEX/OPEX Currency",
      hidden: true,
      onAdd: true,
      options: () => store.state.currencies,
      isDropdown: true,
    },
    {
      key: "footprint_m2",
      label: "Footprint (m<sup>2</sup>)",
      onAdd: true,
      type: "number",
      step: 0.01,
      required: false,
    },
    {
      key: "max_power_consumption_kwh",
      label: "Max Power Consumption (kWh)",
      onAdd: true,
      type: "number",
      required: false,
    },
    {
      key: "retrofit_experience",
      label: "Retrofit Experience",
      onAdd: true,
      type: "number",
      required: false,
    },
    {
      key: "newbuild_experience",
      label: "Newbuild Experience",
      onAdd: true,
      type: "number",
      required: false,
    },
    {
      key: "is_model_active",
      label: "Is Active",
      onEdit: true,
      checkbox: true,
      explicitWidth: "col-6",
      noSearch: true,
    },
  ],

  ClassSocieties: [
    { key: "class_type_approval_id", primaryKey: true, hidden: true },
    {
      key: "class_type_approval_name",
      label: "Name",
      onAdd: true,
      fullLine: true,
    },
  ],

  ImoApprovals: [
    { key: "system_imo_approvals_id", primaryKey: true, hidden: true },
    {
      key: "country",
      label: "Country",
      onAdd: true,
      fullLine: true,
      isDropdown: true,
      options: () => store.state.countries,
    },
    {
      key: "approval_status",
      label: "Approval Status",
      onAdd: true,
      options: ["Ongoing", "Approved", "Final", "Basic"],
      isDropdown: true,
    },
    {
      key: "approval_body",
      label: "Approval Body",
      onAdd: true,
      options: ["OLD G8", "2016 G8", "BWMS CODE"],
      isDropdown: true,
    },
  ],

  ComponentTypes: [
    { key: "component_type_id", primaryKey: true, hidden: true },
    {
      key: "type",
      label: "Type",
      onAdd: true,
      options: ["Minor", "Major"],
      isDropdown: true,
    },
    {
      key: "technology_group_name",
      label: "Technology Group",
      onAdd: true,
      virtualAttr: true,
      isDropdown: true,
    },
    { key: "component_type_name", label: "Name", onAdd: true, fullLine: true },
  ],

  Components: [
    { key: "component_id", primaryKey: true, hidden: true },
    {
      key: "component_type_name",
      label: "Component Type",
      onAdd: true,
      virtualAttr: true,
      isDropdown: true,
    },
    { key: "model_name", label: "Component Model", onAdd: true },
    {
      key: "manufacturer_name",
      label: "Manufacturer",
      onAdd: true,
      virtualAttr: true,
      isDropdown: true,
      extraApiParams: { component_manufacturer: true },
    },
    {
      key: "unit_cost",
      label: "Unit Cost",
      onAdd: true,
      type: "number",
      required: false,
      formatter: currencyFormatter("unit_cost", "unit_cost_currency"),
    },
    {
      key: "unit_cost_currency",
      label: "Currency",
      hidden: true,
      onAdd: true,
      isDropdown: true,
      options: () => store.state.currencies,
    },
    {
      key: "lifetime_expectancy",
      label: "Lifetime Expectancy (h)",
      onAdd: true,
      type: "number",
      required: false,
    },
  ],

  Manufacturers: [
    { key: "manufacturer_id", primaryKey: true, hidden: true },
    {
      key: "account_id",
      label: "Account",
      virtualAttr: true,
      isDropdown: true,
      hidden: true,
      onAdd: true,
    },
    { key: "name", label: "Name" },
    { key: "email", label: "Email", hidden: true },
    { key: "phone", label: "Phone Number", hidden: true },
    { key: "website", label: "Website", hidden: true },
    { key: "address", label: "Street Name", hidden: true },
    { key: "country", label: "Country", options: () => store.state.countries },
    {
      key: "brand_rating",
      label: "Brand Rating",
      onAdd: true,
      type: "number",
      options: ["Poor", "Average", "Good"],
    },
    {
      key: "global_reach",
      label: "Global Reach",
      onAdd: true,
      options: ["Poor", "Average", "Good"],
      explicitWidth: "col-5",
    },
    {
      key: "bwms_manufacturer",
      label: "BWMS Manufacturer",
      checkbox: true,
      onAdd: true,
      explicitWidth: "col-3",
    },
    {
      key: "component_manufacturer",
      label: "Component Manufacturer",
      checkbox: true,
      onAdd: true,
      explicitWidth: "col-4",
    },
  ],

  Ships: [
    { key: "ship_id", primaryKey: true, hidden: true },
    { key: "ship_name", label: "Ship Name", onAdd: true },
    {
      key: "ship_type",
      label: "Ship Type",
      onAdd: true,
      isDropdown: true,
      options: () => {
        return Object.keys(store.state.ship_types);
      },
    },
    {
      key: "name",
      label: "Account",
      onAdd: true,
      virtualAttr: true,
      isDropdown: true,
      required: false,
      conditionalRendering: () => isIRorBallastAdmin(),
      addItemFn: (router) => router.push("/accounts"),
    },
    { key: "imo_number", label: "IMO Number", onAdd: true, type: "number" },
    {
      key: "system_title",
      label: "BWMS",
      onAdd: true,
      isDropdown: true,
      virtualAttr: true,
      addItemFn: (router) => router.push("/systems/systems"),
    },
    {
      key: "total_ballast_pump_capacity",
      label: "Total Ballast Pump Capacity",
      type: "number",
    },
    {
      key: "class_type_approval_name",
      label: "Class Society",
      onAdd: true,
      virtualAttr: true,
      isDropdown: true,
    },
    {
      key: "bwds_compliance",
      label: "BWDS Compliance",
      onAdd: true,
      format: "date",
    },
    {
      key: "d2_compliance",
      label: "D2 Compliance",
      onAdd: true,
      format: "date",
    },
    {
      key: "pumps",
      hidden: true,
      isTable: true,
      onEdit: true,
      tableApiEndpoint: "ships/pumps",
      tableApiParams: (form) => {
        return { ship_id: form["ship_id"] };
      },
      copyApiParamsToBody: true,
      tableFields: [
        { key: "ship_pump_id", primaryKey: true, hidden: true },
        { key: "pump_name", label: "Pump Name", onAdd: true },
        {
          key: "pump_capacity",
          label: "Pump Capacity",
          onAdd: true,
          type: "number",
        },
        { key: "pump_location", label: "Pump Location", onAdd: true },
      ],
      tableName: "ship pumps",
      disableTableBorder: true,
    },
  ],

  Users: [
    { key: "username", primaryKey: true, hidden: true },
    {
      key: "firstname",
      label: "First Name",
      onAdd: true,
      pattern: "^([a-zA-Z]+\\s*)*[a-zA-Z]+$",
      onInvalidText: "Your first name can only include letters (small or capital) and single spaces between words",
    },
    {
      key: "lastname",
      label: "Last Name",
      onAdd: true,
      pattern: "^([a-zA-Z]+\\s*)*[a-zA-Z]+$",
      onInvalidText: "Your last name can only include letters (small or capital) and single spaces between words",
    },
    {
      key: "phone",
      label: "Phone",
      type: "tel",
      formatter: phoneNumberFormatter("phone", "Country Alpha2 Code"),
      onAdd: true,
      pattern: "(?:00|\\+)[0-9]{4,15}",
      onInvalidText: "Phone number can only have from 4 to 15 phone digits, starting by a single + or 00",
    },
    { key: "email", label: "Email", onAdd: true, type: "email" },
    {
      key: "country",
      label: "Country",
      onAdd: true,
      isDropdown: true,
      options: () => store.state.countries,
    },
    {
      key: "userrole",
      label: "Role",
      onAdd: true,
      isDropdown: true,
      conditionalRendering: () => !isOnPage("profile"),
    },
    {
      key: "company_user",
      label: "Company (Provided by User)",
      hidden: true,
      onEdit: true,
      conditionalRendering: () => isOnPage("users"),
    },
    {
      key: "company_user",
      label: "Company",
      hidden: true,
      onEdit: true,
      conditionalRendering: () => isOnPage("profile"),
    },
    {
      key: "company_admin",
      label: "Company",
      onAdd: true,
      virtualAttr: true,
      isDropdown: true,
      conditionalRendering: () => isOnPage("users"),
    },
    { key: "date_of_registration", label: "Date of Registration" },
    {
      key: "url",
      type: "url",
      label: "URL",
      hidden: true,
      required: false,
      onAdd: true,
      conditionalRendering: () => !isOnPage("internal-resources/employees"),
    },
    {
      key: "subscription",
      label: "Subscription",
      onAdd: true,
      hidden: true,
      isDropdown: true,
      options: ["Free", "Pro", "Ultimate"],
      default: "Free",
    },
    {
      key: "subscription_last_validity_date",
      label: "Subscription Last Validity Date",
      onAdd: true,
      hidden: true,
      format: "date",
      required: false,
    },
    {
      key: "manages_ships",
      label: "Manages Ships?",
      onAdd: true,
      hidden: true,
      checkbox: true,
    },
  ],

  MCQSC: [
    { key: "mcqsc_id", hidden: true, primaryKey: true },
    { key: "mcqsc_name", label: "MCQSC", onAdd: true },
    { key: "num_users", label: "# Users" },
    {
      key: "requirements_years",
      label: "Required Years",
      onAdd: true,
      type: "number",
    },
    {
      key: "description",
      label: "Description",
      onAdd: true,
      hidden: true,
      textarea: true,
    },
    { key: "date_created", label: "Date Created" },
    {
      key: "requirements_other",
      label: "Other requirements",
      onAdd: true,
      hidden: true,
      textarea: true,
    },
    {
      key: "mcqsc_users",
      label: "Users of this MCQSC",
      hidden: true,
      onAdd: true,
      componentName: "TableWithCheckbox",
      componentParams: (form) => ({
        apiEndpoint: "mcqsc-users",
        apiParamsFn: { role: ["Employee"], mcqsc_name: form["mcqsc_name"] },
        fields: [
          { key: "full_name", label: "Full Name" },
          { key: "count", label: "Has MCQSC" },
        ],
        tablePrimaryKey: "username",
      }),
    },
  ],

  Scanners: [
    { key: "scanner_id", primaryKey: true, hidden: true },
    { key: "scanner_name", label: "Name", onAdd: true },
    { key: "model", label: "Model", onAdd: true, isDropdown: false },
    {
      key: "serial_number",
      label: "Serial Number",
      onAdd: true,
      fullLine: true,
    },
    {
      key: "description",
      label: "Scanner Description",
      textarea: true,
      hidden: true,
      onAdd: true,
    },
    {
      key: "official_location",
      label: "Official Location",
      onAdd: true,
    },
    { key: "current_location", label: "Current Location", onAdd: true },
    {
      key: "last_calibration_date",
      label: "Last Calibration Date",
      onAdd: true,
      format: "date",
    },
    {
      key: "next_calibration_date",
      label: "Next Calibration Date",
      onAdd: true,
      format: "date",
      validationFn: (form) => {
        if (compareDates(form["last_calibration_date"], form["next_calibration_date"]) > 0) {
          return "Next Calibration cannot be before Last Calibration date";
        } else return "";
      },
    },
  ],

  Accounts: [
    { key: "account_id", primaryKey: true },
    { key: "name", label: "Account Name", onAdd: true },
    {
      key: "email",
      label: "Account Email",
      onAdd: true,
      type: "email",
      required: false,
    },
    {
      key: "address_street",
      label: "Address Street",
      hidden: true,
      onAdd: true,
      fullLine: true,
      required: false,
    },
    {
      key: "address_city",
      label: "Address City",
      hidden: true,
      onAdd: true,
      required: false,
    },
    {
      key: "address_state",
      label: "Address State",
      hidden: true,
      onAdd: true,
      required: false,
    },
    {
      key: "address_postalcode",
      label: "Address Postal Code",
      hidden: true,
      onAdd: true,
      required: false,
    },
    {
      key: "country",
      label: "Primary Office Country",
      onAdd: true,
      options: () => store.state.countries,
      isDropdown: true,
    },
    {
      key: "website",
      label: "Website",
      onAdd: true,
      hidden: true,
      required: false,
    },
    {
      key: "phone",
      label: "Account Phone Number",
      formatter: phoneNumberFormatter("phone"),
      onAdd: true,
    },
    {
      key: "extra_users",
      hidden: true,
      isTable: true,
      onEdit: true,
      tableApiEndpoint: "accounts-extra-users",
      tableApiParams: (form) => {
        return { account_id: form["account_id"] };
      },
      copyApiParamsToBody: true,
      tableFields: [
        { key: "extra_user_id", primaryKey: true, hidden: true },
        {
          key: "extra_user_fullname",
          label: "Contact Person",
          explicitWidth: "col-9",
          hidden: true,
          onAdd: true,
          virtualAttr: true,
          isDropdown: true,
          addItemFn: (router) => router.push("/users"),
        },
        { key: "full_name", label: "Name" },
        { key: "email", label: "Email" },
        {
          key: "is_main_contact",
          onAdd: true,
          label: "Main Contact",
          checkbox: true,
          explicitWidth: "col-3",
        },
      ],
      tableBooleanColumns: ["is_main_contact"],
      tableName: "contact person",
      disableTableBorder: true,
    },
  ],

  Links: [
    { key: "link_id", primaryKey: true, hidden: true },
    { key: "link_name", label: "Link Name", onAdd: true, hidden: true },
    { key: "link_url", label: "Link URL", onAdd: true },
    { key: "project_id", hidden: true },
    { key: "opportunity_id", hidden: true },
  ],

  Ports: [
    { key: "port_id", primaryKey: true, hidden: true },
    { key: "port_name", label: "Name", onAdd: true },
    {
      key: "country",
      label: "Country",
      onAdd: true,
      isDropdown: true,
      options: () => store.state.countries,
    },
    {
      key: "latitude",
      label: "Latitude",
      onAdd: true,
      type: "number",
      step: 0.01,
      required: false,
      hidden: true,
    },
    {
      key: "longtitude",
      label: "Longtitude",
      onAdd: true,
      type: "number",
      step: 0.01,
      required: false,
      hidden: true,
    },
    {
      key: "min_uvt_prct",
      label: "Min UVT(%)",
      onAdd: true,
      type: "number",
      min: 0,
      max: 100,
      step: 0.01,
      required: false,
    },
    {
      key: "max_uvt_prct",
      label: "Max UVT(%)",
      onAdd: true,
      hidden: true,
      type: "number",
      min: 0,
      max: 100,
      step: 0.01,
      required: false,
    },
    {
      key: "min_temperature_c",
      label: "Min Temperature (c)",
      onAdd: true,
      type: "number",
      step: 0.01,
      required: false,
    },
    {
      key: "max_temperature_c",
      label: "Max Temperature (c)",
      onAdd: true,
      hidden: true,
      type: "number",
      step: 0.01,
      required: false,
    },
    {
      key: "min_salinity_psu",
      label: "Min Salinity (PSU)",
      onAdd: true,
      type: "number",
      step: 0.01,
      required: false,
    },
    {
      key: "max_salinity_psu",
      label: "Max Salinity (PSU)",
      onAdd: true,
      hidden: true,
      type: "number",
      step: 0.01,
      required: false,
    },
    {
      key: "min_tss_mg_l",
      label: "Min TSS (mg/l)",
      onAdd: true,
      hidden: true,
      type: "number",
      step: 0.01,
      required: false,
    },
    {
      key: "max_tss_mg_l",
      label: "Max TSS (mg/l)",
      onAdd: true,
      hidden: true,
      type: "number",
      step: 0.01,
      required: false,
    },
    {
      key: "min_doc_mg_l",
      label: "Min DOC (mg/l)",
      onAdd: true,
      hidden: true,
      type: "number",
      step: 0.01,
      required: false,
    },
    {
      key: "max_doc_mg_l",
      label: "Max DOC (mg/l)",
      onAdd: true,
      hidden: true,
      type: "number",
      step: 0.01,
      required: false,
    },
    {
      key: "min_zooplankton_um",
      label: "Min Zooplankton (um)",
      onAdd: true,
      hidden: true,
      type: "number",
      step: 0.01,
      required: false,
    },
    {
      key: "max_zooplankton_um",
      label: "Max Zooplankton (um)",
      onAdd: true,
      hidden: true,
      type: "number",
      step: 0.01,
      required: false,
    },
    {
      key: "min_phytoplankton_um",
      label: "Min Phytoplankton (um)",
      onAdd: true,
      hidden: true,
      type: "number",
      step: 0.01,
      required: false,
    },
    {
      key: "max_phytoplankton_um",
      label: "Max Phytoplankton (um)",
      onAdd: true,
      hidden: true,
      type: "number",
      step: 0.01,
      required: false,
    },
  ],

  ProjectTypes: [
    {
      key: "project_type",
      label: "Project Type",
      onAdd: true,
      fullLine: true,
      primaryKey: true,
    },
    {
      key: "project_type_description",
      label: "Project Type Description",
      onAdd: true,
      textarea: true,
    },
  ],
};

export const APPROVE_BUTTON_FIELD = {
  key: "is_approved",
  label: "",
  noSearch: true,
  type: "button",
  checkbox: true,
  explicitWidth: "col-2",
};
