<template>
  <div>
    <b-button v-b-toggle="kebabCase(label)" variant="primary" class="custom-btn">
      <i class="fa fa-chevron-circle-down space-after-down-arrow" aria-hidden="true"></i>
      {{ label.toUpperCase() }}
    </b-button>
    <b-collapse v-model="isVisible" :id="kebabCase(label)" class="mt-2">
      <slot />
    </b-collapse>
  </div>
</template>

<script>
import kebabCase from "lodash/kebabCase";

export default {
  name: "Collapsable",

  props: {
    label: String,
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isVisible: this.visible,
    };
  },

  methods: {
    kebabCase,
  },
};
</script>

<style>
.custom-btn {
  text-align: left !important;
  color: #593b8a !important;
  border: 0px !important;
  font-weight: bold;
  background-color: transparent !important;
  padding-left: 3px;
  padding-top: 0px;
}
.space-after-down-arrow {
  padding-right: 20px;
}
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
.btn-primary:hover {
  background-color: transparent !important;
}
</style>
