import axios from "axios";
import { getApiPath } from "./helpers.js";

export function prepareEndpointCallURL(
  endpoint,
  params = {},
  httpMethod = "GET",
  body = {},
  responseType = null,
  contentType = "application/json"
) {
  const path = getApiPath(endpoint);
  var config = {
    params: formatParams(params),
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("user-token"),
      "Cache-Control": "no-cache", // DISABLE CACHE IN GET REQUESTS!
      "Content-Type": contentType,
    },
    responseType: responseType,
  };

  switch (httpMethod) {
    case "GET":
      return axios.get(path, config);
    case "PUT":
      return axios.put(path, body, config);
    case "POST":
      return axios.post(path, body, config);
    case "DELETE":
      return axios.delete(path, config);
  }
}

export function callEndpoint(
  endpoint,
  callback,
  params = {},
  httpMethod = "GET",
  body = {},
  onError = null,
  responseType = null,
  contentType = "application/json"
) {
  const call = prepareEndpointCallURL(
    endpoint,
    params,
    httpMethod,
    body,
    responseType,
    contentType
  );
  call
    .then((res) => {
      callback(res.data);
    })
    .catch((error) => {
      if (onError != null) onError(error);
    });
}

export function callAllEndpoints(endpoints, params, callback) {
  const endpointCalls = endpoints.map((e) =>
    prepareEndpointCallURL(e, params[e]).catch(() => {
      // In case one of the promises fail, we return an empty data dictionary
      const obj = { data: [] };
      return obj;
    })
  );
  axios.all(endpointCalls).then(axios.spread(callback));
}

export function formatParams(paramsDict) {
  const params = new URLSearchParams();
  for (var key in paramsDict) {
    params.append(key, paramsDict[key]);
  }
  return params;
}
