<template>
  <div>
    <b-modal
      :id="this.$options.name"
      hide-footer
      centered
      no-stacking
      no-close-on-backdrop
      size="lg"
      header-class="forget-password-header-class"
      footer-class="forget-password-footer-class"
      body-class="forget-password-body-class"
    >
      <div class="forgot-password-label forget-password-root-div-conf" style="padding-top: 65px;">
        <h2>{{ msg }}</h2>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { callEndpoint } from "./../utils/apicall.js";

export default {
  name: "AccountActivationModal",

  props: {
    token: String,
    token_type: String
  },

  data() {
    return {
      msg: ""
    };
  },

  mounted() {
    if (this.token != undefined && this.token != "" && this.token_type == "a") {
      callEndpoint(
        "activate-account",
        () => {
          this.msg = "Your account has been successfully activated";
        },
        {},
        "POST",
        {
          token: this.token
        },
        // TODO: Error handling
        err => {
          // eslint-disable-next-line
          console.log(err);
          this.msg = err.response.data["error"] + " Please contact support to obtain a new account activation token";
        }
      );
    }
  }
};
</script>

<style>
.forgot-password-label {
  color: #593b8a;
  text-align: center;
  padding-bottom: 10px;
}
.forget-password-footer-class {
  border-top: 0px solid white !important;
  display: inline-block;
  text-align: center;
}
.forget-password-body-class {
  padding-bottom: 0px !important;
}
.forget-password-root-div-conf {
  height: 250px !important;
  max-height: 250px !important;
  text-align: center;
}
.forget-password-header-class {
  border-bottom: 0px solid white !important;
}
</style>
