<template>
  <BaseView>
    <Table
      :key="rerenderTableKey"
      apiEndpoint="systems"
      stickyHeaderHeight="1125px"
      :fields="systemsSchema"
      @detailsClicked="navigateToSystemsPage(...arguments)"
      searchingEnabled
      v-bind:addButtonEnabled="
        () => {
          return isUserAdminBallastAdminManufacturer();
        }
      "
      v-bind:editButtonEnabled="
        (row) => {
          return isUserAdminBallastAdmin() || (row !== undefined && isUsersRecord(row));
        }
      "
      v-bind:deleteButtonEnabled="
        (row) => {
          return isUserAdminBallastAdmin() || (row !== undefined && isUsersRecord(row));
        }
      "
      v-bind:detailsButtonEnabled="() => true"
      :booleanColumns="['active_substrances', 'ams_designation', 'explosion_proofing']"
      @rerenderAfterGenericModal="rerenderTableKey = !rerenderTableKey"
      :templates="['is_active']"
    >
      <template v-slot:is_active="{ data }">
        <!-- https://emojiguide.org/green-circle -->
        <div v-if="data.item['is_active']" style="text-align: center">&#128994;</div>
        <div v-else style="text-align: center">&#9899;</div>
      </template>
    </Table>
  </BaseView>
</template>

<script>
import BaseView from "./../BaseView.vue";
import Table from "@/components/charts/Table.vue";
import { TABLE_SCHEMAS, APPROVE_BUTTON_FIELD } from "./../../config.js";
import { isUserAdminBallastAdminManufacturer, isUserAdminBallastAdmin, isUsersRecord } from "./../../utils/helpers.js";

export default {
  name: "Systems",
  components: {
    BaseView,
    Table,
  },
  data() {
    return {
      systemsSchema: TABLE_SCHEMAS[this.$options.name].concat([APPROVE_BUTTON_FIELD]),
      rerenderTableKey: true,
    };
  },
  methods: {
    isUserAdminBallastAdminManufacturer,
    isUserAdminBallastAdmin,
    isUsersRecord,
    navigateToSystemsPage(row) {
      this.$router.push("/systems/" + row.item["system_id"]);
    },
  },
};
</script>
