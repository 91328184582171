import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import TableWithCheckbox from "./components/charts/TableWithCheckbox.vue";
import VueRecaptcha from "vue-recaptcha";
import FileManager from "./components/file_manager/FileManager.vue";

Vue.config.productionTip = false;

// Register components globally here
Vue.component("TableWithCheckbox", TableWithCheckbox);
Vue.component("VueRecaptcha", VueRecaptcha);
Vue.component("FileManager", FileManager);

// Bus to communicate various events on dynamic components
var componentBus = new Vue();
export default componentBus;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
