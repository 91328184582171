import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
Vue.use(Vuex);
import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
} from "./constants.js";
import createPersistedState from "vuex-persistedstate";
import { getApiPath } from "./../../utils/helpers.js";

const store = new Vuex.Store({
  state: {
    status: "",
    ship_types: {},
  },

  mutations: {
    [AUTH_REQUEST]: (state) => {
      state.status = "loading";
    },
    [AUTH_SUCCESS]: (state, resp) => {
      state.status = "success";
      state.token = resp.token;
    },
    [AUTH_ERROR]: (state, err) => {
      state.status = "error";
      state.error_code = err.response.status;
    },
    [AUTH_LOGOUT]: (state) => {
      state.token = "";
    },
  },

  actions: {
    [AUTH_REQUEST]: ({ commit }, user) => {
      return new Promise((resolve, reject) => {
        commit(AUTH_REQUEST);

        const path = getApiPath("login");
        axios({ url: path, auth: user, method: "POST" })
          .then((resp) => {
            sessionStorage.setItem("user-token", resp.data["token"]);
            sessionStorage.setItem("user-role", resp.data["userrole"]);
            sessionStorage.setItem("user-name", user["username"]);
            sessionStorage.setItem(
              "user-manages-ships",
              resp.data["managesShips"]
            );
            sessionStorage.setItem("subscription", resp.data["subscription"]);
            commit(AUTH_SUCCESS, resp);
            resolve(resp);
          })
          .catch((err) => {
            commit(AUTH_ERROR, err);
            sessionStorage.removeItem("user-token");
            reject(err);
          });
      });
    },
    [AUTH_LOGOUT]: ({ commit }) => {
      return new Promise((resolve) => {
        commit(AUTH_LOGOUT);
        sessionStorage.removeItem("user-token");
        resolve();
      });
    },
  },

  plugins: [createPersistedState()],
});

export default store;
