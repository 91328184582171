<template>
  <BaseView>
    <Table
      :key="rerenderTableKey"
      apiEndpoint="component-types"
      stickyHeaderHeight="1125px"
      :fields="componentTypesSchema"
      v-bind:addButtonEnabled="
        () => {
          return isUserAdminBallastAdminManufacturer();
        }
      "
      v-bind:editButtonEnabled="
        (row) => {
          return isUserAdminBallastAdmin() || (row !== undefined && isUsersRecord(row));
        }
      "
      v-bind:deleteButtonEnabled="
        (row) => {
          return isUserAdminBallastAdmin() || (row !== undefined && isUsersRecord(row));
        }
      "
      v-bind:viewButtonEnabled="() => false"
      searchingEnabled
      @rerenderAfterGenericModal="rerenderAfterGenericModal"
      sortTableByColumn="technology_group_name"
    >
    </Table>
  </BaseView>
</template>

<script>
/* @ is a shortcut for ./src */
import BaseView from "./../BaseView.vue";
import Table from "@/components/charts/Table.vue";
import { currencyFormatter } from "@/components/style_formatters/currencyFormatter.js";
import store from "./../../views/auth/store.js";
import { TABLE_SCHEMAS, APPROVE_BUTTON_FIELD } from "./../../config.js";
import { isUserAdminBallastAdminManufacturer, isUserAdminBallastAdmin, isUsersRecord } from "./../../utils/helpers.js";

export default {
  name: "ComponentTypes",
  components: {
    BaseView,
    Table,
  },
  data() {
    return {
      store,
      rerenderTableKey: false,
      componentTypesSchema: TABLE_SCHEMAS[this.$options.name].concat([APPROVE_BUTTON_FIELD]),
    };
  },
  methods: {
    isUserAdminBallastAdminManufacturer,
    isUserAdminBallastAdmin,
    isUsersRecord,
    currencyFormatter,
    rerenderAfterGenericModal() {
      this.rerenderTableKey = !this.rerenderTableKey;
    },
  },
};
</script>
