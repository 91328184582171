<template>
  <BaseView>
    <Table
      :key="rerenderTableKey"
      apiEndpoint="project-types"
      stickyHeaderHeight="1125px"
      :fields="projectTypesSchema"
      searchingEnabled
      @rerenderAfterGenericModal="rerenderAfterGenericModal"
    >
    </Table>
  </BaseView>
</template>

<script>
import BaseView from "@/views/BaseView.vue";
import Table from "@/components/charts/Table.vue";
import { TABLE_SCHEMAS } from "@/config.js";

export default {
  name: "ProjectTypes",
  components: {
    BaseView,
    Table,
  },
  data() {
    return {
      rerenderTableKey: true,
      projectTypesSchema: TABLE_SCHEMAS[this.$options.name],
    };
  },
  methods: {
    rerenderAfterGenericModal() {
      this.rerenderTableKey = !this.rerenderTableKey;
    },
  },
};
</script>
