<template>
  <div :class="getTableClasses">
    <!-- Modals for various operations -->
    <GenericModal
      :ref="'modal_addview_' + this.getName"
      :fields="modalFields('ADD')"
      @rerenderAfterGenericModal="refreshTable()"
      v-if="addButtonEnabled() || this.calculatedFields.some((field) => ['add', 'view'].includes(field.key))"
      :withWideModal="withWideModal"
    />
    <GenericModal
      :ref="'modal_edit_' + this.getName"
      :fields="modalFields('EDIT')"
      @rerenderAfterGenericModal="refreshTable()"
      v-if="addButtonEnabled() || this.calculatedFields.some((field) => field.key === 'edit')"
      :withWideModal="withWideModal"
    />
    <GenericModal
      :ref="'modal_convert_' + this.getName"
      :fields="modalFields('CONVERT')"
      @rerenderAfterGenericModal="refreshTable()"
      v-if="convertButtonEnabled"
      :withWideModal="withWideModal"
    />
    <GenericModal
      :ref="'modal_select_' + this.getName"
      :fields="modalFields('SELECT')"
      @rerenderAfterGenericModal="refreshTable()"
      v-if="selectButtonEnabled()"
      :withWideModal="withWideModal"
    />

    <!-- Buttons for various operations -->
    <div style="float: left">
      <b-pagination
        v-if="enablePagination"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="rowsPerPage"
      ></b-pagination>
    </div>

    <div style="float: right; padding-bottom: 20px">
      <b-button
        v-if="displayMsg && !disableDisplayMsg"
        :variant="displayMsgVariant"
        style="display: inline-block; margin-right: 30px"
      >
        {{ this.displayMsgText }}
      </b-button>

      <b-button
        variant="outline-info"
        @click.prevent="showGenericModal(undefined, 'SELECT')"
        style="float: right"
        v-if="selectButtonEnabled()"
      >
        {{ "SELECT " + this.formatString(this.getName) }}
      </b-button>
      <b-button
        variant="outline-info"
        @click.prevent="showGenericModal(undefined, 'ADD')"
        style="float: right"
        v-if="addButtonEnabled()"
      >
        {{ "ADD NEW " + this.formatString(this.getName) }}
      </b-button>
    </div>

    <!-- Style change 26-04-2020 -->
    <!-- outlined, removed as prop below -->
    <b-table
      :items="searchingEnabled ? filtered : items"
      :fields="viewableCols"
      :busy="isBusy"
      @refresh="refreshTable()"
      @row-clicked="rowClickedHandler"
      responsive
      striped
      hover
      show-empty
      sort-icon-left
      :sticky-header="stickyHeaderHeight"
      head-variant="light"
      no-border-collapse
      :per-page="rowsPerPage"
      :current-page="currentPage"
    >
      <!-- Convert tables marked for special handling -->
      <template
        v-slot:[`cell(${colName})`]="row"
        v-for="colName in [
          ...booleanColumns,
          ...Object.keys(urlColumns),
          ...emailColumns,
          ...dropdownColumns,
          ...multilineColumns,
          ...templates,
        ]"
      >
        <!-- Converts boolean to checkmarks (and leave empty otherwise) - -->
        <div v-if="booleanColumns.includes(colName)" :key="colName">
          <div v-if="row.item[colName] == 1">&#10004;</div>
        </div>
        <!-- Convert text urls to functional and clickable urls -->
        <div v-else-if="Object.keys(urlColumns).includes(colName)" :key="colName">
          <b-link :href="row.item[colName]">{{ row.item[urlColumns[colName]] }}</b-link>
        </div>
        <!-- Convert text email to functional and clickable mailto -->
        <div v-else-if="emailColumns.includes(colName)" :key="colName">
          <a :href="'mailto:' + row.item[colName]">{{ row.item[colName] }}</a>
        </div>
        <!-- Convert text dropdown to dropdown-->
        <div v-else-if="dropdownsEnabled && dropdownColumns.includes(colName)" :key="colName">
          <b-form-select :options="['Approved', 'Ongoing']" v-model="row.item[colName]" />
        </div>
        <!-- Convert text to multiline text -->
        <div v-else-if="multilineColumns.includes(colName)" :key="colName">
          <div style="white-space: pre">{{ row.item[colName] ? row.item[colName].split(",").join("\n") : "" }}</div>
        </div>
        <!-- Else let the caller deside. He has the power! -->
        <div v-else :key="colName">
          <slot :name="colName" :data="row"></slot>
        </div>
      </template>

      <!-- Special formatting for headers -->
      <template v-slot:head()="data">
        <span v-html="data.label"></span>
      </template>

      <template v-slot:cell(details)="row">
        <b-button @click="$emit('detailsClicked', row)" v-b-tooltip.hover.topright="'Details'" size="sm">
          <i class="fa fa-ellipsis-v fa-fw" aria-hidden="true"></i>
        </b-button>
      </template>

      <template v-slot:cell(rowDetails)="row">
        <b-button @click="row.toggleDetails" v-b-tooltip.hover.topright="'Show Models'" size="sm">
          <i class="fa fa-arrow-down fa-fw" aria-hidden="true"></i>
        </b-button>
      </template>

      <!-- Edit -->
      <template v-slot:cell(edit)="row">
        <b-button
          @click.prevent="showGenericModal(row, 'EDIT')"
          v-b-tooltip.hover.topright="'Edit'"
          size="sm"
          v-if="editButtonEnabled(row.item)"
        >
          <i class="fa fa-edit fa-fw" aria-hidden="true"></i>
        </b-button>
      </template>

      <!-- View -->
      <template v-slot:cell(view)="row">
        <b-button
          @click.prevent="showGenericModal(row, 'VIEW')"
          v-b-tooltip.hover.topright="'View'"
          size="sm"
          v-if="viewButtonEnabled(row.item)"
        >
          <i class="fa fa-eye fa-fw" aria-hidden="true"></i>
        </b-button>
      </template>

      <template v-slot:cell(convert)="row">
        <b-button @click.prevent="showGenericModal(row, 'CONVERT')" v-b-tooltip.hover.topright="'Convert'" size="sm">
          <i class="fas fa-level-up-alt fa-fw" aria-hidden="true"></i>
        </b-button>
      </template>

      <template v-slot:cell(delete)="row">
        <b-button
          @click.prevent="showConfirmDeleteModal(row)"
          v-b-tooltip.hover.topright="'Delete'"
          size="sm"
          v-if="deleteButtonEnabled(row.item)"
        >
          <i class="fas fa-trash-alt fa-fw" aria-hidden="true"></i>
        </b-button>
      </template>

      <!-- The approve button is special: for admins, it allows to change the is_approve column, and for 
      non-admins, it is just displayed in disable state (to show if an entry is approved or not) -->
      <template v-slot:cell(is_approved)="row">
        <b-button
          variant="link"
          :style="getApproveButtonStyle(row.item.is_approved)"
          v-on:click="approveSystemComponent(row)"
          v-bind:disabled="!isAdminUser()"
          v-b-tooltip.hover.topright="'Approved for use'"
        >
          <span class="fa-stack">
            <i class="far fa-circle fa-stack-1x" style="font-size: 32px"></i>
            <i class="fas fa-check" />
          </span>
        </b-button>
      </template>

      <!-- Busy state of the table while loading -->
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <!-- Show no data found text when no records are returned from API -->
      <template v-slot:empty>
        <h5 style="text-align: center; padding-top: 15px; padding-bottom: 0px; color: #593b8a">No records found</h5>
      </template>

      <!-- Search bar as top row -->
      <template
        slot="top-row"
        slot-scope="{ fields }"
        v-if="searchingEnabled && (filtered.length > 0 || hasTextInTextFields)"
      >
        <td v-for="field in fields" :key="field.key">
          <b-form-input
            v-model="filters[field.key]"
            :placeholder="stripHtmlTags(field.label)"
            v-if="
              (field.noSearch == undefined || field.noSearch == false) &&
              field.key != 'edit' &&
              field.key != 'view' &&
              field.key != 'convert' &&
              field.key != 'delete' &&
              field.key != 'details' &&
              field.key != 'rowDetails'
            "
          />
        </td>
      </template>

      <!-- Row details (used e.g. by bwms finder) -->
      <!-- TODO: This should be a Table object like above -->
      <template #row-details="row">
        <b-table :items="row.item.models" :fields="detailsRowModel">
          <template v-slot:head()="data">
            <span v-html="data.label"></span>
          </template>
        </b-table>
      </template>
    </b-table>
  </div>
</template>

<script>
import { callEndpoint } from "@/utils/apicall.js";
import { UI_CONSTANTS } from "@/config.js";
import { isAdminUser, formatString, isUsersRecord } from "@/utils/helpers.js";
import GenericModal from "@/modals/GenericModal.vue";

export default {
  name: "Table",

  props: {
    /*
     * All our tables are initialized by specifing an apiEndpoint and apiParams (optional)
     * in which case the data is retreived from the API call to the given endpoint
     */
    apiEndpoint: String,
    apiParams: {},
    /* Props to customize which columns, with what labels and in what order */
    fields: {},
    /* Props to customize various functionality of the table */
    detailsButtonEnabled: {
      type: Function,
      default: () => {
        return false;
      },
    },
    rowDetailsButtonEnabled: {
      type: Boolean,
      default: false,
    },
    detailsRowModel: {
      type: Object,
      default: () => {},
    },
    editButtonEnabled: {
      type: Function,
      default: (row) => {
        return isAdminUser() || (row !== undefined && isUsersRecord(row));
      },
    },
    viewButtonEnabled: {
      type: Function,
      default: (row) => {
        return !isAdminUser() && row !== undefined && !isUsersRecord(row);
      },
    },
    selectButtonEnabled: {
      type: Function,
      default: () => {
        return false;
      },
    },
    convertButtonEnabled: {
      type: Object,
      default: () => {},
    },
    deleteButtonEnabled: {
      type: Function,
      default: (row) => {
        return isAdminUser() || (row !== undefined && isUsersRecord(row));
      },
    },
    addButtonEnabled: {
      type: Function,
      default: () => {
        return isAdminUser();
      },
    },
    searchingEnabled: {
      type: Boolean,
      default: false,
    },
    templates: {
      type: Array,
      default: function () {
        return [];
      },
    },
    booleanColumns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    urlColumns: {
      type: Object,
      default: function () {
        return {};
      },
    },
    emailColumns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    multilineColumns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    dropdownColumns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    dropdownsEnabled: {
      type: Boolean,
      default: false,
    },
    /* Sorting */
    sortTableByColumn: {
      type: String,
      default: undefined,
    },
    sortTableByFn: {
      type: Function,
      default: undefined,
    },
    /* Other misc props */
    onRefresh: Function,
    stickyHeaderHeight: {
      type: String,
      default: "300px",
    },
    rowClickedHandler: {
      type: Function,
      default: () => true, // NOOP
    },
    paginationRowsPerPage: {
      type: Number,
      default: UI_CONSTANTS.ENABLE_TABLE_PAGINATION_ROW_COUNT_LIMIT,
    },
    disableTableBorder: {
      type: Boolean,
      default: false,
    },
    // An optional parameter used in the add/convert/edit panes of the table. If not set, we use the apiendpoint instead (check getName computer property)
    name: {
      type: String,
      default: undefined,
    },
    // A parameter for when we need to copy the params to the body of a request
    copyApiParamsToBody: {
      type: Boolean,
      default: false,
    },
    // A parameter for specifying that this table should enable wide modals, covering most of the screen
    withWideModal: {
      type: Boolean,
      default: false,
    },
    // Disables displaying info or warn msg on top
    disableDisplayMsg: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    GenericModal,
  },

  data() {
    return {
      items: [],
      lastRow: [],
      calculatedFields: [],
      isBusy: true,
      filters: {},
      currentPage: 1,
      // Msg on top of table, next to add button
      displayMsg: false,
      displayMsgVariant: "danger",
      displayMsgText: "",
      searchCriteria: {},
    };
  },

  methods: {
    isAdminUser,
    formatString,
    isUsersRecord,

    refreshTable() {
      this.isBusy = true;
      callEndpoint(
        this.apiEndpoint,
        (data) => {
          this.items = data;
          /* Enable edit button column IF there is some record that has it enabled */
          if (this.items.some((row) => this.detailsButtonEnabled(row) === true))
            this.calculatedFields.push({ key: "details", label: "" });
          /* Enable edit button column IF there is some record that has it enabled */
          /* NOTE the condition must be complimentary to that of view button below */
          if (this.items.some((row) => this.editButtonEnabled(row) === true))
            this.calculatedFields.push({ key: "edit", label: "" });
          /* Enable view button column IF there is some record that has it enabled */
          /* NOTE the condition must be complimentary to that of edit button above */
          if (
            this.items.some((row) => this.detailsButtonEnabled(row) === false && this.viewButtonEnabled(row) === true)
          )
            this.calculatedFields.push({ key: "view", label: "" });
          /* Enable delete button column IF there is some record that has it enabled */
          if (this.items.some((row) => this.deleteButtonEnabled(row) === true))
            this.calculatedFields.push({ key: "delete", label: "" });
          /* Set isBusy to false once done to signal we can display the data */
          this.isBusy = false;
          if (this.onRefresh) this.onRefresh(this.items);
        },
        this.getApiParamsWithSearchCriteria()
      );
    },

    getApiParamsWithSearchCriteria() {
      return Object.assign({}, this.apiParams, this.searchCriteria);
    },

    genericModalCloseCalllback(msg) {
      this.displayMsg = true;
      this.displayMsgVariant = "info";
      this.displayMsgText = msg;
      setTimeout(() => (this.displayMsg = false), 6000);
    },

    showGenericModal(row, operation) {
      const op = ["SELECT", "CONVERT", "EDIT"].includes(operation) ? operation.toLowerCase() : "addview";
      const modalName = "modal_" + op + "_" + this.getName;

      let data = row !== undefined ? row.item : {};

      /* Only potentially needed for nested generic modals */
      if (this.copyApiParamsToBody) {
        for (const [key, value] of Object.entries(this.apiParams)) {
          data[key] = value;
        }
      }
      /* --------------------------------------------------- */

      this.$refs[modalName].initializeModal(
        data,
        this.apiEndpoint,
        operation,
        this.getName,
        this.calculatedFields.find((f) => f.primaryKey == true).key,
        this.convertButtonEnabled,
        (msg) => this.genericModalCloseCalllback(msg)
      );

      // Now that we have initialized the modal, display it!
      this.$refs[modalName].$children[0].show();
    },

    showConfirmDeleteModal(row) {
      const title = "Are you sure you want to delete this " + this.formatString(this.getName, true, false) + " entry";
      this.$root.$emit("bv::show::modal", "ConfirmationModal", title, this.deleteConfirmationCallback, row);
    },

    deleteConfirmationCallback(row) {
      const primaryKey = this.calculatedFields.filter((f) => f.primaryKey == true)[0].key;
      const primaryKeyValue = row.item[primaryKey];
      callEndpoint(
        this.apiEndpoint + "/" + primaryKeyValue,
        () => {
          this.displayMsg = true;
          this.displayMsgVariant = "info";
          this.displayMsgText = this.formatString(this.getName, true, false, true, true) + " deleted successfully";
          setTimeout(() => (this.displayMsg = false), 6000);
          this.refreshTable();
        },
        {},
        "DELETE",
        {},
        (error) => {
          const code = error.response.status;
          this.displayMsg = true;
          this.displayMsgVariant = "danger";
          if (code == 409)
            this.displayMsgText = "DELETE failed. Please first remove all dependent data to this entry and then retry";
          else this.displayMsgText = "DELETE failed with error code: Unauthorized";
          setTimeout(() => (this.displayMsg = false), 6000);
        }
      );
    },

    // removes html tags from string
    stripHtmlTags(txt) {
      let regex = /(<([^>]+)>)/gi;
      return txt.replace(regex, "");
    },

    getApproveButtonStyle(is_approved) {
      return is_approved ? "color: green; padding: 0px; height:30px" : "color: grey; padding: 0px; height:30px";
    },

    approveSystemComponent(row) {
      const primaryKey = this.calculatedFields.filter((f) => f.primaryKey == true)[0].key;
      const primaryKeyValue = row.item[primaryKey];
      row.item["is_approved"] = !row.item["is_approved"];

      // Here, we delete suitable models, as this comes from a record of the GET request of the table.
      // There, suitable models is a comma seperated string, and we do not want to give it back to the API,
      // as doing so would make the API interpret it as a suitable models update.
      const updatedRow = { ...row.item };
      delete updatedRow["suitable_models"];

      callEndpoint(
        this.apiEndpoint + "/" + primaryKeyValue,
        /* success callback */
        () => {},
        {} /* no params */,
        "PUT",
        updatedRow,
        /* error handler */
        () => {}
      );
    },

    modalFields(operation) {
      switch (operation) {
        case "CONVERT":
          return this.convertButtonEnabled.convertToSchema;
        case "ADD":
          return this.calculatedFields.filter((f) => f.onAdd == true || f.copyValue !== undefined);
        case "SELECT":
          return this.calculatedFields.filter((f) => f.onSelect == true || f.copyValue !== undefined);
        default:
          return this.calculatedFields.filter(
            (f) => f.onEdit !== false && (f.onAdd !== false || f.copyValue !== undefined)
          );
      }
    },
  },

  created() {
    // Copy as (1) we modify calculated fields below and as (2) javascript passes things by reference, this modification would
    // affect the original object, something we do not want, as the same schema may be referenced twice in two different pages.
    this.calculatedFields = [...this.fields];

    /* Push additional field if set, to header. The addition of some other fields happen in refreshTable */
    if (this.convertButtonEnabled) {
      this.calculatedFields.push({ key: "convert", label: "" });
    }
    if (this.rowDetailsButtonEnabled) {
      this.calculatedFields.push({ key: "rowDetails", label: "" });
    }
    this.refreshTable();
  },

  computed: {
    // Makes all columns sortable by default, excluding the button columns
    viewableCols() {
      return this.calculatedFields
        .filter((f) => f.hidden != true && (f.conditionalRendering === undefined || f.conditionalRendering()))
        .map((f) => {
          return {
            key: f.key,
            label: f.label,
            formatter: f.formatter,
            noSearch: f.noSearch,
            sortable:
              !f.disableSorting &&
              ["edit", "view", "convert", "rowDetails", "delete", "details", "approval_status"].indexOf(f.key) < 0,
            tdClass:
              ["edit", "view", "rowDetails", "convert", "delete", "details"].indexOf(f.key) >= 0 || f.type == "button"
                ? "buttonClass"
                : f.thClass,
            thClass:
              ["edit", "view", "rowDetails", "convert", "delete", "details"].indexOf(f.key) >= 0 || f.type == "button"
                ? "buttonClass"
                : f.thClass,
          };
        });
    },

    filtered() {
      const filtered = this.items.filter((item) => {
        return Object.keys(this.filters).every((key) =>
          String(item[key]).toLowerCase().includes(this.filters[key].toLowerCase())
        );
      });

      // Sort by specified column
      if (this.sortTableByColumn !== undefined) {
        filtered.sort((a, b) => {
          const aStr = a[this.sortTableByColumn].toString().toLowerCase();
          const bStr = b[this.sortTableByColumn].toString().toLowerCase();
          if (aStr < bStr) return -1;
          if (aStr > bStr) return 1;
          return 0;
        });
      } else if (this.sortTableByFn !== undefined) {
        filtered.sort(this.sortTableByFn);
      }

      return filtered.length > 0 ? filtered : [];
    },

    rows() {
      return this.items.length;
    },

    rowsPerPage() {
      return this.enablePagination ? this.paginationRowsPerPage : this.items.length;
    },

    enablePagination() {
      return this.items.length >= UI_CONSTANTS.ENABLE_TABLE_PAGINATION_ROW_COUNT_LIMIT;
    },

    hasTextInTextFields() {
      for (const f of Object.entries(this.filters)) {
        if (f.length > 0) return true;
      }
      return false;
    },

    getTableClasses() {
      return {
        tableContainer: !this.disableTableBorder,
      };
    },

    getName() {
      return this.name !== undefined ? this.name : this.apiEndpoint;
    },

    hasHiddenColumns() {
      return this.calculatedFields.filter((f) => f.hidden && !f.primaryKey).length > 0;
    },
  },

  mounted() {
    this.$root.$on("searchButtonClicked", (searchCriteria) => {
      if (this._isMounted == true && this._isDestroyed == false) {
        this.searchCriteria = searchCriteria;
        this.refreshTable();
        //this.$emit("rerenderAfterGenericModal");
      }
    });
  },
};
</script>

<style>
.tableContainer {
  background-color: white;
  /* Style change 26-04-2020 */
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-color: #ddd;
  padding: 15px;
  /* ---------------------- */
}
/* Sizing classes for specific columns (multiples of a button column) */
.buttonClass {
  width: 36px !important;
}
.buttonClassx2 {
  width: 72px !important;
}
.buttonClassx3 {
  width: 108px !important;
}
.buttonClassx4 {
  width: 144px !important;
}
.buttonClassx6 {
  width: 216px !important;
}
.buttonClassx8 {
  width: 288px !important;
}
/* ----------------------------------- */
.details-btn {
  border: 0px !important;
  padding: 0px !important;
  background-color: transparent !important;
  color: grey !important;
}
.table > tbody > tr > td {
  vertical-align: middle !important;
}
.b-table-sticky-header,
.table-responsive,
[class*="table-responsive-"] {
  margin-bottom: 0rem;
}
/* Control strip color */
/* Style change 26-04-2020 */
.table-striped > tbody > tr:nth-child(2n + 1) > td {
  background-color: #f9f9f9;
}
.table.b-table > thead > tr > [aria-sort],
.table.b-table > tfoot > tr > [aria-sort] {
  vertical-align: middle;
}
/* ---------------------- */
/* https://github.com/bootstrap-vue/bootstrap-vue/issues/1732 */
.tooltip {
  top: 0;
}
</style>
