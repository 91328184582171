<template>
  <BaseView>
    <Table
      apiEndpoint="users"
      stickyHeaderHeight="1125px"
      :apiParams="{
        role: ['Employee', 'Project Manager', 'Administrator']
      }"
      :emailColumns="['email']"
      :urlColumns="{'url': 'url'}"
      :fields="usersSchema"
      v-bind:addButtonEnabled="() => false" 
      v-bind:editButtonEnabled="() => false"
      v-bind:deleteButtonEnabled="() => false"
      v-bind:detailsButtonEnabled="() => true"
      @detailsClicked="navigateToProjectPage(...arguments)"
    />
  </BaseView>
</template>

<script>
import BaseView from "./../BaseView.vue";
import Table from "@/components/charts/Table.vue";
import { TABLE_SCHEMAS } from "./../../config.js";

export default {
  name: "Employees",

  data() {
    return {
      usersSchema: TABLE_SCHEMAS["Users"]
    };
  },

  components: {
    BaseView,
    Table
  },

  methods: {
    navigateToProjectPage(row) {
      this.$router.push("/users/" + row.item["username"]);
    }
  }
};
</script>
