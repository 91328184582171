var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseView',[_c('Table',{key:_vm.rerenderTableKey,attrs:{"apiEndpoint":"opportunities","apiParams":{ status: _vm.opportunitiesStatusOptions },"stickyHeaderHeight":"1125px","fields":_vm.opportunitiesSchema,"searchingEnabled":"","convertButtonEnabled":{
      convertToSchema: _vm.projectsSchema,
      copyFields: [
        ['name', 'name'],
        ['region', 'region'],
        ['manager_full_name', 'manager_full_name'],
        ['project_type', 'project_type'],
        ['opportunity_title', 'project_title'],
        ['opportunity_id', 'origin_opportunity_id'],
        ['opportunity_seq_num', 'opportunity_seq_num'] ],
      convertToApiEndpoint: 'projects',
      updateCurrRecord: function (rec) { return (rec['status'] = 'Qualified'); },
    },"detailsButtonEnabled":function () { return true; },"sortTableByFn":function (a, b) { return (a['status'] == 'Qualified' || b['status'] == 'Qualified' ? -1 : 1); }},on:{"detailsClicked":function($event){return _vm.navigateToOpportunityPage.apply(void 0, arguments)},"rerenderAfterGenericModal":_vm.rerenderAfterGenericModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }