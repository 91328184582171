<template>
  <BaseView
    searchEnabled
    :searchConfig="{
      'System Criteria': [
        {
          key: 'ship_type',
          label: 'Ship Type',
          type: 'dropdown',
          options: Object.keys(ship_types),
        },
        {
          key: 'apparatus_group',
          label: 'Apparatus Group',
          type: 'dropdown',
          options: ['IIA', 'IIB', 'IIC'],
          conditionalRendering: (searchCriteria) => {
            return ship_types[searchCriteria['ship_type']];
          },
        },
        {
          key: 'temperature_group',
          label: 'Temperature Group',
          type: 'dropdown',
          options: ['T1', 'T2', 'T3', 'T4', 'T5', 'T6'],
          initValue: 'T3',
          default: 'T3',
          conditionalRendering: (searchCriteria) => {
            return ship_types[searchCriteria['ship_type']];
          },
        },
        {
          key: 'with_uscg_approval_set',
          label: 'US Trade Eligible (USCG)',
          type: 'checkbox',
        },
        {
          key: 'only_uscg_approved_systems',
          label: 'Only USCG-Approved systems',
          type: 'checkbox',
          conditionalRendering: (searchCriteria) => {
            return searchCriteria['with_uscg_approval_set'] === true;
          },
        },
        {
          key: 'include_old_g8_systems',
          label: 'Include Old G8 Systems',
          type: 'checkbox',
        },
        {
          key: 'only_approved_imo_status',
          label: 'Use only IMO-Approved systems',
          type: 'checkbox',
        },
        {
          key: 'fitting_requirements',
          label: 'Fitting Requirements',
          type: 'dropdown',
          options: ['New Build', 'Retrofit'],
        },
      ],
      'Water Quality': [
        {
          key: 'use_worst_possible_ports',
          label: 'Use worst possible ports',
          type: 'checkbox',
        },
        {
          key: 'specific_port_data',
          label: 'Use data from specific ports',
          type: 'multiselect',
          apiEndpoint: 'ports',
          mapFunc: (rec) => rec['port_name'],
          conditionalRendering: (searchCriteria) => {
            return searchCriteria['use_worst_possible_ports'] == false;
          },
        },
      ],
      'Flow Rate Requirements': [
        {
          key: 'pump_one_capacity',
          label: 'Pump One (m³/h)',
          type: 'number',
          step: 100,
          min: 0,
        },
        {
          key: 'pump_two_capacity',
          label: 'Pump Two (m³/h)',
          type: 'number',
          step: 100,
          min: 0,
        },
      ],
      'Weighting Criteria': [
        {
          key: 'financial_cat_weight',
          label: 'Operational Limitations',
          type: 'slider',
          initValue: 2,
          min: 0,
          max: 4,
        },
        {
          key: 'installation_cat_weight',
          label: 'Financial',
          type: 'slider',
          initValue: 2,
          min: 0,
          max: 4,
        },
        {
          key: 'operational_cat_weight',
          label: 'Installation Complexity',
          type: 'slider',
          initValue: 2,
          min: 0,
          max: 4,
        },
        {
          key: 'manufacturer_cat_weight',
          label: 'Manufacturer Reputation',
          type: 'slider',
          initValue: 2,
          min: 0,
          max: 4,
        },
      ],
    }"
  >
    <Table
      :key="rerenderTableKey"
      apiEndpoint="bwms_finder"
      stickyHeaderHeight="1125px"
      :fields="systemsSchema"
      rowDetailsButtonEnabled
      :detailsRowModel="modelsSchema"
      v-bind:addButtonEnabled="() => false"
      v-bind:editButtonEnabled="() => false"
      v-bind:deleteButtonEnabled="() => false"
      v-bind:detailsButtonEnabled="() => false"
      v-bind:viewButtonEnabled="() => true"
      @detailsClicked="navigateToSystemsPage(...arguments)"
      :booleanColumns="['active_substrances', 'ams_designation', 'explosion_proofing']"
      @rerenderAfterGenericModal="rerenderTableKey = !rerenderTableKey"
    ></Table>
  </BaseView>
</template>

<script>
/* @ is a shortcut for ./src */
import BaseView from "./BaseView.vue";
import Table from "@/components/charts/Table.vue";
import { TABLE_SCHEMAS } from "./../config.js";
import store from "@/views/auth/store.js";

export default {
  name: "BWMSFinder",
  components: {
    BaseView,
    Table,
  },

  data() {
    return {
      systemsSchema: TABLE_SCHEMAS["Systems"]
        .concat([{ key: "total_system_score", label: "System Score (%)" }])
        .filter((c) => c.key != "is_active"),
      // TODO CLEANUP COLUMNS HERE AFTER FINISHED TESTING THE FINDER
      modelsSchema: TABLE_SCHEMAS["Models"]
        .filter(
          (c) =>
            c.hidden != true && c.key != "opex" && c.key.includes("experience") == false && c.key != "is_model_active"
        )
        // .concat({ key: "financial_score", label: "Financial Score" })
        // .concat({ key: "normalized_opex_rank", label: "Normalized Opex Rank" })
        // .concat({ key: "normalized_capex_rank", label: "Normalized Capex Rank" })
        // .concat({ key: "filter_model_rank", label: "filter_model_rank" })
        .concat({ key: "total_model_score_prct", label: "Model Score (%)" })
        .map((e) => {
          e.sortable = true;
          return e;
        }),
      rerenderTableKey: true,
      searchCriteria: {},
      countries: store.state.countries,
      ship_types: { ...{ "": false }, ...store.state.ship_types },
    };
  },

  methods: {
    navigateToSystemsPage(row) {
      this.$router.push("/systems/" + row.item["system_id"]);
    },
  },
};
</script>
