<template>
  <div class="login-screen">
    <div class="login-screen__text">
      <h1>Welcome to Mouawad Consulting</h1>
      <br />
      <h4 style="padding-bottom: 25px">
        Sign in to see the status of your projects and the latest news and regulations in the maritime industry
      </h4>
      <b-form @submit.prevent="login">
        <b-row class="justify-content-md-center">
          <b-col sm="7">
            <b-form-input v-model="username" required placeholder="Username" />
            <b-input-group>
              <b-form-input v-model="password" required :type="passwordFieldType" placeholder="Password" />
              <b-input-group-append>
                <span class="fa fa-eye hide-password-button" type="password" @click="switchVisibility" />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <div style="height: 25px">
          <!-- Must match the padding on h4 above (sign in text) -->
          <h6 v-if="wrongPwGiven()" style="color: red">{{ loginErrorText() }}</h6>
        </div>
        <div class="container space-around">
          <b-button
            type="button"
            variant="link"
            class="login-screen__register_forgot_pw_label"
            v-on:click="$bvModal.show('RegisterUserModal')"
          >
            Register Now
          </b-button>
          <b-button
            type="button"
            variant="link"
            class="login-screen__register_forgot_pw_label"
            v-on:click="showForgotPasswordModal()"
          >
            Forgot Password?
          </b-button>
        </div>
        <b-button type="submit" variant="light" style="margin-top: 10px" :pressed="true">SIGN IN</b-button>
        <!-- Modals follow here -->
        <RegisterUserModal />
        <ForgotPasswordModal />
        <ResetPasswordModal :token="this.token" />
        <AccountActivationModal :token="this.token" :token_type="this.token_type" />
      </b-form>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import { AUTH_REQUEST } from "./constants.js";
import store from "./store.js";
import ForgotPasswordModal from "../../modals/ForgotPasswordModal.vue";
import ResetPasswordModal from "../../modals/ResetPasswordModal.vue";
import RegisterUserModal from "../../modals/RegisterUserModal.vue";
import { callAllEndpoints } from "./../../utils/apicall.js";
import { isUserInternalResource } from "./../../utils/helpers.js";
import AccountActivationModal from "../../modals/AccountActivationModal.vue";

export default {
  name: "LoginScreen",

  data() {
    return {
      username: "",
      password: "",
      passwordFieldType: "password", // For switching between hidden and non hidden password
      store,
      token: this.$route.query.token,
      token_type: this.$route.query.token_type,
      wrongPwGiven: () => this.store.state.status == "error",
      loginErrorText: () =>
        this.store.state.error_code == 403
          ? "Please activate your account first and retry"
          : "Invalid username or password",
    };
  },

  components: {
    RegisterUserModal,
    ForgotPasswordModal,
    ResetPasswordModal,
    AccountActivationModal,
  },

  methods: {
    loadStaticDataAndProceedTo(route) {
      if (
        store.state.countries === undefined ||
        store.state.regions === undefined ||
        store.state.currencies === undefined ||
        store.state.ship_types === undefined
      ) {
        // Initialize static datasets and run application
        callAllEndpoints(
          ["regions", "currency-codes", "countries", "ship-types"],
          {},
          (region_names, currency_codes, country_names, ship_types) => {
            store.state.currencies = currency_codes.data;
            store.state.regions = region_names.data;
            store.state.countries = country_names.data;
            store.state.ship_types = Object.assign(
              {},
              ...ship_types.data.map((ship) => ({ [ship.ship_type]: ship.explosion_proofing }))
            );
            this.$router.push(route);
          }
        );
      } else this.$router.push(route);
    },

    login: function () {
      const { username, password } = this;
      this.store.dispatch(AUTH_REQUEST, { username, password }).then(() => {
        // On successfull authentication redirect to main dashboard if an internal resource
        if (isUserInternalResource()) this.loadStaticDataAndProceedTo("/dashboard/general");
        else this.loadStaticDataAndProceedTo("/profile"); // otherwise to profile if user
      });
    },
    showForgotPasswordModal() {
      this.$bvModal.show("ForgotPasswordModal");
    },
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
    },
  },

  mounted() {
    // Display modal if needed
    if (this.token != undefined && this.token != "") {
      if (this.token_type == "p") this.$bvModal.show("ResetPasswordModal");
      else this.$bvModal.show("AccountActivationModal");
    }
    this.store.state.status = "";
  },
};
</script>

<style scoped>
h4 {
  line-height: 1.6;
}

.login-screen {
  color: #000 !important;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-weight: 400 !important;
  min-height: 100%;
  background-image: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
    url("../../../public/banner_1.jpg"); /* TODO: Support dynamic banners for this? */
  background-size: cover;
}

.login-screen__text {
  width: 650px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: hsla(0, 0%, 100%, 0.1);
  padding: 60px 45px;
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  border-radius: 4px;
}

.login-screen__register_forgot_pw_label {
  text-decoration: underline;
  color: dimgray !important;
  padding-bottom: 15px;
}

label {
  width: 15%;
  padding-right: 10px;
}

input[type="text"],
input[type="password"] {
  width: 40%;
  margin: 8px 0;
  box-sizing: border-box;
}

.hide-password-button {
  position: absolute;
  top: 9px;
  right: 9px;
  border: 1px solid transparent;
  background: transparent;
  line-height: 35px;
  font-size: 0.8rem;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 100000;
}

.container {
  display: flex;
  width: 313px;
}
.container.space-around {
  justify-content: space-around;
}
.container.space-between {
  justify-content: space-between;
}
</style>
