import pluralize from "pluralize";

export function convertDateToAPIFormat(d) {
  var mm = d.getMonth() + 1; // getMonth() is zero-based
  var dd = d.getDate();

  return [d.getFullYear(), (mm > 9 ? "" : "0") + mm, (dd > 9 ? "" : "0") + dd].join("-");
}

export const INTERNAL_RESOURCE_USERS = ["Administrator", "Project Manager", "Employee"];
export const IR_AND_BALLASTADMINS = INTERNAL_RESOURCE_USERS.concat(["Ballast Administrator"]);
export const IR_AND_MANUFACTURERS = INTERNAL_RESOURCE_USERS.concat(["Manufacturer"]);
export const IR_BALLASTADMIN_MANUFACTURERS = INTERNAL_RESOURCE_USERS.concat(["Ballast Administrator", "Manufacturer"]);
export const ADMIN_BALLASTADMIN_MANUFACTURERS = ["Administrator", "Ballast Administrator", "Manufacturer"];
export const ADMIN_BALLASTADMIN = ["Administrator", "Ballast Administrator"];
export const NON_BWM_INTERNAL = ["User", "Manufacturer"];

export function getUsername() {
  return sessionStorage.getItem("user-name");
}

export function getUserRole() {
  return sessionStorage.getItem("user-role");
}

export function isUsersRecord(rec) {
  return rec["created_by"] == getUsername();
}

export function isUserInternalResource() {
  const role = getUserRole();
  return INTERNAL_RESOURCE_USERS.indexOf(role) >= 0;
}

export function isSubcriptionProOrUltimate() {
  const subscription = sessionStorage.getItem("subscription");
  return subscription === "Pro" || subscription === "Ultimate";
}

export function isUserManufacturer() {
  return getUserRole() == "Manufacturer";
}

export function isAdminUser() {
  return getUserRole() == "Administrator";
}

export function isUserAdminBallastAdminManufacturer() {
  const role = getUserRole();
  return ADMIN_BALLASTADMIN_MANUFACTURERS.indexOf(role) >= 0;
}

export function isUserAdminBallastAdmin() {
  const role = getUserRole();
  return ADMIN_BALLASTADMIN.indexOf(role) >= 0;
}

export function isIRorBallastAdmin() {
  const role = getUserRole();
  return IR_AND_BALLASTADMINS.indexOf(role) >= 0;
}

export function isIRBallastAdminManufacturer() {
  const role = getUserRole();
  return IR_BALLASTADMIN_MANUFACTURERS.indexOf(role) >= 0;
}

export function isUserIROrManufacturer() {
  const role = getUserRole();
  return IR_AND_MANUFACTURERS.indexOf(role) >= 0;
}

export function capitalizeFirstLetterFn(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatString(string, singularize = true, toUppercase = true, removeDashes = true, capitalizeFirstLetter = false) {
  var res = string;
  if (singularize) res = pluralize.singular(res);
  if (removeDashes) res = res.replace(/-/g, " ");
  if (capitalizeFirstLetter) res = capitalizeFirstLetterFn(res);
  return toUppercase ? res.toUpperCase() : res;
}

export function getApiPath(endpoint) {
  const hostname = process.env.NODE_ENV == "development" ? "http://localhost" : window.location.origin;
  return hostname + ":2053/" + endpoint;
}

export function compareDates(dateStr1, dateStr2) {
  var d1 = Date.parse(dateStr1);
  var d2 = Date.parse(dateStr2);
  return d1 - d2;
}

export function isOnPage(pageName) {
  return window.location.pathname.endsWith(pageName);
}
