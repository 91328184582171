var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseView',{on:{"rerenderAfterGenericModal":_vm.rerenderAfterGenericModal}},[_c('div',[(_vm.systemInfo == undefined)?_c('div',{staticClass:"text-center text-danger my-2",staticStyle:{"height":"350px","padding-top":"160px"}},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading... "+_vm._s(this.systemInfo)+" ")])],1):(_vm.systemInfo.length == 0)?_c('div',[_c('h2',{staticClass:"banner-class-text"},[_vm._v("You are not authorized to view the information of this project")])]):_c('b-container',{attrs:{"fluid":""}},[_c('Collapsable',{attrs:{"label":"SYSTEM INFO","visible":"","id":"systemInfo"}},[_c('b-form',[_c('b-row',{staticClass:"info-row-border",staticStyle:{"background-color":"white"}},_vm._l((_vm.systemsSchema.filter(
                function (f) { return f.onAdd && (f.conditionalRendering === undefined || f.conditionalRendering(_vm.systemInfo)); }
              )),function(field,idx){return _c('b-col',{key:idx,class:field.explicitWidth ? field.explicitWidth : 'col-6',staticStyle:{"height":"70px"}},[_c('b-row',[_c('b-col',{staticStyle:{"font-weight":"bold"},attrs:{"sm":"3"}},[_c('span',{domProps:{"innerHTML":_vm._s(field.label)}})]),_c('b-col',{attrs:{"sm":"8"}},[(field.checkbox)?_c('b-checkbox',{attrs:{"disabled":""},model:{value:(_vm.systemInfo[field.key]),callback:function ($$v) {_vm.$set(_vm.systemInfo, field.key, $$v)},expression:"systemInfo[field.key]"}}):_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.systemInfo[field.key] !== undefined && _vm.systemInfo[field.key] !== null
                          ? _vm.systemInfo[field.key].toString()
                          : '',"disabled":""}})],1)],1)],1)],1)}),1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-12",attrs:{"id":"models"}},[_c('Collapsable',{attrs:{"label":"MODELS","visible":""}},[_c('Table',{key:_vm.rerenderTableKey,attrs:{"apiEndpoint":"models","apiParams":{ system_id: this.$route.params.id },"stickyHeaderHeight":"1125px","fields":_vm.modelsSchema,"searchingEnabled":"","sortTableByColumn":"model_name","addButtonEnabled":function () {
                  return _vm.isUserAdminBallastAdmin() || (_vm.isUserManufacturer() && _vm.isUsersRecord(this$1.systemInfo));
                },"editButtonEnabled":function (row) {
                  return _vm.isUserAdminBallastAdmin() || (_vm.isUserManufacturer() && _vm.isUsersRecord(this$1.systemInfo));
                },"deleteButtonEnabled":function (row) {
                  return _vm.isUserAdminBallastAdmin() || (_vm.isUserManufacturer() && _vm.isUsersRecord(this$1.systemInfo));
                },"viewButtonEnabled":function () { return false; },"templates":['is_model_active']},on:{"rerenderAfterGenericModal":_vm.rerenderAfterGenericModal},scopedSlots:_vm._u([{key:"is_model_active",fn:function(ref){
                var data = ref.data;
return [(data.item['is_model_active'])?_c('div',{staticStyle:{"text-align":"center"}},[_vm._v("🟢")]):_c('div',{staticStyle:{"text-align":"center"}},[_vm._v("⚫")])]}}])})],1)],1),_c('b-col',{staticClass:"col-12",attrs:{"id":"components"}},[_c('Collapsable',{attrs:{"label":"COMPONENTS","visible":""}},[_c('Table',{key:_vm.rerenderTableKey,attrs:{"name":"components","apiEndpoint":"systems/components","apiParams":{ system_id: this.$route.params.id, filter_matching: true },"stickyHeaderHeight":"1125px","fields":_vm.componentsSchema,"searchingEnabled":"","addButtonEnabled":function () {
                  return _vm.isUserAdminBallastAdmin() || (_vm.isUserManufacturer() && _vm.isUsersRecord(this$1.systemInfo));
                },"editButtonEnabled":function (row) {
                  return _vm.isUserAdminBallastAdmin() || (_vm.isUserManufacturer() && _vm.isUsersRecord(this$1.systemInfo));
                },"deleteButtonEnabled":function (row) {
                  return _vm.isUserAdminBallastAdmin() || (_vm.isUserManufacturer() && _vm.isUsersRecord(this$1.systemInfo));
                },"viewButtonEnabled":function () { return false; },"multilineColumns":['suitable_models']},on:{"rerenderAfterGenericModal":_vm.rerenderAfterGenericModal}})],1)],1),_c('b-col',{staticClass:"col-12",attrs:{"id":"filters"}},[_c('Collapsable',{attrs:{"label":"FILTERS","visible":""}},[_c('Table',{key:_vm.rerenderTableKey,attrs:{"name":"filters","apiEndpoint":'systems/filters',"apiParams":{ system_id: this.$route.params.id, filter_matching: true },"stickyHeaderHeight":"1125px","fields":_vm.filtersSchema,"searchingEnabled":"","addButtonEnabled":function () {
                  return _vm.isUserAdminBallastAdmin() || (_vm.isUserManufacturer() && _vm.isUsersRecord(this$1.systemInfo));
                },"editButtonEnabled":function (row) {
                  return _vm.isUserAdminBallastAdmin() || (_vm.isUserManufacturer() && _vm.isUsersRecord(this$1.systemInfo));
                },"deleteButtonEnabled":function (row) {
                  return _vm.isUserAdminBallastAdmin() || (_vm.isUserManufacturer() && _vm.isUsersRecord(this$1.systemInfo));
                },"viewButtonEnabled":function () { return false; },"multilineColumns":['suitable_models']},on:{"rerenderAfterGenericModal":_vm.rerenderAfterGenericModal}})],1)],1),_c('b-col',{staticClass:"col-12",attrs:{"id":"imoapprovals"}},[_c('Collapsable',{attrs:{"label":"IMO APPROVALS","visible":""}},[_c('Table',{key:_vm.rerenderTableKey,attrs:{"name":"IMO Approvals","apiEndpoint":'systems/imo_approvals',"apiParams":{ system_id: this.$route.params.id },"stickyHeaderHeight":"1125px","fields":_vm.imoApprovalsSchema,"searchingEnabled":"","addButtonEnabled":function () {
                  return _vm.isUserAdminBallastAdmin() || (_vm.isUserManufacturer() && _vm.isUsersRecord(this$1.systemInfo));
                },"editButtonEnabled":function (row) {
                  return _vm.isUserAdminBallastAdmin() || (_vm.isUserManufacturer() && _vm.isUsersRecord(this$1.systemInfo));
                },"deleteButtonEnabled":function (row) {
                  return _vm.isUserAdminBallastAdmin() || (_vm.isUserManufacturer() && _vm.isUsersRecord(this$1.systemInfo));
                },"viewButtonEnabled":function () { return false; }},on:{"rerenderAfterGenericModal":_vm.rerenderAfterGenericModal}})],1)],1),_c('b-col',{staticClass:"col-12",attrs:{"id":"classocieties"}},[_c('Collapsable',{attrs:{"label":"CLASS SOCIETIES","visible":""}},[_c('Table',{key:_vm.rerenderTableKey,attrs:{"name":"class societies","apiEndpoint":'systems/class_type_approvals',"apiParams":{ system_id: this.$route.params.id },"stickyHeaderHeight":"1125px","searchingEnabled":"","fields":_vm.classSocietiesSchema,"addButtonEnabled":function () {
                  return _vm.isUserAdminBallastAdmin() || (_vm.isUserManufacturer() && _vm.isUsersRecord(this$1.systemInfo));
                },"editButtonEnabled":function (row) {
                  return _vm.isUserAdminBallastAdmin() || (_vm.isUserManufacturer() && _vm.isUsersRecord(this$1.systemInfo));
                },"deleteButtonEnabled":function (row) {
                  return _vm.isUserAdminBallastAdmin() || (_vm.isUserManufacturer() && _vm.isUsersRecord(this$1.systemInfo));
                },"viewButtonEnabled":function () { return false; }},on:{"rerenderAfterGenericModal":_vm.rerenderAfterGenericModal}})],1)],1),_c('b-col',{staticClass:"col-12",attrs:{"id":"technologies"}},[_c('Collapsable',{attrs:{"label":"TECHNOLOGIES","visible":""}},[_c('Table',{key:_vm.rerenderTableKey,attrs:{"name":"technologies","apiEndpoint":'systems/technologies',"apiParams":{ system_id: this.$route.params.id, filter_matching: true },"stickyHeaderHeight":"1125px","fields":_vm.technologiesSchema,"searchingEnabled":"","addButtonEnabled":function () { return false; },"editButtonEnabled":function () { return false; },"deleteButtonEnabled":function () { return false; },"selectButtonEnabled":function () {
                  return _vm.isUserAdminBallastAdmin() || (_vm.isUserManufacturer() && _vm.isUsersRecord(this$1.systemInfo));
                },"viewButtonEnabled":function () { return false; }},on:{"rerenderAfterGenericModal":_vm.rerenderAfterGenericModal}})],1)],1),_c('b-col',{staticClass:"col-12",attrs:{"id":"documents"}},[_c('Collapsable',{attrs:{"label":"DOCUMENTS","visible":"","id":"documents"}},[_c('FileManager',{attrs:{"rootDir":'/systems/' + this.systemInfo['system_id'] + '/'}})],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }