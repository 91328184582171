<template>
  <BaseView>
    <Table
      :key="rerenderTableKey"
      apiEndpoint="projects"
      stickyHeaderHeight="1125px"
      :fields="projectsSchema"
      v-bind:addButtonEnabled="() => false"
      v-bind:detailsButtonEnabled="() => true"
      @detailsClicked="navigateToProjectPage(...arguments)"
      searchingEnabled
      @rerenderAfterGenericModal="rerenderTableKey = !rerenderTableKey"
    ></Table>
  </BaseView>
</template>

<script>
/* @ is a shortcut for ./src */
import BaseView from "./BaseView.vue";
import Table from "@/components/charts/Table.vue";
import { TABLE_SCHEMAS } from "./../config.js";
import { isAdminUser } from "@/utils/helpers.js";

export default {
  name: "Projects",
  components: {
    BaseView,
    Table
  },
  data() {
    return {
      projectsSchema: TABLE_SCHEMAS[this.$options.name],
      rerenderTableKey: true
    };
  },
  methods: {
    isAdminUser,
    navigateToProjectPage(row) {
      this.$router.push("/projects/" + row.item["project_id"]);
    }
  }
};
</script>
