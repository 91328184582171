<template>
  <BaseView>
    <Table
      :key="rerenderTableKey"
      apiEndpoint="users"
      :fields="usersSchema"
      stickyHeaderHeight="1125px"
      :urlColumns="{'url': 'url'}"
      :emailColumns="['email']"
      @rerenderAfterGenericModal="rerenderAfterGenericModal"
    >
    </Table>
  </BaseView>
</template>

<script>
import BaseView from "./BaseView.vue";
import Table from "./../components/charts/Table.vue";
import { TABLE_SCHEMAS } from "./../config.js";

export default {
  name: "Users",

  components: {
    BaseView,
    Table
  },

  data() {
    return {
      rerenderTableKey: true,
      usersSchema: TABLE_SCHEMAS[this.$options.name]
    };
  },

  methods: {
    rerenderAfterGenericModal() {
      this.rerenderTableKey = !this.rerenderTableKey;
    }
  }
};
</script>
