<template>
  <!-- Nothing particular to rended here, just render the view based on the route -->
  <router-view></router-view>
</template>

<!-- Global style directives -->
<style>
/* 
   * Height must be applied to root elements so that subsequent elements can override it
   * https://stackoverflow.com/questions/7049875/why-doesnt-height-100-work-to-expand-divs-to-the-screen-height
   */
html,
body,
form,
main {
  height: 100%;
}

/* Font imports */
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic);
</style>

<script>
import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
export default {};

// Donut library
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
Vue.use(Donut);

// Rich editor
import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {
	hideModules: { 
		"justifyLeft": true,
		"justifyCenter": true,
		"justifyRight": true,
		"link": true,
		"code": true,
		"image": true,
		"table": true,
		"removeFormat": true,
		"separator": true
	},
	maxHeight: "150px"
});
import "vue-wysiwyg/dist/vueWysiwyg.css";
</script>
