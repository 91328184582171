import { parsePhoneNumberFromString } from "libphonenumber-js/min";

export function phoneNumberFormatter(column_key) {
  // eslint-disable-next-line
  return (value, key, item) => {
    try {
      return parsePhoneNumberFromString(item[column_key]).formatInternational();
    } catch {
      return item[column_key];
    }
  };
}
