<template>
  <div class="base-view">
    <IdleAlertLogoutModal />
    <ConfirmationModal @rerenderAfterGenericModal="handleRefresh()" />
    <Menu :router="getRouter()" logoIconPath="/logo.png" menuTitle="Mouawad Consulting"></Menu>
    <!-- Main content next to menu-->
    <div class="main-content" :style="getBackgroundColor()">
      <h3 class="main-content-header">
        {{ $route.name }}
        <b-button variant="link" style="float: right; padding-top: 12px">
          <span class="fa-stack" v-on:click="doLogout()" title="Log out">
            <i class="far fa-circle fa-stack-1x" style="font-size: 32px; color: #593b8a !important"></i>
            <i class="fa fa-sign-out-alt fa-stack-1x" style="font-size: 18px; color: #593b8a !important"></i>
          </span>
        </b-button>
      </h3>
      <div class="main-content-body" style="padding-top: 1.5rem" v-if="!searchEnabled">
        <slot></slot>
      </div>
      <div v-else class="container-fluid" style="height: calc(100% - 60px)">
        <div class="row" style="height: 100%; margin-right: 0px; margin-left: 0px">
          <div class="col-10 main-content-body" style="padding-top: 1.5rem">
            <slot></slot>
          </div>
          <div class="col-2" style="padding-right: 0px; padding-left: 0px">
            <Search :router="getRouter()" :searchConfig="searchConfig"></Search>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /* @ is a shortcut for ./src */
  import Menu from "@/components/menu/Menu.vue";
  import Search from "@/components/search/Search.vue";
  import IdleAlertLogoutModal from "@/modals/IdleAlertLogoutModal.vue";
  import Router from "@/router.js";
  import ConfirmationModal from "@/modals/ConfirmationModal.vue";
  import { AUTH_LOGOUT } from "@/views/auth/constants.js";
  import store from "@/views/auth/store.js";

  export default {
    name: "BaseView",
    components: {
      Menu,
      IdleAlertLogoutModal,
      ConfirmationModal,
      Search,
    },

    props: {
      backgroundColor: {
        type: String,
        default: "#F9F9FA",
      },
      searchEnabled: {
        type: Boolean,
        default: false,
      },
      searchConfig: {
        type: Object,
        default: () => {},
      },
    },

    data() {
      return {
        store,
      };
    },

    methods: {
      getRouter() {
        return Router;
      },

      doLogout() {
        this.store.dispatch(AUTH_LOGOUT);
        this.$router.push("/");
      },

      getBackgroundColor() {
        return "background-color: " + this.backgroundColor;
      },
    },
  };
</script>

<style scoped>
  .base-view {
    width: 100%;
    height: 100%;
    z-index: 0;
    display: flex;
  }

  .main-content-header {
    color: #593b8a;
    vertical-align: middle;
    border-bottom: 1px solid grey;
    line-height: 60px;
    height: 60px;
    background-color: #edf0f2;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 4;
    padding-left: 50px;
    margin-bottom: 0px; /* Override h3 default margin */
  }

  .main-content {
    overflow-y: auto;
    flex-grow: 1;
    min-height: 100%;
    height: 100%;
  }

  .main-content-body {
    padding-left: 40px;
    padding-right: 40px;
  }
</style>
