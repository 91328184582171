var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseView',[_c('Table',{key:_vm.rerenderTableKey,attrs:{"apiEndpoint":"leads","apiParams":{ status: _vm.leadsStatusOptions },"stickyHeaderHeight":"1125px","fields":_vm.leadsSchema,"emailColumns":['email'],"searchingEnabled":"","sortTableByFn":function (a, b) { return (a['status'] == 'Qualified' || b['status'] == 'Qualified' ? -1 : 1); },"convertButtonEnabled":{
      convertToSchema: _vm.opportunitiesSchema,
      copyFields: [
        ['name', 'name'],
        ['region', 'region'],
        ['manager_full_name', 'manager_full_name'],
        ['lead_name', 'opportunity_title'],
        ['lead_id', 'origin_lead_id'] ],
      convertToApiEndpoint: 'opportunities',
      updateCurrRecord: function (rec) { return (rec['status'] = 'Qualified'); },
    }},on:{"rerenderAfterGenericModal":_vm.rerenderAfterGenericModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }