<template>
  <div style="height: 100%;">
    <div class="text-center text-danger my-2" v-if="this.busy" style="height: 275px; padding-top: 110px;">
      <b-spinner class="align-middle"></b-spinner>
      <strong>Loading...</strong>
    </div>
    <vc-donut
      :sections="series"
      has-legend
      :size="40"
      :auto-adjust-text-size="true"
      :thickness="thickness"
      unit="%"
      @section-mouseleave="handleMouseLeave"
      @section-mouseenter="handleMouseEnter"
      v-else-if="series.length > 0"
    >
      <h6 style="overflow-wrap: anywhere;" v-html="getText"></h6>
    </vc-donut>
    <div v-else style="height: 275px;">
      <h3 style="text-align: center; padding-top: 110px; padding-bottom: 20px; color: #593b8a;">
        No records found
      </h3>
    </div>
  </div>
</template>

<script>
import { callEndpoint } from "./../../utils/apicall.js";

export default {
  props: {
    apiEndpoint: String,
    apiParams: {},
    thickness: {
      type: Number,
      default: 30,
    },
  },

  name: "PieChart",

  data() {
    return {
      total: 0,
      text: "",
      series: [],
      busy: true,
    };
  },

  methods: {
    // eslint-disable-next-line
    handleMouseLeave(section, event) {
      this.text = "Total<br />" + this.total;
    },
    // eslint-disable-next-line
    handleMouseEnter(section, event) {
      this.text = section.label + "<br />" + section.count + " (" + section.value + "%)";
    },
  },

  computed: {
    getText() {
      return this.text;
    },
  },

  created() {
    this.busy = true;
    callEndpoint(
      this.apiEndpoint,
      (data) => {
        const values = Object.values(data);
        this.total = values.reduce((a, b) => a + b, 0);
        this.series = Object.entries(data).map((e) => {
          // convert NaN to zero with || 0
          return { label: e[0], value: Number.parseFloat(((e[1] * 100) / this.total).toFixed(2)) || 0, count: e[1] };
        });
        this.text = "Total<br />" + this.total;
        this.busy = false;
      },
      this.apiParams
    );
  },
};
</script>
