var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseView',{attrs:{"backgroundColor":"white"}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"mb-4"},[_c('b-col',[_c('DropdownMenu',{attrs:{"allOptionsText":"ALL REGIONS","items":this.store.state.regions},on:{"dropdownSelected":function($event){_vm.region = $event}}})],1),_c('b-col',[_c('DropdownMenu',{attrs:{"allOptionsText":"ALL TIME","items":['THIS YEAR', 'THIS MONTH', 'THIS WEEK'],"defaultOption":"THIS MONTH"},on:{"dropdownSelected":function($event){_vm.timePeriod = $event}}})],1),(_vm.isAdminUser())?_c('b-col',[_c('DropdownMenu',{attrs:{"allOptionsText":"ALL MANAGERS","apiEndpoint":"users/fullnames","fieldExtractor":function (user) { return user['full_name']; },"apiParams":{ role: ['Administrator', 'Project Manager'] }},on:{"dropdownSelected":function($event){_vm.managerName = $event}}})],1):_vm._e()],1),_c('b-row',{staticClass:"mb-4",staticStyle:{"height":"320px"}},[_c('div',{staticClass:"col-3 col-sm-2"}),_c('div',{staticClass:"col-6 col-sm-8"},[_c('div',{staticStyle:{"height":"200px","min-height":"200px","max-width":"420px","margin":"0 auto"}},[_c('ChartContainer',{attrs:{"label":"INVOICES BY STATUS"}},[_c('br'),_c('PieChart',{key:this.dropdownChangedKey,attrs:{"apiEndpoint":"invoices/count/type","apiParams":{ region: this.region, managerName: this.managerName, startDate: this.getStartDate() }}})],1)],1)]),_c('div',{staticClass:"col-3 col-sm-2"})]),_c('b-row',{staticClass:"row-border mb-3"},[_c('div',{staticClass:"col-12"},[_c('ChartContainer',{attrs:{"label":"OPEN PROJECTS"}},[_c('Table',{attrs:{"apiEndpoint":"projects","fields":[
              { key: 'project_title', label: 'Project Title' },
              { key: 'name', label: 'Account' },
              { key: 'project_type', label: 'Project Type' },
              { key: 'validity_date', label: 'Due Date' },
              { key: 'issued_amount', label: 'Total Amount', formatter: _vm.currencyFormatter('issued_amount') },
              { key: 'invoiced_amount', label: 'Invoiced Amount', formatter: _vm.currencyFormatter('invoiced_amount') },
              { key: 'unbilled_amount', label: 'Unbilled Amount', formatter: _vm.currencyFormatter('unbilled_amount') },
              { key: 'num_hours_registered', label: '#Hours Registered' },
              {
                key: 'salaries_and_expenses',
                label: 'Salaries & Expenses',
                formatter: _vm.currencyFormatter('salaries_and_expenses'),
              },
              {
                key: 'subcontractor_cost',
                label: 'Subcontractor Cost',
                formatter: _vm.currencyFormatter('subcontractor_cost'),
              },
              { key: 'balance', label: 'Balance', formatter: _vm.currencyFormatter('balance') } ],"apiParams":{
              region: this.region,
              managerName: this.managerName,
              startDate: this.getStartDate(),
              projectStatus: ['In Progress', 'Pending'],
            },"addButtonEnabled":function () { return false; },"editButtonEnabled":function () { return false; },"deleteButtonEnabled":function () { return false; },"disableTableBorder":""}})],1)],1),_c('div',{staticClass:"col-12",staticStyle:{"padding-top":"50px"}},[_c('br'),_c('ChartContainer',{attrs:{"label":"EMPLOYEE OVERVIEW (CURRENT MONTH)"}},[_c('Table',{attrs:{"apiEndpoint":"user-hours-statistics","fields":[
              { key: 'username', label: 'Username' },
              { key: _vm.hoursLoggedName, label: _vm.hoursLoggedName },
              { key: _vm.overtimeLoggedName, label: _vm.overtimeLoggedName },
              { key: 'Projects', label: 'Projects And Services' } ],"templates":[_vm.hoursLoggedName, _vm.overtimeLoggedName, 'Projects'],"apiParams":{
              userrole: ['Administrator', 'Project Manager'],
            },"addButtonEnabled":function () { return false; },"editButtonEnabled":function () { return false; },"deleteButtonEnabled":function () { return false; },"disableTableBorder":""},scopedSlots:_vm._u([{key:_vm.hoursLoggedName,fn:function(ref){
            var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.sum["Work"])+" / "+_vm._s(_vm.numWorkingDaysInPeriod())+" ")]}},{key:_vm.overtimeLoggedName,fn:function(ref){
            var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.sum["overtimeHours"])+" ")]}},{key:"Projects",fn:function(ref){
            var data = ref.data;
return [_c('ol',{staticStyle:{"padding-inline-start":"13px"}},_vm._l((data.item['ProjectsAndServices']),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0)]}}],null,true)})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }