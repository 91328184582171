 <template>
  <BaseView>
    <Table
      id="scannersTable"
      :key="rerenderTableKey"
      apiEndpoint="scanners"
      :fields="scannersSchema"
      @rerenderAfterGenericModal="rerenderAfterGenericModal"
    ></Table>
    <br />
    <div style="padding-top: 10px" />
    <h4>Timeline</h4>
    <br />
    <div class="text-center text-danger my-2" v-if="items === undefined" style="height: 350px; padding-top:160px">
      <b-spinner class="align-middle"></b-spinner>
      <strong>Loading... {{ this.opportunityInfo }} </strong>
    </div>
    <TimelineChart :items="items" v-else-if="items.length > 0" />
    <div v-else>
      <h6 style="margin-top: -10px; margin-left: 3px">
        No services named "3D Scanning" found using one of the previous scanners.
      </h6>
    </div>
  </BaseView>
</template>

<script>
import BaseView from "./../BaseView.vue";
import Table from "@/components/charts/Table.vue";
import { callEndpoint } from "./../../utils/apicall.js";
import TimelineChart from "@/components/charts/TimelineChart.vue";
import { TABLE_SCHEMAS } from "./../../config.js";

export default {
  name: "Scanners",

  data() {
    return {
      rerenderTableKey: true,
      items: undefined,
      scannersSchema: TABLE_SCHEMAS["Scanners"]
    };
  },

  components: {
    BaseView,
    Table,
    TimelineChart
  },

  methods: {
    rerenderAfterGenericModal() {
      this.rerenderTableKey = !this.rerenderTableKey;
    },
    getName() {
      return "3D Scanners";
    }
  },

  mounted() {
    callEndpoint("scanners/timeline", data => {
      this.items = data;
    });
  }
};
</script>
